// export const API_URL = "http://88.218.17.104:9002";
export const MAIN_UR = 'codeamooz.com'
  
export const API_URL = "https://finalapi.codeamooz.com";
export const HOME_URL = "https://codeamooz.com";



// export const API_URL = "https://finalapi.codeamooz.com";
// export const HOME_URL = "https://codeamooz.com"";



export const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM3NzIwNDQ2LCJpYXQiOjE2MzY1OTcyNDYsImp0aSI6ImFjYzIwMTIxYzQ2MzQ3ZDE4ZmFjZDQ5ZTNlOGJjNTVjIiwidXNlcl91c2VybmFtZSI6InBhcmlzYTcxNyJ9.7cSGKsPZiLCSED0mKaamNu3AgC_s2rvW0kWXHs6-SFA";
export const PAGE_SIZE = 5;
