import React, {useState} from "react";

const CourseStatusModal = ({statusData}) => {
    //success  error  text-primary
    console.log('statusData', statusData)
    return (
        <div className="CourseStatusModal__grid">
            <div className="CourseStatusModal__title"></div>
            <div className="CourseStatusModal__status"><p>وضعیت</p></div>

            {statusData.status_set.map((status) => (
                <>
                    <div className="CourseStatusModal__title"><p>{status.get_status_name_display}</p></div>
                    <div className="CourseStatusModal__status"><p
                        className={status.get_status_state_display === 'انجام شده' ?
                            'success' : 'error'}>{status.get_status_state_display}</p>
                    </div>
                </>
            ))}
            {/*<div className="CourseStatusModal__title"><p>ساخت کلی دوره</p></div>*/}
            {/*<div className="CourseStatusModal__status"><p className='success'>انجام شد</p></div>*/}
            {/*<div className="CourseStatusModal__title">آپلود ویدئو</div>*/}
            {/*<div className="CourseStatusModal__status"><p className='error'>انجام نشده</p></div>*/}
            {/*<div className="CourseStatusModal__title">ساخت جلسه</div>*/}
            {/*<div className="CourseStatusModal__status"><p className='text-primary'>در حال انجام</p></div>*/}
            {/*<div className="CourseStatusModal__title">آپلودی جلسات</div>*/}
            {/*<div className="CourseStatusModal__status"><p className='text-primary'>در حال انجام</p></div>*/}
        </div>
    );
};

export default CourseStatusModal;
