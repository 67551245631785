import React, { useContext, useState, createContext } from "react";
import useFetch from "./useFetch";

const CreateContentContext = createContext();

const CreateContentProvider = ({ children }) => {
  const [courseId, setCourseID] = useState(undefined);
  const [createInfoData, setCreateInfoData] = useState({
    example: [],
    description: [],
    code: [],
  });
  //   const [postCreateData, setPostCreateData] = useState();

  // const updateCourseDetail = (step, field, changeValue) => {
  // const updateCourseDetail = (step, changeValue) => {
  // 	//  ("in handle changes ", step, field, changeValue);
  // 	// const before = { ...createInfoData };
  // 	// before[step][field] = changeValue;
  // 	setCreateInfoData((before) => ({
  // 		...before,
  // 		[step]: changeValue,
  // 	}));
  // };

  // const addCourseStepTwoItem = (step, field, newValue) => {
  // 	const before = { ...createInfoData };
  // 	before[step][field].push(newValue);
  // 	setCreateInfoData(before);
  // };

  // const deleteCourseStepTwoItem = (step, field, index) => {
  // 	const before = { ...createInfoData };
  // 	before[step][field].splice(index, 1);
  // 	setCreateInfoData(before);
  // };

  // const editCourseStepTwoItem = (step, field, index, newValue) => {

  // 	const before = { ...createInfoData };
  // 	before[step][field][index].prop = newValue;
  // 	setCreateInfoData(before);
  // };

  return (
    <CreateContentContext.Provider
      value={{
        createInfoData,
      }}
    >
      {children}
    </CreateContentContext.Provider>
  );
};

const useCreateContent = () => useContext(CreateContentContext);

export { useCreateContent, CreateContentProvider };
