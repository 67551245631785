import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "@Components/Shared/Inputs/Input";
import CheckboxInput from "@Components/Shared/Inputs/CheckboxInput";
import DatePickerInput from "@Components/Shared/Inputs/DatePickerInput";
import Button from "@Components/Shared/Buttons/Button";
import Select from "@Components/Shared/Inputs/Select";
import { ReactComponent as RightArrow } from "@Assets/Icons/left-arrow.svg";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import useFetch from "../../../../Context/useFetch";
import { useAuth } from "@App/Context/authContext";
import WebTour from "@Components/Shared/WebTour/WebTour";
import { tourConfig } from "@Components/Layouts/CreateCourse/Step/BasicFormStep";
import { Skeleton } from "antd";

const optionList = [
  { label: "سخت", value: "professional" },
  { label: "متوسط ", value: "intermediate" },
  { label: "آسان ", value: "beginner" },
];

const CostList = [
  { label: "c", value: "50" },
  { label: "c#", value: "51" },
  { label: "c++", value: "51" },
  { label: "golang", value: "51" },
  { label: "java", value: "51" },
  { label: "javascript", value: "51" },
  { label: "php", value: "68" },
  { label: "python", value: "71" },
  { label: "ruby", value: "72" },
  { label: "rust", value: "73" },
];

const BasicInfoForm = ({ nextStep, courseData }) => {
  const { createInfoData, updateCourseDetail } = useCreateCourse();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      courseName: courseData?.title || createInfoData.stepOne.courseName || "",
      courseLevel:
        courseData?.level || createInfoData.stepOne.courseLevel || "beginner",
      courseLanguage:
        courseData?.language.toString() ||
        createInfoData.stepOne.courseLanguage ||
        "50",
      coursePrice: courseData?.price || createInfoData.stepOne.coursePrice,
    },
  });

  // useEffect(() => {
  //     if (courseDataLoading === false) {
  //         console.log('courseData: ', courseData)
  //         settitle(courseData.title)
  //         setlevel(courseData.level)
  //         setlanguage(courseData.language)
  //         setprice(courseData.price)
  //     }
  // }, [courseDataLoading]);

  const onSubmit = (data) => {
    updateCourseDetail("stepOne", data);
    nextStep();
  };
  const onSubmit1 = (data) => {
    updateCourseDetail("stepOne", data);
    setShowGuid(true);

    // nextStep();
  };
  const [languages, setLanguages] = useState([]);
  const { showGuid, setShowGuid } = useAuth();

  const getLanguages = useFetch({
    url: `HelpsService/languages`,
    argFunc: (res) => {
      let langs = [];
      for (var key in res) {
        langs.push({ label: res[key], value: key });
      }
      setLanguages(langs);
    },
  });
  console.log("languages", languages);
  const [costState, setCostState] = useState(CostList[0].value);

  function handleCostChange(value) {
    setCostState(value);
  }
  const handleTourOpen = (data) => {
    setShowGuid(true);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="create__course--form">
        <div className="create__course--wrapper">
          <div className=" BasicInfoForm-wrapper grid grid-cols-2 gap-12">
            <Input
              label="نام دوره"
              register={{
                required: {
                  value: true,
                  message: "نام دوره را وارد کنید",
                },
              }}
              placeholder={"دوره آموزش اندروید"}
              name="courseName"
              control={control}
              datatut="reactour__lock"
              // value={title}
            />
            <Select
              label="سطح دوره"
              register={{
                required: true,
              }}
              message=" سطح دوره  را وارد کنید"
              name="courseLevel"
              control={control}
              options={optionList}
              // value={level}
              datatut="reactour__courselevel"
            />
            {getLanguages.response?.data ? (
              <Select
                label="انتخاب زبان"
                register={{
                  required: true,
                }}
                message=" زبان دوره را وارد کنید"
                name="courseLanguage"
                control={control}
                options={languages.length > 0 ? languages : []}
                // value={language}
                datatut="reactour__courselan"
              />
            ) : (
              <Skeleton.Button block active size="large" />
            )}

            <Input
              label="هزینه دوره به تومان"
              register={{
                required: {
                  value: true,
                  message: "هزینه را وارد کنید",
                },
              }}
              placeholder={1430000}
              type={"number"}
              name="coursePrice"
              control={control}
              datatut="reactour__coursePrice"
              // value={price}
            />

            {costState === "1" && (
              <>
                <Input
                  label="درصد تخفیف دوره"
                  register={{
                    required: false,
                  }}
                  message="درصد تخفیف دوره را وارد کنید"
                  name="first_name"
                  control={control}
                  errors={errors}
                />
                <p className="self-end">
                  هزینه ی دوره:<span>16000</span>تومان
                </p>
              </>
            )}
            {costState === "1" && (
              <>
                <DatePickerInput
                  control={control}
                  label="تاریخ شروع تخفیف"
                  name="start"
                  errors={errors}
                />
                <DatePickerInput
                  control={control}
                  label="تاریخ اتمام تخفیف"
                  name="end"
                  errors={errors}
                />
              </>
            )}
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div></div>
          <Button type="primary" htmlType="submit" datatut="reactour__nextbtn">
            <RightArrow />
            مرحله ی بعد
          </Button>
        </div>
        <div className=" flex items-center justify-center w-full">
          <Button
            type="primary"
            // htmlType="submit"
            // onClick={handleTourOpen}
            onClick={handleTourOpen}
            key="BasicInfoForm"
          >
            راهنما
          </Button>
        </div>
      </form>

      <WebTour tourConfig={tourConfig} />
    </>
  );
};

export default BasicInfoForm;
