import React from "react";
import { useAuth } from "../../../Context/authContext";

function ClickPrevent(props) {
  const { showGuid } = useAuth();
  const styles = {
    pointerEvents: showGuid === true ? "none " : "all",
    height: "auto",
  };
  return <div style={styles}>{props.children}</div>;
}

export default ClickPrevent;
