import React from "react";
import MyCourseFilterItem from "./MyCourseFilterItem";

const MyCourseFilter = ({ updateFilterSearch, statistics }) => {
  return (
    <div className="flex">
      <MyCourseFilterItem
        title="همه"
        number={statistics.all_courses}
        updateFilterSearch={updateFilterSearch}
        state={"all"}
      />
      /
      <MyCourseFilterItem
        title="پیش نویس"
        number={statistics.draft_courses}
        updateFilterSearch={updateFilterSearch}
        state={"draft"}
      />
      /
      <MyCourseFilterItem
        title="منتشر شده"
        number={statistics.published_courses}
        updateFilterSearch={updateFilterSearch}
        state={"published"}
      />
    </div>
  );
};

export default MyCourseFilter;
