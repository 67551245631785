import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowDown } from "@Assets/Icons/down--arrow.svg";
import { ReactComponent as Pdf } from "@Assets/Icons/tutorialpdf.svg";
import pdf from "@Assets/Icons/tutorialpdf.svg";
import CodeEditor from "./CodeEditor";
import CodeeditorBox from "../../../Shared/codeeditor/CodeeditorBox";
import ExampleCodeeditorWithRun from "../../../Shared/codeeditor/ExampleEditorBox";
import ExampleEditorBox from "../../../Shared/codeeditor/ExampleEditorBox";

const ExampleItem = ({
  item,
  getContentDetail,
  resetForm,
  updatePositionList,
  contentPosition,
  lanid,
}) => {
  // console.log('item: ', item)
  // const arr = [
  //     {
  //         input: "1,2",
  //         output: "3",
  //     },
  //     {
  //         input: "1,2,3",
  //         output: "6",
  //     },
  // ];
  // console.log('pooria: ',item.example_code)
  const [value, setValue] = useState(item.example_code);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);

  function onChangeEditor(newValue) {
    setValue(newValue);
  }
  useEffect(() => {
    for (let index = 0; index < item.user_test_cases.length; index++) {
      setValue1(item.user_test_cases[index].input.split("#$$$#"));
      setValue2(item.user_test_cases[index].output.split("#$$$#"));
    }
    for (let index = 0; index < item.test_cases.length; index++) {
      setValue3(item.test_cases[index].input.split("#$$$#"));
      setValue4(item.test_cases[index].output.split("#$$$#"));
    }
  }, [item]);
  return (
    <div className="px-20 ">
      <p>{item.name}</p>
      <p>{item.example_text}</p>

      <ExampleEditorBox
        id={item?.uuid}
        name={item?.name}
        onChangeEditor={onChangeEditor}
        lan={
          getContentDetail?.response?.data?.language === "c"
            ? "c_cpp"
            : getContentDetail?.response?.data?.language
        }
        // small={true}
        value={item.example_code}
        languageId={lanid}
      />
      <p className="mt-8">نمونه ورودی و خروجی</p>
      <div className="d-flex-align mt-8">
        {value4.length !== 0 &&
          value4?.map((i, idd) => (
            <div className="testCase--items-column ml-4">
              {value3[idd] !== "" && (
                <div className="gray-bg">{value3[idd]}</div>
              )}
              {value3[idd] !== "" && i.output !== "" && <ArrowDown />}
              {value4[idd] !== "" && (
                <div className="gray-bg">{value4[idd]}</div>
              )}
            </div>
          ))}
      </div>
      <p className="mt-8"> نمونه ورودی و خروجی جهت نمایش به کاربر</p>
      <div className="d-flex-align mt-8">
        {value2.length !== 0 &&
          value2?.map((i, idd) => (
            <div className="testCase--items-column ml-4">
              {value1[idd] !== "" && (
                <div className="gray-bg">{value1[idd]}</div>
              )}
              {value1[idd] !== "" && i.output !== "" && <ArrowDown />}
              {value2[idd] !== "" && (
                <div className="gray-bg">{value2[idd]}</div>
              )}
            </div>
          ))}
      </div>
      {item.file !== null && (
        <div className="mt-8 d-flex-align">
          <img src={pdf} alt={pdf} />
          <p className="cursor-pointer">
            <a href={item.file} download target={"_blank"}>
              دانلود فایل
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default ExampleItem;
