import React, {useEffect, useState} from "react";
import CodeeditorBox from "@Components/Shared/codeeditor/CodeeditorBox";
import "highlight.js/styles/dracula.css";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import {ReactComponent as Submit} from "@Assets/Icons/CheckCircle.svg";
import {useParams} from "react-router";
import useFetch from "../../../../Context/useFetch";
import {useForm} from "react-hook-form";
import {ReactComponent as Trash} from "@Assets/Icons/Trash.svg";

const code = `const App = props => {
  return (
    <div>
      <h1> React App </h1>
      <div>Awesome code</div>
    </div>
  );
};
`;

function CodeEditorItem(props) {
    // const {classes} = props;
    // const [lan, setLan] = useState(props.lan);
    // const [btn, setBtn] = useState(0);
    // const [data, setData] = useState("");
    // const [info, setInfo] = useState("");
    // const [errs, setErrs] = useState("");
    // const {
    //     handleSubmit,
    //     control,
    //     formState: {errors},
    // } = useForm();

    // useEffect(() => {
    //     setValue(props.item.code)
    // }, [])
    console.log('props.item.code.XXX: ', props.item.code);

    // const [postData, setPostData] = useState();
    // const {contentId} = useParams();
    const [value, setValue] = useState(props.item.code);

    function onChangeEditor(newValue) {
        setValue(newValue);
    }

    return (
        <div>
            {/* <form
        className="content__SubmitFrom  relative"
        onSubmit={handleSubmit(onSubmit)}
      > */}
            <div className="CodeEditor-page center relative">
                {/* <div className=" IconBox-ab ">
            <IconBtn htmlType="sumbit" title="تایید" icon={<Submit />} />
          </div> */}
                {/* <div className=" " onClick={props.resetEditForm}>
            <IconBtn title="بستن" icon={<Trash />} />
          </div> */}
                <CodeeditorBox
                    onChangeEditor={onChangeEditor}
                    lan={props.lan === 'c' ? 'c_cpp' : props.lan}
                    small={true}
                    value={props.item.code}
                    id={props?.item?.uuid}
                />
            </div>
            {/* </form> */}
        </div>
    );
}

export default CodeEditorItem;
