import React, { useState, lazy, useEffect, useCallback } from "react";
import UseWindowSize from "@Components/Sizes/UseWindowSize";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import UseCopyToClipboard from "@Components/hooks/UseCopyToClipboard";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import "highlight.js/styles/dracula.css";
import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { useAuth } from "../../../Context/authContext";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../../constants";
import { toast } from "react-toastify";

const languages = [
  "sass",
  "mysql",
  "golang",
  "elixir",
  "csharp",
  "css",
  "d",
  "diff",
  "handlebars",
  "haskell",
  "ini",
  "java",
  "javascript",
  "json",
  "julia",
  "kotlin",
  "less",
  "lua",
  "makefile",
  "markdown",
  "nginx",
  "objectivec",
  "perl",
  "php",
  "properties",
  "python",
  "r",
  "ruby",
  "rust",
  "scala",
  "scss",
  "sql",
  "swift",
  "typescript",
  "xml",
  "yaml",
  "django",
  "c_cpp",
];

// languages.forEach((lang) => {
//   require(`ace-builds/src-noconflict/mode-${lang}`);
//   require(`ace-builds/src-noconflict/snippets/${lang}`);
// });

const override = css`
  display: block;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const override1 = css`
  display: block;
  z-index: 100;
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function CodeeditorBox(props) {
  console.log("props.value.XXX: ", props.value);
  console.log("props.id: ", props.id);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const [value, setValue] = useState(props.value);
  languages.map(
    (lang) =>
      lang === props.lan &&
      require(`ace-builds/src-noconflict/mode-${lang}`) &&
      require(`ace-builds/src-noconflict/snippets/${lang}`)
  );
  const [btn, setBtn] = useState(0);
  const [data, setData] = useState("");
  const [info, setInfo] = useState("");
  const [errs, setErrs] = useState("");
  const [load, setLoad] = useState(false);
  const [isCopied, handleCopy] = UseCopyToClipboard(3000);

  const handle = useFullScreenHandle();
  const windowSize = UseWindowSize();
  const { token } = useAuth();
  const codeCompiler = useAxios({
    url: `${API_URL}/CompilerService/v2/code/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        res.data.compiler_stdout === null ? setBtn(2) : setBtn(1);
        setInfo(res.data.compiler_stdout);
        setErrs(res.data.compiler_stderr);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const handleSend = () => {
    if (!value) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else {
      setData({
        submissions: {
          code_id: props.id,
          source: value,
          input: "",
        },
      });
      setLoad(true);
      codeCompiler.reFetch();
    }
  };
  const copyHandle = () => {
    btn === 0
      ? handleCopy(value)
      : btn === 1
      ? handleCopy(info)
      : handleCopy(errs);
  };

  function onChange(newValue) {
    setValue(newValue);
    props.onChangeEditor(newValue);
  }

  return (
    <FullScreen handle={handle} enabled={handle}>
      <div key={props.id} className="codeeditorBox">
        <div
          className={
            props.small
              ? handle.active
                ? "codeeditorBox__BtnBox"
                : "codeeditorBox__BtnBox1"
              : "codeeditorBox__BtnBox"
          }
        >
          <div>
            {props.id ? (
              <>
                <button
                  className={
                    btn === 1
                      ? "codeeditorBox__activbtn"
                      : "codeeditorBox__disactivbtn"
                  }
                  value={1}
                  type={"button"}
                  onClick={() => setBtn(1)}
                >
                  Result
                </button>
                <button
                  className={
                    btn === 2
                      ? "codeeditorBox__activbtn"
                      : "codeeditorBox__disactivbtn"
                  }
                  value={2}
                  type={"button"}
                  onClick={() => setBtn(2)}
                >
                  Errors
                </button>
              </>
            ) : null}

            <button
              className={
                btn === 0
                  ? "codeeditorBox__activbtn"
                  : "codeeditorBox__disactivbtn"
              }
              value={0}
              type={"button"}
              onClick={() => setBtn(0)}
            >{`code.${props.lan}`}</button>
          </div>
          <div className="d-flex">
            {props.id && (
              <button
                className={
                  load
                    ? "codeeditorBox__btnDon Example__codeeditor-btndonedis"
                    : "codeeditorBox__btnDon Example__codeeditor-btndone"
                }
                {...(props.id && { onClick: handleSend })}
                type={"button"}
                disabled={!props.id || load}
              >
                <i className="fas fa-play"></i>
                {!props.id ? "" : "اجرای کد"}
              </button>
            )}
            {handle.active ? (
              <button
                className="codeeditorBox__fulScreen"
                onClick={handle.exit}
                type={"button"}
              />
            ) : (
              <button
                className="codeeditorBox__fulScreen"
                onClick={handle.enter}
                type={"button"}
              />
            )}
            <button
              className="codeeditorBox__btnDon Example__codeeditor-btncopy1"
              onClick={copyHandle}
              type={"button"}
            >
              {isCopied ? (
                <div className="d-flex">
                  <i className="fas fa-check"></i>
                  کپی شد
                </div>
              ) : (
                <div className="d-flex">
                  {" "}
                  <i className="far fa-copy"></i>
                  کپی
                </div>
              )}
            </button>
          </div>
        </div>
        {windowSize === "lg" ||
        windowSize === "md" ||
        windowSize === "md-2" ||
        windowSize === "sm" ? (
          <div style={{ position: "relative", marginBottom: "2rem" }}>
            <AceEditor
              mode={props.lan}
              theme="monokai"
              value={btn === 0 ? value : btn === 1 ? info : errs}
              onChange={onChange}
              width={handle.active ? "100vw" : "100%"}
              height={handle.active ? "100vh" : "30vh"}
              name="UNIQUE_ID_OF_DIV"
              highlightActiveLine={false}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                fontSize: "9pt",
              }}
            />
            <RiseLoader
              color="#0dca78"
              loading={load}
              size={20}
              css={override}
            />
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <AceEditor
              mode={props.lan}
              theme="monokai"
              value={btn === 0 ? value : btn === 1 ? info : errs}
              onChange={onChange}
              width={handle.active ? "100vw" : "100%"}
              height={handle.active ? "100vh" : "40vh"}
              highlightActiveLine={false}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                fontSize: "12pt",
              }}
            />
            {props.small ? (
              <div style={{ width: "35vw" }}>
                <RiseLoader
                  color="#0dca78"
                  loading={load}
                  size={20}
                  css={override1}
                />
              </div>
            ) : (
              <RiseLoader
                color="#0dca78"
                loading={load}
                size={20}
                css={override}
              />
            )}
          </div>
        )}
      </div>
    </FullScreen>
  );
}

export default CodeeditorBox;
