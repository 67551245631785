import React, { useState } from "react";
import { ReactComponent as Line } from "@Assets/Icons/Line 27.svg";
import ResumeDoneWrapper from "@Components/Layouts/resume/ResumeWrapper/ResumeDoneWrapper";
import useFetch from "@App/Context/useFetch";
import SkillsForm from "./SkillsForm";
import Rate from '@Components/Shared/Rate/Rate';

const SkillsDone = ({ skill_name, id, caller, showForm,skill_rate }) => {
  const [edit, setEdit] = useState(false);
  const showEdit = () => {
    setEdit((before) => !before);
  };
  return (
    <>
      <ResumeDoneWrapper
        className="WorkExperinceDone SkillsDone"
        type={"skill"}
        id={id}
        caller={caller}
        showEdit={showEdit}
      >
        <div className=" WorkExperinceDone__header SkillsDone__header  d-flex-space mt-14">
          <p className="WorkExperinceDone__title">{skill_name}</p>
          <Rate
						value={skill_rate}
            disabled 
					/>
        </div>

      </ResumeDoneWrapper>

      {edit ? (
        <SkillsForm
          showEdit={showEdit}
          edit={true}
          showForm={showForm}
          getSkill={caller}
          skill_name={skill_name}
          skill_rate={skill_rate}
          id={id}
        />
      ) : undefined}
    </>
  );
};

export default SkillsDone;
