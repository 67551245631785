import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import EducationExperinceDone from "./EducationExperinceDone";
import EducationExperienceForm from "./EducationExperienceForm";
import Button from "@Components/Shared/Buttons/Button";
import useFetch from "@App/Context/useFetch";
import { Skeleton } from "antd";

const EducationExperiencePanel = () => {
  const [teacherEducations, setTeacherEducations] = useState({ results: [] });
  const [addItem, setAddItem] = useState(false);

  const getEducations = useFetch({
    url: `TeacherGradeService`,
    setter: setTeacherEducations,
  });
  const showForm = () => {
    setAddItem((before) => !before);
  };
  return (
    <div className="WorkExperiencePanel">
      {getEducations.response?.data ? (
        teacherEducations.results.map((item) => {
          return [
            <EducationExperinceDone
              key={item.uuid}
              institute_name={item.institute_name}
              grade={item.grade}
              catch_end_date={item.catch_end_date}
              catch_start_date={item.catch_start_date}
              major={item.major}
              id={item.uuid}
              caller={getEducations}
              showForm={showForm}
            />,
          ];
        })
      ) : (
        <Skeleton.Button block active size="large" />
      )}

      {addItem && (
        <EducationExperienceForm
          getEducations={getEducations}
          showForm={showForm}
        />
      )}
      {!addItem && (
        <Button type="text" size="small" success onClick={showForm}>
          <PlusIcon />
          <p>اضافه کردن سوابق تحصیلی</p>
        </Button>
      )}
    </div>
  );
};

export default EducationExperiencePanel;
