import React, { useState } from 'react';
import IncomeListTable from './IncomeListTable';
import { useHistory } from 'react-router-dom';
import { Statistic } from 'antd';
import {ReactComponent as CoinIcon} from '@Assets/Icons/coin.svg';
import {ReactComponent as CoinsIcon} from '@Assets/Icons/coins.svg';
import {ReactComponent as DollarIcon} from '@Assets/Icons/dollar.svg';
import useFetch from '../../../Context/useFetch';
import Price from '@Components/Shared/Price/Price';

const IncomelistContainer = () => {
	const history = useHistory();

	const [walletInfo, setWalletInfo] = useState(null);
	const [walletLoading, setWalletLoading] = useState(true);

	const setData = (data) => {
		setWalletInfo(data);
		setWalletLoading(false);
	};

	const getWalletInfo = useFetch({
		url: `TeacherService/teacher_wallet`,
		method: 'GET',
		setter: setData,
	});

	return (
		<>
			{!walletLoading && (
				<div className='IncomeListContainer__wrapper'>
					<div className='flex IncomeListContainer-header items-center  flex-col md:flex-row'>
						<div className='flex items-center '>
							<DollarIcon />
							<span>درآمد کل شما: &nbsp;</span>
							<Price value={walletInfo?.total_earnings} suffix='تومان' />
						</div>
						<div className='flex items-center'>
							<CoinIcon />
							<span>درآمد این ماه شما: &nbsp;</span>
							<Price value={walletInfo?.month_earnings} suffix='تومان' />
						</div>
						<div className='flex items-center'>
							<CoinsIcon />
							<span>درآمد امسال شما: &nbsp;</span>
							<Price value={walletInfo?.year_earnings} suffix='تومان' />
						</div>
					</div>
					<IncomeListTable courseData={walletInfo?.courses} />
				</div>
			)}
		</>
	);
};

export default IncomelistContainer;
// const data = [
//     {
//         key: "1",
//         coursename: "دوره آنلاین برنامه نویسی پایتون",
//         students: "0",
//         cost: "2000000",
//         status: false,
//         gained: "200000",
//     },
//     {
//         key: "2",
//         coursename: "دوره آنلاین برنامه نویسی پایتون",
//         students: "0",
//         cost: "2000000",
//         status: true,
//         gained: "200000",
//     },
//     {
//         key: "3",
//         coursename: "دوره آنلاین برنامه نویسی پایتون",
//         students: "0",
//         cost: "2000000",
//         status: true,
//         gained: "200000",
//     },
//     {
//         key: "4",
//         coursename: "دوره آنلاین برنامه نویسی پایتون",
//         students: "0",
//         cost: "2000000",
//         status: false,
//         gained: "200000",
//     },
// ];
