import React from "react";

const DescriptionItem = ({text}) => {
  return (
    <div className='px-20 '>
      <p>
        {text}
      </p>
    </div>
  );
};

export default DescriptionItem;
