import React from 'react';

const DashboardStudentItem = ({
	first_name,
	last_name,
	avatar,
	course_name,
}) => {
	return (
		<div className='dashboard__student--item'>
			<div className='dashboard__student--item-cover'>
				<img src={avatar} alt={first_name} className='image' />
			</div>
			<div className='flex flex-col mr-4 gap-y-2'>
				<h4>
					{first_name}
					{last_name}
				</h4>
				<p>{course_name}</p>
			</div>
		</div>
	);
};
export default DashboardStudentItem;
