import React, { useState } from 'react';
import { Collapse } from 'antd';
import { ReactComponent as Arrow } from '@Assets/Icons/arrow-down.svg';
import { ReactComponent as Trash } from '@Assets/Icons/Trash.svg';
import IconBtn from '@Components/Shared/Buttons/IconBtn';
import { ReactComponent as Pencil } from '@Assets/Icons/Pencil.svg';
import CheckboxInput from '@Components/Shared/Inputs/CheckboxInput';
import useFetch from '@App/Context/useFetch';
import classNames from 'classnames';

const { Panel } = Collapse;

const ArrowIcon = ({ active }) => (
	<div
		className={classNames('accordion__arrow', {
			active: active,
		})}
	>
		<Arrow />
	</div>
);

const Accordion = ({ children, half,classes, ...rest }) => {
	return (
		<Collapse
			className={classNames('accordion',[classes], {
				accordion__circle: half,
			})}
			expandIcon={({ isActive }) => <ArrowIcon active={isActive} />}
			ghost
			expandIconPosition='left'
			{...rest}
		>
			{children}
		</Collapse>
	);
};

export { Accordion, Panel };
