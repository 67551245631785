import React, { useState, useEffect, useContext } from "react";
import useAxios from "@use-hooks/axios";
import UseWindowSize from "@Components/Sizes/UseWindowSize";
import UseCopyToClipboard from "@Components/hooks/UseCopyToClipboard";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { useAuth } from "../../../../Context/authContext";
import { API_URL } from "../../../../constants";
import { toast } from "react-toastify";

const languages = [
  "sass",
  "mysql",
  "golang",
  "elixir",
  "csharp",
  "css",
  "d",
  "diff",
  "handlebars",
  "haskell",
  "ini",
  "java",
  "javascript",
  "json",
  "julia",
  "kotlin",
  "less",
  "lua",
  "makefile",
  "markdown",
  "nginx",
  "objectivec",
  "perl",
  "php",
  "properties",
  "python",
  "r",
  "ruby",
  "rust",
  "scala",
  "scss",
  "sql",
  "swift",
  "typescript",
  "xml",
  "yaml",
  "django",
  "c_cpp",
];

// languages.forEach((lang) => {
//   require(`ace-builds/src-noconflict/mode-${lang}`);
//   require(`ace-builds/src-noconflict/snippets/${lang}`);
// });
const override = css`
  display: block;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function QuizeCodeEditor(props) {
  languages.map(
    (lang) =>
      lang === props.lan &&
      require(`ace-builds/src-noconflict/mode-${lang}`) &&
      require(`ace-builds/src-noconflict/snippets/${lang}`)
  );
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(props.value);
  const [test, setTest] = useState("");
  const [number, setNumber] = useState(0);
  const [res, setRes] = useState("");
  const [errs, setErrs] = useState("");
  // const [info, setInfo] = useState();
  // const [info1, setInfo1] = useState("");
  const [data, setData] = useState("");
  const [isCopied, handleCopy] = UseCopyToClipboard(3000);
  const [load, setLoad] = useState(false);
  const [btn, setBtn] = useState(1);
  const [numberComplit, setNumberComplit] = useState(0);
  const windowSize = UseWindowSize();
  // useEffect(() => {
  // }, []);
  //   Cookies.set("path", window.location.href);
  //   const path = Cookies.get("path") ? Cookies.get("path") : null;
  //   const tokencookie = Cookies.get("token") ? Cookies.get("token") : null;
  const handle = useFullScreenHandle();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
console.log('first', props.value)
  const { token } = useAuth();
  const QuizPlayGround = useAxios({
    url: `${API_URL}/CompilerService/v2/quiz/new/teacher/playground/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizPlayGround", res.data);
        // setInfo(res.data);
        res.data.compiler_stdout ? setBtn(1) : setBtn(2);
        setRes(res.data.compiler_stdout);
        res.data.compiler_stderr
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs(res.data.compiler_stderr);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const QuizSendToServer = useAxios({
    url: `${API_URL}/CompilerService/v3/quiz/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizSendToServer", res.data);
        // setInfo1(res.data);
        setNumber(res.data.compile_result);
        res.data.compiler_stderr ? setBtn(1) : setBtn(2);
        setRes(res.data.compiler_stdout);

        res.data.compiler_stderr
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs(res.data.compiler_stderr);
        setLoad(false);

        if (res.data.compile_result === 100) {
          setNumberComplit(res.data.compile_result);
          // postPassContent.reFetch();
        } else {
          setNumber(res.data.compile_result);
          setLoad(false);
        }
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const handleSend = () => {
    if (!value) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else {
      setData({
        submissions: {
          // question_id: props.quizId,
          input: test,
          source: value,
          language_id: props.languageId,
          input_test_case: [],
          output_test_case: [],
        },
      });
      setLoad(true);
      QuizPlayGround.reFetch();
    }
  };
  const handleInputSend = () => {
    setData({
      submissions: {
        question_id: props.quizId,
        source: value,
        // input: test,
      },
    });
    setLoad(true);
    QuizSendToServer.reFetch();
  };

  function onChange(newValue) {
    console.log("newValue: ", newValue);
    setValue(newValue);
    props.onChangeEditor(newValue);
  }

  function onChange1(newValue) {
    console.log("newValue: ", newValue);
    setTest(newValue);
  }
  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${props.name}.${props.lang}`;
    document.body.appendChild(element);
    element.click();
  };
  return (
    <FullScreen handle={handle} enabled={handle}>
      {!loading ? (
        <div className="Example">
          {
            <>
              <div
                className={handle.active ? " w-full h-full" : "container"}
                style={{ position: "relative " }}
              >
                <div className="Example__codeeditor">
                  <div>
                    <div className="Example__codeeditor-btnBox">
                      <p>{`${props.name || "code"}.${props.lan}`}</p>
                      <div className="d-flex ">
                        {handle.active ? (
                          <button
                            className="codeeditorBox__fulScreen"
                            onClick={handle.exit}
                            style={{ borderRadius: "0" }}
                            type={"button"}
                          ></button>
                        ) : (
                          <button
                            className="codeeditorBox__fulScreen"
                            onClick={handle.enter}
                            style={{ borderRadius: "0" }}
                            type={"button"}
                          ></button>
                        )}
                        <button
                          className="Example__codeeditor-btncopy"
                          onClick={handleDownload}
                        >
                          ذخیره کدها
                        </button>
                        <button
                          className="Example__codeeditor-btncopy"
                          onClick={() => handleCopy(value)}
                          type={"button"}
                        >
                          {isCopied ? (
                            <div className="d-flex">
                              <i className="fas fa-check"></i>
                              کپی شد
                            </div>
                          ) : (
                            <div className="d-flex">
                              {" "}
                              <i className="far fa-copy"></i>
                              کپی
                            </div>
                          )}
                        </button>

                        <button
                          className={
                            load
                              ? "Example__codeeditor-btndonedis"
                              : "Example__codeeditor-btndone"
                          }
                          onClick={handleSend}
                          disabled={load}
                          type={"button"}
                          data-tut="reactour__quizruncode"
                        >
                          <i className="fas fa-play"></i>
                          اجرای کد
                        </button>
                        {/*<button*/}
                        {/*    className={*/}
                        {/*        load*/}
                        {/*            ? "Example__codeeditor-btndonedis"*/}
                        {/*            : "Example__codeeditor-btndone Example__codeeditor1-btndone"*/}
                        {/*    }*/}
                        {/*    onClick={handleInputSend}*/}
                        {/*    disabled={true}*/}
                        {/*    // disabled={load}*/}
                        {/*>*/}
                        {/*    <i class="fas fa-paper-plane"></i>*/}
                        {/*    ارسال پاسخ*/}
                        {/*</button>*/}
                      </div>
                    </div>
                    {windowSize === "lg" ||
                    windowSize === "md" ||
                    windowSize === "md-2" ||
                    windowSize === "sm" ? (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width="100%"
                        height="65.1vh"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          fontSize: "9pt",
                        }}
                      />
                    ) : windowSize === "big" ? (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width="100%"
                        height="65.1vh"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          fontSize: "15pt",
                        }}
                      />
                    ) : (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width={handle.active ? "100vh" : "45vw"}
                        height={handle.active ? "100%" : "100%"}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          fontSize: "12pt",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <div className="Example__codeeditor-btnBox2">
                      <div className="d-flex">
                        <div
                          className={
                            btn === 1
                              ? "Example__activbtn"
                              : "Example__disactivbtn1"
                          }
                          value={1}
                          onClick={() => setBtn(1)}
                        >
                          Result
                        </div>
                        <div
                          className={
                            btn === 2
                              ? "Example__activbtn"
                              : "Example__disactivbtn1"
                          }
                          value={2}
                          onClick={() => setBtn(2)}
                        >
                          Errors
                        </div>
                      </div>
                    </div>
                    <div className="result-codeeditor">
                      {windowSize === "lg" ||
                      windowSize === "md" ||
                      windowSize === "md-2" ||
                      windowSize === "sm" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width="100%"
                          height={handle.active ? "48vh" : "30vh"}
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "9pt",
                          }}
                        />
                      ) : windowSize === "big" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width="100%"
                          height={handle.active ? "48vh" : "30vh"}
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "15pt",
                          }}
                        />
                      ) : (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width={handle.active ? "100%" : "45vw"}
                          height={handle.active ? "48vh" : "30vh"}
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "12pt",
                          }}
                        />
                      )}
                    </div>
                    <div className="Example__TestBox">
                      <div className="Example__codeeditor-btnBox1">
                        <p>Test</p>
                      </div>{" "}
                      {windowSize === "lg" ||
                      windowSize === "md" ||
                      windowSize === "md-2" ||
                      windowSize === "sm" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width="100%"
                          height={handle.active ? "50vh" : "30.5vh"}
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "9pt",
                          }}
                        />
                      ) : windowSize === "big" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width="100%"
                          height={handle.active ? "50vh" : "30.5vh"}
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "15pt",
                          }}
                        />
                      ) : (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width={handle.active ? "100%" : "45vw"}
                          height={handle.active ? "50vh" : "30.5vh"}
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                            fontSize: "12pt",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <RiseLoader
                  color="#0dca78"
                  loading={load}
                  size={30}
                  css={override}
                />
              </div>
            </>
          }
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <RiseLoader />
        </div>
      )}
    </FullScreen>
  );
}

export default QuizeCodeEditor;
