import React, { useState } from "react";
import AttributeItem from "./AttributeItem";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import PropertyForm from "@Components/Layouts/CreateCourse/Form/PropertyForm";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import { useForm } from "react-hook-form";

const Property = ({ courseData }) => {
  const [addForm, setaddForm] = useState(true);
  const { createInfoData } = useCreateCourse();

  if (courseData?.properties?.length > 0) {
    createInfoData.stepTwo.courseFeature = courseData.properties;
  }

  const toggleForm = () => {
    setaddForm((before) => !before);
  };
  const closeForm = () => {
    setaddForm(true);
  };

  return (
    <div className="AttributeItem__box" data-tut="reactour__courseProperti">
      <p className="atrr__title mb-4" data-tut="reactour__lock">
        ویژگی های دوره:
      </p>

      {createInfoData.stepTwo.courseFeature.map(({ prop }, index) => (
        <AttributeItem
          key={index}
          toggleForm={toggleForm}
          closeForm={closeForm}
          item={prop}
          index={index}
          field={"courseFeature"}
        />
      ))}
      {addForm ? (
        <Button
          onClick={toggleForm}
          type="text"
          size="small"
          success
          datatut="reactour__courseaddNEWProperti"
        >
          <PlusIcon />
          اضافه کردن ویژگی جدید
        </Button>
      ) : (
        <PropertyForm
          toggleForm={toggleForm}
          closeForm={closeForm}
          field="courseFeature"
        />
      )}
    </div>
  );
};

export default Property;
