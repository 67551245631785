import React, { useContext, useState, createContext, useEffect } from "react";
import useFetch from "./useFetch";
import { useAuth } from "./authContext";

const CreateCourseContext = createContext();

const CreateCourseProvider = ({ children }) => {
  const [courseId, setCourseID] = useState(undefined);
  const [caller, setCaller] = useState(null);
  const [isCourseComplete, setIsCourseCompleter] = useState(false);
  const { showGuid, setShowGuid } = useAuth();
  const [createInfoData, setCreateInfoData] = useState({
    stepOne: {
      courseName: "",
      courseLevel: "beginner",
      coursePrice: 0,
      courseLanguage: "50",
    },
    stepTwo: {
      courseFeature: [],
      coursePreReq: [],
    },
    stepThree: {
      courseCategory: [],
      courseTags: [],
      courseTeacherInfo: "",
    },
  });

  useEffect(() => {
    if (!showGuid) {
      setCreateInfoData({
        stepOne: {
          courseName: "دوره آموزش",
          courseLevel: "beginner",
          coursePrice: 0,
          courseLanguage: "50",
        },
        stepTwo: {
          courseFeature: [],
          coursePreReq: [],
        },
        stepThree: {
          courseCategory: [],
          courseTags: [],
          courseTeacherInfo: "",
        },
      });
    } else {
      setCreateInfoData({
        stepOne: {
          courseName: "",
          courseLevel: "beginner",
          coursePrice: 0,
          courseLanguage: "50",
        },
        stepTwo: {
          courseFeature: [],
          coursePreReq: [],
        },
        stepThree: {
          courseCategory: [],
          courseTags: [],
          courseTeacherInfo: "",
        },
      });
    }
  }, [showGuid]);
  const [postCreateData, setPostCreateData] = useState();

  // const updateCourseDetail = (step, field, changeValue) => {
  const updateCourseDetail = (step, changeValue) => {
    //  ("in handle changes ", step, field, changeValue);

    setCreateInfoData((before) => ({
      ...before,
      [step]: changeValue,
    }));

    // const before = {...createInfoData};
    // before[step] = changeValue
    // // before[step].push(changeValue);
    // setCreateInfoData(before);
  };

  const addCourseStepTwoItem = (step, field, newValue) => {
    const before = { ...createInfoData };
    before[step][field].push(newValue);
    setCreateInfoData(before);
  };

  const deleteCourseStepTwoItem = (step, field, index) => {
    const before = { ...createInfoData };
    before[step][field].splice(index, 1);
    setCreateInfoData(before);
  };

  const editCourseStepTwoItem = (step, field, index, newValue) => {
    const before = { ...createInfoData };
    if (before[step][field][index].pre_prop) {
      before[step][field][index].pre_prop = newValue;
    } else {
      before[step][field][index].prop = newValue;
    }

    setCreateInfoData(before);
  };

  const create_pre_req = (data) => {
    let ndata = [];

    data.map((item, index) => {
      ndata.push({
        pre_prop: item.prop,
      });
    });
    return ndata;
  };
  const create_step_data_for_post = (event) => {
    let postData = [];
    createInfoData.stepTwo.courseFeature.forEach((step) => {
      postData.push({
        prop: step.prop,
      });
    });
    // setPostCreateEventStepsData(postData);
    return postData;
  };

  console.log("createInfoData~outside: ", createInfoData);
  const apiCreateCourse = (courseUUID, step, changeValue, getCourse) => {
    console.log("createInfoData~inside1: ", createInfoData);
    if (step) {
      createInfoData[step] = changeValue;
    }
    console.log("createInfoData~inside2: ", createInfoData);

    // let formData = new FormData();
    //  (createInfoData);
    // formData.append("price", createInfoData.stepOne.coursePrice);
    // formData.append("language", createInfoData.stepOne.courseLanguage);
    // formData.append("level", createInfoData.stepOne.courseLevel);
    // formData.append("title", createInfoData.stepOne.courseName);

    // formData.append("properties", create_step_data_for_post());
    // formData.append(
    //   "prerequisites",
    //   create_pre_req(createInfoData.stepTwo.coursePreReq)
    // );

    // formData.append("tags", createInfoData.stepThree.courseTags);
    // // formData.append("categories", createInfoData.stepThree.courseCategory);
    // formData.append("categories", [1]);
    // formData.append(
    //   "courseTeacherInfo",
    //   createInfoData.stepThree.courseTeacherInfo
    // );

    // formData.append("");
    // properties: create_step_data_for_post(createInfoData.stepTwo.courseFeature),
    setPostCreateData({
      properties: createInfoData.stepTwo.courseFeature,
      teacher_uuid: "SnxJopwR",
      categories: createInfoData.stepThree.courseCategory, //
      // categories: [1],
      tags: createInfoData.stepThree.courseTags, //
      intro: createInfoData.stepThree.courseTeacherInfo, //
      prerequisites: createInfoData.stepTwo.coursePreReq,

      price: createInfoData.stepOne.coursePrice,
      language: createInfoData.stepOne.courseLanguage,
      level: createInfoData.stepOne.courseLevel,
      title: createInfoData.stepOne.courseName,
    });

    if (courseUUID) {
      console.log("context+courseUUID: ", courseUUID);
      setCourseID(courseUUID);
      setCaller(getCourse);
      updateCourseApi.reFetch();
    } else {
      createCourseApi.reFetch();
    }
  };
  const createCourseApi = useFetch({
    url: `CourseService/course_post`,
    method: "POST",
    data: postCreateData,
    trigger: false,
    message: "دوره با موفقیت  ثبت شد",
    func: () => {
      setIsCourseCompleter(true);
    },
    argFunc: (res) => {
      setCreateInfoData({
        stepOne: {
          courseName: "",
          courseLevel: "beginner",
          coursePrice: "0",
          courseLanguage: "50",
        },
        stepTwo: {
          courseFeature: [],
          coursePreReq: [],
        },
        stepThree: {
          courseCategory: [],
          courseTags: [],
          courseTeacherInfo: "",
        },
      });

      setCourseID(res.uuid);
    },
  });

  const updateCourseApi = useFetch({
    url: `CourseService/${courseId}/course_update`,
    method: "PATCH",
    data: postCreateData,
    trigger: false,
    caller: caller,
    message: "دوره با موفقیت  ویرایش شد",
    // argFunc: (res) => {
    //     setCreateInfoData({
    //         stepOne: {
    //             courseName: '',
    //             courseLevel: 'beginner',
    //             coursePrice: '0',
    //             courseLanguage: '50',
    //         },
    //         stepTwo: {
    //             courseFeature: [],
    //             coursePreReq: [],
    //         },
    //         stepThree: {
    //             courseCategory: [],
    //             courseTags: [],
    //             courseTeacherInfo: '',
    //         },
    //     });
    // },
  });

  return (
    <CreateCourseContext.Provider
      value={{
        createInfoData,
        updateCourseDetail,
        addCourseStepTwoItem,
        deleteCourseStepTwoItem,
        editCourseStepTwoItem,
        apiCreateCourse,
        courseId,
        isCourseComplete,
      }}
    >
      {children}
    </CreateCourseContext.Provider>
  );
};

const useCreateCourse = () => useContext(CreateCourseContext);

export { useCreateCourse, CreateCourseProvider };
