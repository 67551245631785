import React from 'react';
import CreateContentContainer from '@Components/Layouts/CreateContent/CreateContentContainer';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const CreateContent = () => {
	return (
		<>
			<BreadCrumb />
			<CreateContentContainer />
		</>
	);
};

export default CreateContent;
