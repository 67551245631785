import React, { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Textarea from "@Components/Shared/Inputs/Textarea";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import TestCaseInput from "@Components/Shared/TestCaseInput/TestCaseInput";
import QuizeCodeEditor from "@Components/Layouts/CreateContent/QuizeCodeEditor/QuizeCodeEditor";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../Context/authContext";
import useFetch from "../../Context/useFetch";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constants";
import Input from "../../Components/Shared/Inputs/Input";
import Upload from "../../Components/Shared/Inputs/Upload";
import { toast } from "react-toastify";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import WebTour from "@Components/Shared/WebTour/WebTour";
import { tourConfig } from "./QuizStep";
import { Skeleton } from "antd";
import Modal from "@Components/Shared/Modal/Modal";

const Quize = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #ffa44a;
  `;
  const param = useParams();
  const location = useLocation();
  const history = useHistory();

  let languageId = location.state.languageId;
  let languageName = location.state.languageName;
  let seasonId = param.seasonId;
  const { courseId } = useParams();
  const [quizUUID, setQuizUUID] = useState(
    location.state.quizId ? location.state.quizId : ""
  );

  const [val, setVal] = useState();

  const [postData, setPostData] = useState();
  const [quizData, setQuizData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const { token, showGuid, setShowGuid } = useAuth();
  const [test, setTest] = useState("");
  const [test1, setTest1] = useState("");
  const [test2, setTest2] = useState("");
  const [test3, setTest3] = useState("");
  const [firstload, setFirstload] = useState(false);
  const [loaddata, setLoaddata] = useState(!!quizUUID);
  const [isDisplayModal, setDisplayModal] = useState(false);
  const toggleDisplayModal = () => {
    setDisplayModal((before) => !before);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    getValues,
  } = useForm({});

  const {
    fields: fieldsTestCase,
    append: appendTestCase,
    remove: removeTestCase,
  } = useFieldArray({ name: "testCase", control });

  const {
    fields: fieldsTestCaseUser,
    append: appendTestCaseUser,
    remove: removeTestCaseUser,
  } = useFieldArray({ name: "testCaseUser", control });
  useEffect(() => {
    if (quizUUID) {
      setLoaddata(true);
      removeTestCase();
      removeTestCaseUser();
      setVal("");
      setFirstload(true);
      getQuiz.reFetch();
    }
  }, [load]);
  const setLoadedData = (data) => {
    setQuizData(data);
    setVal(data.code);
    setValue("name", data.name);
    setValue("text", data.text);
    for (let index = 0; index < data.test_cases.length; index++) {
      if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input === ""
      ) {
        const test = data.test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCase({
            output: test[index],
          });
        }
      } else if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input === "#$$$#"
      ) {
        const test = data.test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCase({
            output: test[index],
          });
        }
      } else if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input !== ""
      ) {
        const test = data.test_cases[index].output.split("#$$$#");
        const test1 = data.test_cases[index].input.split("#$$$#");
        for (let index = 0; index < test.length; index++) {
          appendTestCase({ input: [test1[index]], output: test[index] });
        }
      } else {
        appendTestCase();
      }
    }
    for (let index = 0; index < data.user_test_cases.length; index++) {
      if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input === ""
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({
            output: test[index],
          });
        }
      } else if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input === "#$$$#"
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({
            output: test[index],
          });
        }
      } else if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input !== ""
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");
        const test1 = data.user_test_cases[index].input.split("#$$$#");
        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({ input: [test1[index]], output: test[index] });
        }
      }
    }
    setFirstload(false);
    setLoader(false);
    setLoaddata(false);
  };
  const addTestCaseUser = () => {
    setTest2("");
    setTest1("");
    setTest3("");
    setTest(getValues("testCase").map((e) => e.input));
    for (let index = 0; index < test.length; index++) {
      for (let j = 0; j < test[index].length; j++) {
        if (j + 1 !== test[index].length) {
          setTest2((perv) => perv + test[index][j] + "#$$*#");
          setTest3((perv) => perv + test[index][j] + "#$$*#");
        } else if (
          j + 1 ===
          test[index].length
          // index !== test.length - 1
        ) {
          setTest2((perv) => perv + test[index][j]);
          setTest3((perv) => perv + test[index][j]);
        }
      }
      if (index !== test.length - 1) {
        setTest2(test3 + "#$$$*#");
      }
    }
    setTest1("تست کیس ثبت شد");
  };
  const getQuiz = useFetch({
    url: `QuizService/${quizUUID}`,
    trigger: !!quizUUID,
    method: "GET",
    setter: setLoadedData,
  });

  const createQuiz = useFetch({
    url: "QuizService/season_quiz_create",
    data: postData,
    method: "POST",
    trigger: false,
    // caller: getQuiz,
    argFunc: (res) => {
      setQuizUUID(res.uuid);
      setQuizResultNumber(0);
      setLoader(false);
      setLoaddata(false);
      setLoad((perv) => !perv);
      setDisplayModal(false);
    },
    message: "ازمون با موفقیت ساخته شد",
  });

  const editQuiz = useFetch({
    url: `QuizService/${quizUUID}`,
    data: postData,
    method: "PATCH",
    trigger: false,
    caller: getQuiz,
    argFunc: (res) => {
      console.log("quizData.status: ", quizData.status);
      if (quizData.status === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      } else {
        toast.success("ازمون با موفقیت ویرایش شد");
      }
      setQuizResultNumber(0);
      setLoader(false);
      setLoaddata(false);
      setLoad((perv) => !perv);
      setDisplayModal(false);
    },
  });
  const createQuizCourse = useFetch({
    url: "QuizService/course_quiz_create",
    data: postData,
    method: "POST",
    trigger: false,
    // caller: getQuiz,
    argFunc: (res) => {
      setQuizUUID(res.uuid);
      setQuizResultNumber(0);
      setLoader(false);
      setLoaddata(false);
      setLoad((perv) => !perv);
      setDisplayModal(false);
    },
    message: "ازمون با موفقیت ساخته شد",
  });

  const [submittedData, setSubmittedData] = useState({});

  // const [file, setFile] = useState(null)
  // console.log('FILE: ', file)
  const onSubmit = (data) => {
    setLoader(true);
    setSubmittedData(data);
    const formData = new FormData();
    if (data.file && data.file.length !== 0) {
      formData.append("file", data.file[0], data.file[0].name);
    }
    if (location.state.type === "course") {
      formData.append("course_uuid", seasonId);
      // formData.append("uuid", seasonId);
    } else {
      formData.append("season_uuid", seasonId);
    }
    formData.append("name", data.name);
    formData.append("text", data.text);
    formData.append(
      "test_case_input",
      data.testCase.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "test_case_output",
      data.testCase.map(({ output }) => output).join("#$$$#")
    );
    formData.append(
      "user_test_case_input",
      data.testCaseUser.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "user_test_case_output",
      data.testCaseUser.map(({ output }) => output).join("#$$$#")
    );
    setPostData(formData);
    if (quizUUID) {
      editQuiz.reFetch();
    } else {
      if (location.state.type === "course") {
        createQuizCourse.reFetch();
      } else createQuiz.reFetch();
    }
  };

  const [data, setData] = useState("");
  const [quizResultNumber, setQuizResultNumber] = useState(0);
  const QuizSendToServer = useAxios({
    url: `${API_URL}/CompilerService/v3/quiz/new/teacher/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizSendToServerTEACHER", res.data);
        setQuizResultNumber(res.data.compile_result);
        if (res.data.compiler_stdout === "Wrong Answer") {
          toast.error("ازمون را با موفقیت پاس نکردی");
        } else if (res.data.compiler_stdout === "Accepted") {
          toast.success("ازمون با موفقیت پاس شد");
        } else {
          toast.error("ازمون با خطا مواجه شد");
        }
        if (res.data.compile_result === 100) {
          setDisplayModal(true);
        }
        setLoader(false);
      }
      if (err) {
        console.log("err.response: ", err.response);
        setLoader(false);
      }
    },
  });

  function onChangeEditor(newValue) {
    setVal(newValue);
  }

  const handleInputSend = () => {
    if (!val) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else if (getValues("testCase").length === 0) {
      toast.error("نمونه  خروجی (جهت ارزیابی توسط کامپایلر) وارد نکرده اید");
    } else if (
      !getValues("testCase").every(
        (item) => item.input !== "" && item.output !== ""
      )
    ) {
      toast.error(
        "نمونه ورودی و خروجی (جهت ارزیابی توسط کامپایلر) به درستی وارد نکرده اید"
      );
    } else {
      setData({
        submissions: {
          source: val,
          language_id: languageId,
          input_test_case: getValues("testCase").map((e, id, row) =>
            id === row.length - 1
              ? e.input !== undefined &&
                e.input.length !== 0 &&
                e.input.map((s, idd, roww) =>
                  idd === roww.length - 1 ? s : s + "#$$#"
                ) + ""
              : e.input !== undefined &&
                e.input.length !== 0 &&
                e.input.map((s, idd, roww) =>
                  idd === roww.length - 1 ? s : s + "#$$#"
                ) + ""
          ),
          output_test_case: getValues("testCase").map((e) => e.output),
          // input: test,
        },
      });
      setLoader(true);
      QuizSendToServer.reFetch();
    }
  };
  const handleTourOpen = () => {
    setShowGuid(true);
  };
  // console.log('loader before return: ', loader)
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  return (
    <div className="Quize__page">
      <h2 className="Quize__page--title">افزودن آزمون</h2>
      <div className=" text-left w-full flex items-center justify-end">
        <Button type="primary" onClick={handleTourOpen}>
          راهنما
        </Button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Quize__page--form ContentSecondPhase ContentTest ">
          <p className="mb-8">آزمون: </p>
          {loaddata ? (
            <Skeleton.Button block active size="large" />
          ) : (
            <Input
              label="  نام "
              register={{
                required: {
                  value: true,
                  message: "نام را وارد کنید",
                },
              }}
              name="name"
              control={control}
              datatut="reactour__quizname"
            />
          )}
          {loaddata ? (
            <>
              <br />
              <br />
              <Skeleton.Button
                block
                active
                style={{ height: "20rem", width: "100%" }}
              />
              {/* <Skeleton.Button block active size="large" />
          <Skeleton.Button block active size="large" /> */}
              <br />
              <br />
            </>
          ) : (
            <div className=" mt-10">
              <Textarea
                label="  توضیحات "
                register={{
                  required: {
                    value: true,
                    message: "توضیحات را وارد کنید",
                  },
                }}
                name="text"
                control={control}
                datatut="reactour__quizdes"
              />
            </div>
          )}

          <p className="mt-8"> تست کیس ورودی و خروجی</p>
          <p className="Quize-des">
            تست کیس ها برای بررسی کد شما هستند.تا زمانی که نتوانید با قطعه کد
            پیشنهادی و تست کیس ها نمره کامل کسب کنید این مثال تایید نخواهد شد.*
          </p>
          <div data-tut="WebTour__quiztestcase">
            <p className="mt-8">
              نمونه ورودی و خروجی (جهت ارزیابی توسط کامپایلر)
            </p>

            <div className="p-4 ">
              <div className="grid textCaseLable mb-4">
                <p>ورودی:</p>
                <p className="mr-14px">خروجی:</p>
              </div>

              {fieldsTestCase.map((item, index) => (
                <TestCaseInput
                  key={item.id}
                  item={item}
                  mode="edit"
                  control={control}
                  index={index}
                  name="testCase"
                  remove={removeTestCase}
                  add={addTestCaseUser}
                  test={test1}
                  appendTestCase={appendTestCase}
                  setData1={setData1}
                  data1={data1}
                  getValues={getValues}
                  setValue={setValue}
                />
              ))}
            </div>
            <Button
              onClick={() => appendTestCase({ input: [], output: "" })}
              type="text"
              size="small"
              success
              datatut="reactour__newtestcase"
            >
              <PlusIcon />
              <p>اضافه کردن نمونه ورودی و خروجی های جدید </p>
            </Button>
          </div>
          <div data-tut="reactour__sampletestcase">
            <p className="mt-8"> نمونه ورودی و خروجی (جهت نمایش به کاربر)</p>
            <div className="p-4 ">
              <div className="grid textCaseLable mb-4">
                <p>ورودی:</p>
                <p className="mr-14px">خروجی:</p>
              </div>

              {fieldsTestCaseUser.map((item, index) => (
                <TestCaseInput
                  key={item.id}
                  item={item}
                  mode="edit"
                  control={control}
                  name="testCaseUser"
                  index={index}
                  remove={removeTestCaseUser}
                  add={addTestCaseUser}
                  setData1={setData2}
                  data1={data2}
                  getValues={getValues}
                  setValue={setValue}
                />
              ))}
            </div>
            <Button
              onClick={() => appendTestCaseUser({ input: [], output: "" })}
              type="text"
              size="small"
              success
              datatut="reactour__newsampletestcase"
            >
              <PlusIcon />
              <p>اضافه کردن نمونه ورودی و خروجی های جدید </p>
            </Button>
          </div>
          <div data-tut="reactour__codeeditor">
            <p className="mt-8"> قطعه کد: </p>
            <QuizeCodeEditor
              id={quizData?.uuid}
              value={val}
              languageId={languageId}
              lan={languageName === "c" ? "c_cpp" : languageName}
              onChangeEditor={onChangeEditor}
            />
          </div>
          <div className="d-flex-space px-10">
            <p data-tut="reactour__quizdegree">نمره شما : {quizResultNumber}</p>

            <p>برای ثبت آزمون باید نمره ی 100 دریافت کنید</p>

            {/*<Button type='primary' disabled={loader} onClick={ () =>{ setLoader(true); handleInputSend()}}>*/}

            <Button
              type="primary"
              disabled={loader || firstload}
              onClick={handleInputSend}
              datatut="reactour__quiztest"
            >
              {loader ? (
                <DotLoader
                  color="#fff"
                  loading={loader}
                  css={override}
                  size={22}
                />
              ) : (
                "تست آزمون"
              )}
            </Button>
          </div>
          <div className=" d-flex-space px-10">
            <div className="mt-8" data-tut="reactour__quizfile">
              <Upload
                label=" آپلود فایل"
                // register={{
                //     required: false,
                // }}
                {...register("file", { required: false })}
                message="فایل دوره را وارد کنید"
                name="file"
                // control={control}
                error={errors["file"]}
                value={quizData?.file}
                accept=".pdf"
                // isSubmitted={isSubmitted}
                // onChange={(e) => setFile(e.target.files)}
                id="cover_upload"
              />
            </div>
          </div>
          <div className="btn-box flex mt-8">
            <div className="flex">
              <div className="mr-8">
                <Button
                  onClick={() =>
                    history.push(`/my-course/${courseId}`)
                  }
                >
                  برگشت به جلسات
                </Button>
              </div>
            </div>
            <Button datatut="reactour__quizcancle">انصراف</Button>

            <div className="flex">
              {quizResultNumber === 100 ? (
                <Button
                  htmlType="submit"
                  datatut="reactour__quizfinal"
                  disabled={loader || firstload}
                >
                  {loader ? (
                    <DotLoader
                      color="#fff"
                      loading={loader}
                      css={override}
                      size={22}
                    />
                  ) : (
                    "ثبت"
                  )}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </form>
      <Modal
        visible={isDisplayModal}
        onCancel={toggleDisplayModal}
        title={"ثبت آزمون"}
        classes="min-w-max pb-0"
        maskClosable={false}
      >
        <p className=" mb-12">
          آزمون ذخیره نشده است، برای ذخیره آزمون روی دکمه ثبت کلیک کنید
        </p>
        <div className=" flex items-center justify-center">
          <Button
            htmlType="submit"
            disabled={loader || firstload}
            datatut="reactour__quizfinal"
            classes=" mr-5"
            onClick={handleSubmit(onSubmit)}
          >
            {" "}
            {loader ? (
              <DotLoader
                color="#fff"
                loading={loader}
                css={override}
                size={22}
              />
            ) : (
              "ثبت"
            )}
          </Button>
        </div>
      </Modal>
      <WebTour tourConfig={tourConfig} />
    </div>
  );
};

export default Quize;
