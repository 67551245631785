import React, { useState } from "react";
import CodeeditorBox from "@Components/Shared/codeeditor/CodeeditorBox";
import "highlight.js/styles/dracula.css";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import { useParams } from "react-router";
import useFetch from "../../../../Context/useFetch";
import { useForm } from "react-hook-form";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { toast } from "react-toastify";
import Button from "@Components/Shared/Buttons/Button";
import ExampleEditorBox from "@Components/Shared/codeeditor/ExampleEditorBox";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
const code = `const App = props => {
  return (
    <div>
      <h1> React App </h1>
      <div>Awesome code</div>
    </div>
  );
};
`;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ffa44a;
`;
function CodeEditor({
  getContentDetail,
  resetForm,
  resetEditForm,
  isEdit,
  item,
  updatePositionList,
  contentPosition,
  lanid,
}) {
  // const {classes} = props;
  // const [lan, setLan] = useState(lan);
  // const [btn, setBtn] = useState(0);
  // const [data, setData] = useState("");
  // const [info, setInfo] = useState("");
  // const [errs, setErrs] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [postData, setPostData] = useState();
  const { contentId } = useParams();
  const [value, setValue] = useState(isEdit ? item.code : "");
  const [loading, setLoading] = useState(false);

  const createCodeText = useFetch({
    url: `CodeTextService`,
    params: { type: "code" },
    data: postData,
    method: "POST",
    trigger: false,
    caller: getContentDetail,
    argFunc: () => {
      resetForm();
      updatePositionList();
      setLoading(false);
    },
    // setter:setCategories,
  });
  const editCodeText = useFetch({
    url: isEdit ? `CodeTextService/${item.uuid}` : undefined,
    params: { type: "code" },
    data: postData,
    method: "PATCH",
    trigger: false,
    caller: getContentDetail,
    argFunc: (res) => {
      console.log("item.status: ", item.status);
      if (item.status === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      }
      resetEditForm();
    },
    // setter:setCategories,
  });
  const onSubmit = (data) => {
    console.log("data", data);
    if (!value) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else {
      setLoading(true);
      setPostData({
        code: value,
        content_uuid: contentId,
        codetext_index: isEdit ? item.index : contentPosition.length,
      });
      if (isEdit) editCodeText.reFetch();
      else createCodeText.reFetch();
    }
  };

  function onChangeEditor(newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <form
        className="content__SubmitFrom content__SubmitFrom content__SubmitFrom--example"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="content__SubmitFrom--codeEditor center">
          <ExampleEditorBox
            id={item?.uuid}
            onChangeEditor={onChangeEditor}
            lan={
              getContentDetail?.response?.data?.language === "c"
                ? "c_cpp"
                : getContentDetail?.response?.data?.language
            }
            // small
            languageId={lanid}
            value={value}
          />
        </div>
        <div className="flex items-center justify-between mt-10">
          <Button onClick={() => resetForm()}>لغو</Button>
          <Button htmlType="sumbit" type="primary">
            {loading ? (
              <DotLoader
                color="#fff"
                loading={loading}
                css={override}
                size={22}
              />
            ) : (
              "ثبت"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CodeEditor;
