import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Upload from "@Components/Shared/Inputs/Upload";
import Input from "@Components/Shared/Inputs/Input";
import InputMask from "@Components/Shared/Inputs/InputMask";
import Textarea from "@Components/Shared/Inputs/Textarea";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import CheckboxInput from "@Components/Shared/Inputs/CheckboxInput";
import useFetch from "../../../Context/useFetch";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Skeleton } from "antd";
import DotLoader from "react-spinners/DotLoader";

const ContentFirstPhase = ({ nextStep, lockedOn }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      lockedOn: lockedOn,
    },
  });

  const [video, setVideo] = useState();
  const { contentId } = useParams();
  const [sessionDetail, setSessionDetail] = useState(undefined);
  const [postData, setPostData] = useState();
  const [isupdated, setIsupdated] = useState(false);
  const [data, setData] = useState();
  const getContentDetail = useFetch({
    url: `ContentService/${contentId}/contentFirstPhaseGet`,
    setter: setSessionDetail,
    // setter:setCategories,
    argFunc: (res) => {
      setData(res);
      setValue("title", res.title);
      setValue("short_description", res.short_description);
      setValue("duration_time", res.duration_time);
      setValue("lockedOn", res.lockedOn);
      setIsupdated(res.is_updated);
    },
  });

  const updateContentDetail = useFetch({
    url:
      sessionDetail !== undefined
        ? `ContentService/${sessionDetail.uuid}/contentFirstPhaseUpdate`
        : undefined,
    trigger: false,
    data: postData,
    method: "PATCH",
    argFunc: (res) => {
      if (sessionDetail?.get_status_display === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      } else {
        toast.success("با موفقیت انجام شد");
        getContentDetail.reFetch();
      }
      nextStep();
      // setValue("content_name", res.title);
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("duration_time", data.duration_time);
    formData.append("title", data.title);
    formData.append("short_description", data.short_description);
    formData.append("lockedOn", data.lockedOn);
    setPostData(formData);
    updateContentDetail.reFetch();
  };

  return (
    <form className="ContentFirstPhase" onSubmit={handleSubmit(onSubmit)}>
      {!getContentDetail.loading ? (
        <div className="ContentFirstPhase__row">
          <Input
            label="نام مبحث"
            register={{
              required: {
                value: true,
                message: "نام مبحث را وارد کنید",
              },
            }}
            name="title"
            control={control}
            absolute
          />
          <InputMask
            label="زمان ویدئو"
            register={{
              required: {
                value: true,
                message: "زمان ویدئو را وارد کنید",
              },
            }}
            name="duration_time"
            control={control}
            mask="99:99:99"
            absolute
          />

          <div className="resume__checkbox flex items-end">
            <CheckboxInput
              label="قفل مبحث"
              register={{
                required: false,
              }}
              name="lockedOn"
              control={control}
              large
            />
          </div>
          <div className="ContentFirstPhase__box">
            {/* <p className="ContentFirstPhase__box--tilte">توضیحات</p> */}
            {/*<div className='mt-8 mb-14'>*/}
            {/*	<Upload*/}
            {/*		label=' آپلود فیلم معرفی دوره'*/}
            {/*		register={{*/}
            {/*			required: true,*/}
            {/*		}}*/}
            {/*		message='فیلم دوره را وارد کنید'*/}
            {/*		name='video'*/}
            {/*		control={control}*/}
            {/*		errors={errors}*/}
            {/*		accept='.mp4 '*/}
            {/*		value={video}*/}
            {/*		id='video_upload'*/}
            {/*	/>*/}
            {/*</div>*/}
            <Textarea
              label="توضیحات "
              register={{
                required: {
                  value: true,
                  message: "توضیحات ویدئو را وارد کنید",
                },
              }}
              name="short_description"
              control={control}
              errors={errors}
            />
          </div>
        </div>
      ) : (
        <Skeleton active size="large" />
      )}

      <div className="btn-box flex">
        <div className="flex">
          <Button
            htmlType="submit"
            disabled={updateContentDetail.loading || getContentDetail.loading}
          >
            {!updateContentDetail.loading ? (
              "ثبت"
            ) : (
              <DotLoader
                loading={updateContentDetail.loading}
                color="#fff"
                size={22}
              />
            )}
          </Button>
        </div>

        <Button type="primary" disabled={!isupdated} onClick={() => nextStep()}>
          <LeftArrow />
          مرحله ی بعد
        </Button>
      </div>
    </form>
  );
};

export default ContentFirstPhase;
