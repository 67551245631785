import React, { useState } from "react";
import Phase from "@Components/Shared/Phase/Phase";
import ContentFirstPhase from "./ContentFirstPhase";
import ContentSecondPhase from "./ContentSecondPhase";
import ContentTest from "./ContentTest";
import { CreateContentProvider } from "../../../Context/ContentContext";
import { useLocation } from "react-router-dom";

const CreateContentContainer = () => {
  const location = useLocation();
  // console.log('lockedOn+', location.state.lockedOn)
  const [PhaseNum, setPhaseNum] = useState(1);
  const [languageId, setLanguageId] = useState();
  const [languageName, setLanguageName] = useState();
  const [quizId, setQuizId] = useState();

  const PhaseList = [
    {
      PhaseNumber: 1,
      phaseName: "توضیحات",
    },
    {
      PhaseNumber: 2,
      phaseName: " مثال و قطعه کد ",
    },
    {
      PhaseNumber: 3,
      phaseName: " آزمون ",
    },
  ];
  const nextStep = () => {
    setPhaseNum((prevState) => prevState + 1);
  };
  const prevStep = () => {
    setPhaseNum((prevState) => prevState - 1);
  };
  const changeContentsForm = () => {
    switch (PhaseNum) {
      case 1:
        return (
          <ContentFirstPhase
            lockedOn={location.state.lockedOn}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 2:
        return (
          <ContentSecondPhase
            setLanguageId={setLanguageId}
            setLanguageName={setLanguageName}
            setQuizId={setQuizId}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <ContentTest
            languageId={languageId}
            languageName={languageName}
            quizId={quizId}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );

      default:
        break;
    }
  };
  return (
    <div className="mt-10 flex-grow flex flex-col">
      <Phase PhaseNum={PhaseNum} PhaseList={PhaseList} />
      <CreateContentProvider>{changeContentsForm()}</CreateContentProvider>
    </div>
  );
};

export default CreateContentContainer;
