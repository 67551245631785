import classNames from 'classnames';
import React from 'react';
import { useController } from 'react-hook-form';
import Input from 'react-input-mask';

const InputMask = ({
	name,
	value,
	message,
	control,
	register,
	classes,
	label,
	absolute,
	...rest
}) => {
	const {
		field: { ref, ...otherField },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: register,
		defaultValue: value,
	});
	return (
		<div
			className={classNames('input input__mask', {
				input__absolute: absolute,
			})}
		>
			{label && <label className='input__label'>{label} :</label>}
			<Input
				className={classNames('input__field', [classes], {
					input__error: error,
				})}
				formatChars={{ 9: '[0-9]' }}
				maskChar='0'
				alwaysShowMask
				inputRef={ref}
				{...otherField}
				{...rest}
			/>
			{error && <span className='input__message'>{error.message}</span>}
		</div>
	);
};
export default InputMask;
