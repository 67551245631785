import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Textarea from "@Components/Shared/Inputs/Textarea";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import TestCaseInput from "@Components/Shared/TestCaseInput/TestCaseInput";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import QuizeCodeEditor from "./QuizeCodeEditor/QuizeCodeEditor";
import Input from "../../Shared/Inputs/Input";
import { useParams } from "react-router";
import useFetch from "../../../Context/useFetch";
import Upload from "../../Shared/Inputs/Upload";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../../constants";
import { useAuth } from "../../../Context/authContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import { Skeleton } from "antd";
import { tourConfig } from "@Layouts/Quize/QuizStep";
import WebTour from "@Components/Shared/WebTour/WebTour";
import Modal from "@Components/Shared/Modal/Modal";

const ContentTest = ({
  nextStep,
  prevStep,
  languageId,
  languageName,
  quizId,
}) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #ffa44a;
  `;
  const [postData, setPostData] = useState();
  const { contentId, courseId } = useParams();
  const [quizData, setQuizData] = useState(null);
  const [quizUUID, setQuizUUID] = useState(quizId);
  const [loader, setLoader] = useState(false);
  const [loaddata, setLoaddata] = useState(!!quizUUID);
  const [quizResultNumber, setQuizResultNumber] = useState(0);
  const history = useHistory();
  const { token, showGuid, setShowGuid } = useAuth();
  const {
    handleSubmit,
    control,
    formState,
    formState: { errors, isSubmitted },
    reset,
    resetField,
    setValue,
    register,
    getValues,
  } = useForm({});

  const {
    fields: fieldsTestCase,
    append: appendTestCase,
    remove: removeTestCase,
  } = useFieldArray({ name: "testCase", control });

  const {
    fields: fieldsTestCaseUser,
    append: appendTestCaseUser,
    remove: removeTestCaseUser,
  } = useFieldArray({ name: "testCaseUser", control });
  const [isDisplayModal, setDisplayModal] = useState(false);

  const [val, setVal] = useState();
  const [load, setLoad] = useState(false);
  const [firstload, setFirstload] = useState(false);

  const setLoadedData = (data) => {
    setQuizData(data);
    setVal(data.code);
    for (let index = 0; index < data.test_cases.length; index++) {
      if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input === ""
      ) {
        const test = data.test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCase({
            output: test[index],
          });
        }
      } else if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input === "#$$$#"
      ) {
        const test = data.test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCase({
            output: test[index],
          });
        }
      } else if (
        data.test_cases[index].output !== "" &&
        data.test_cases[index].input !== ""
      ) {
        const test = data.test_cases[index].output.split("#$$$#");
        const test1 = data.test_cases[index].input.split("#$$$#");
        for (let index = 0; index < test.length; index++) {
          appendTestCase({ input: [test1[index]], output: test[index] });
        }
      } else {
        appendTestCase();
      }
    }
    for (let index = 0; index < data.user_test_cases.length; index++) {
      if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input === ""
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({
            output: test[index],
          });
        }
      } else if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input === "#$$$#"
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");

        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({
            output: test[index],
          });
        }
      } else if (
        data.user_test_cases[index].output !== "" &&
        data.user_test_cases[index].input !== ""
      ) {
        const test = data.user_test_cases[index].output.split("#$$$#");
        const test1 = data.user_test_cases[index].input.split("#$$$#");
        for (let index = 0; index < test.length; index++) {
          appendTestCaseUser({ input: [test1[index]], output: test[index] });
        }
      }
    }
    setVal(data.code);
    setValue("name", data.name);
    setValue("text", data.text);
    setLoaddata(false);
    // setLoad(false);
    setFirstload(false);
  };
  console.log("fieldsTestCaseUser", fieldsTestCaseUser);

  useEffect(() => {
    if (quizUUID) {
      setLoaddata(true);
      removeTestCase();
      removeTestCaseUser();
      setVal("");
      setFirstload(true);
      getQuiz.reFetch();
    }
  }, [load]);

  const getQuiz = useFetch({
    url: `QuizService/${quizUUID}`,
    trigger: !!quizUUID,
    method: "GET",
    setter: setLoadedData,
  });

  const createQuiz = useFetch({
    url: "QuizService/content_quiz_create",
    data: postData,
    method: "POST",
    trigger: false,
    argFunc: (res) => {
      setQuizUUID(res.uuid);
      // getQuiz.reFetch();
      setQuizResultNumber(0);
      setLoader(false);
      setLoad((perv) => !perv);
      setDisplayModal(false);
    },
    message: "ازمون با موفقیت ساخته شد",
    argErrFunc: (res) => {
      setLoader(false);
    },
  });
  const editQuiz = useFetch({
    url: `QuizService/${quizUUID}`,
    data: postData,
    method: "PATCH",
    trigger: false,
    // caller: getQuiz,
    argFunc: (res) => {
      if (quizData.get_status_display === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      } else {
        toast.success("ازمون با موفقیت ویرایش شد");
      }
      setQuizResultNumber(0);
      setLoader(false);
      setLoad((perv) => !perv);
      setDisplayModal(false);
    },
    argErrFunc: (res) => {
      setLoader(false);
    },
  });

  const deleteContentQuiz = useFetch({
    url: `QuizService/${quizId}`,
    method: "DELETE",
    trigger: false,
    noHeader: false,
    argFunc: (res) => {
      prevStep();
    },
  });
  const [submittedData, setSubmittedData] = useState({});

  const onSubmit = (data) => {
    setLoader(true);
    setSubmittedData(data);
    const formData = new FormData();
    if (data.file && data.file.length !== 0) {
      formData.append("file", data.file[0], data.file[0].name);
    }
    formData.append("content_uuid", contentId);
    formData.append("name", data.name);
    formData.append("text", data.text);
    formData.append(
      "test_case_input",
      data.testCase.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "test_case_output",
      data.testCase.map(({ output }) => output).join("#$$$#")
    );
    formData.append(
      "user_test_case_input",
      data.testCaseUser.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "user_test_case_output",
      data.testCaseUser.map(({ output }) => output).join("#$$$#")
    );
    setPostData(formData);

    if (quizUUID) editQuiz.reFetch();
    else {
      createQuiz.reFetch();
    }
  };
  const toggleDisplayModal = () => {
    setDisplayModal((before) => !before);
  };
  const [data, setData] = useState("");
  const [data1, setData1] = useState([]);

  const QuizSendToServer = useAxios({
    url: `${API_URL}/CompilerService/v3/quiz/new/teacher/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizSendToServerTEACHER", res.data);
        setQuizResultNumber(res.data.compile_result);
        if (res.data.compiler_stdout === "Wrong Answer") {
          toast.error("ازمون را با موفقیت پاس نکردی");
        } else if (res.data.compiler_stdout === "Accepted") {
          toast.success("ازمون با موفقیت پاس شد");
        } else {
          toast.error("ازمون با خطا مواجه شد");
        }
        if (res.data.compile_result === 100) {
          setDisplayModal(true);
        }
        setLoader(false);
      }
      if (err) {
        console.log("err.response: ", err.response);
        setLoader(false);
        // setLoad(false);
      }
    },
  });

  function onChangeEditor(newValue) {
    setVal(newValue);
  }
  const [inputdata, setInputdata] = useState("");
  const handleInputSend = () => {
    console.log('getValues("testCase")', getValues("testCase"));
    if (!val) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else {
      setData({
        submissions: {
          source: val,
          language_id: languageId,
          input_test_case: getValues("testCase").map(
            (e, id, row) =>
              Array.isArray(e.input) &&
              (id === row.length - 1
                ? e.input.length !== 0 &&
                  e.input.map((s, idd, roww) =>
                    idd === roww.length - 1 ? s : s + "#$$#"
                  ) + ""
                : e.input.length !== 0 &&
                  e.input.map((s, idd, roww) =>
                    idd === roww.length - 1 ? s : s + "#$$#"
                  ) + "")
          ),
          output_test_case: getValues("testCase").map((e) => e.output),
          // input: test,
        },
      });
      setLoader(true);
      QuizSendToServer.reFetch();
    }
  };
  const handleTourOpen = () => {
    setShowGuid(true);
  };
  return (
    <div className="Quize__page">
      <div className=" text-left w-full flex items-center justify-end">
        <Button type="primary" onClick={handleTourOpen}>
          راهنما
        </Button>
      </div>
      <form
        className="ContentSecondPhase ContentTest "
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="mb-8">آزمون </p>
        {loaddata ? (
          <Skeleton.Button block active size="large" />
        ) : (
          <Input
            label="  نام "
            register={{
              required: {
                value: true,
                message: "نام را وارد کنید",
              },
            }}
            name="name"
            control={control}
            datatut="reactour__quizname"
          />
        )}
        {loaddata ? (
          <>
            <br />
            <br />
            <Skeleton.Button
              block
              active
              style={{ height: "20rem", width: "100%" }}
            />
            {/* <Skeleton.Button block active size="large" />
          <Skeleton.Button block active size="large" /> */}
            <br />
            <br />
          </>
        ) : (
          <div className=" mt-10">
            <Textarea
              label="  توضیحات "
              register={{
                required: {
                  value: true,
                  message: "توضیحات را وارد کنید",
                },
              }}
              name="text"
              control={control}
              datatut="reactour__quizdes"
            />
          </div>
        )}

        <p className="mt-8">تست کیس ورودی و خروجی</p>
        <p className="Quize-des">
          تست کیس ها برای بررسی کد شما هستند.تا زمانی که نتوانید با قطعه کد
          پیشنهادی و تست کیس ها نمره کامل کسب کنید این مثال تایید نخواهد شد.*
        </p>
        <div data-tut="WebTour__quiztestcase">
          <p className="mt-8">
            {" "}
            نمونه ورودی و خروجی (جهت ارزیابی توسط کامپایلر)
          </p>
          <div className="p-4 ">
            <div className="grid textCaseLable mb-4">
              <p>ورودی:</p>
              <p className="mr-14px">خروجی:</p>
            </div>

            {fieldsTestCase.map((item, index) => (
              <TestCaseInput
                key={item.id}
                item={item}
                mode="add"
                control={control}
                index={index}
                index1={index}
                name="testCase"
                remove={removeTestCase}
                appendTestCase={appendTestCase}
                setData1={setData1}
                data1={data1}
                getValues={getValues}
                setValue={setValue}
                resetField={resetField}
                reset={reset}

              />
            ))}
          </div>
          <Button
            onClick={() => appendTestCase({ input: [], output: "" })}
            type="text"
            size="small"
            success
            datatut="reactour__newtestcase"
          >
            <PlusIcon />
            <p>اضافه کردن نمونه ورودی و خروجی های جدید </p>
          </Button>
        </div>
        <div data-tut="reactour__sampletestcase">
          <p className="mt-8"> نمونه ورودی و خروجی (جهت نمایش به کاربر)</p>
          <div className="p-4 ">
            <div className="grid textCaseLable mb-4">
              <p>ورودی:</p>
              <p className="mr-14px">خروجی:</p>
            </div>

            {fieldsTestCaseUser.map((item, index) => (
              <TestCaseInput
                key={item.id}
                item={item}
                mode="edit"
                control={control}
                name="testCaseUser"
                index={index}
                index1={index}
                remove={removeTestCaseUser}
                appendTestCase={appendTestCaseUser}
                setValue={setValue}
                getValues={getValues}
                setData1={setData1}
                data1={data1}
                resetField={resetField}
                reset={reset}
              />
            ))}
          </div>
          <Button
            onClick={() => appendTestCaseUser({ input: [], output: "" })}
            type="text"
            size="small"
            success
            datatut="reactour__newsampletestcase"
          >
            <PlusIcon />
            <p>اضافه کردن نمونه ورودی و خروجی های جدید </p>
          </Button>{" "}
        </div>
        <div data-tut="reactour__codeeditor">
          <p className="mt-8"> قطعه کد: </p>
          <QuizeCodeEditor
            id={quizData?.uuid}
            value={val}
            languageId={languageId}
            lan={languageName === "c" ? "c_cpp" : languageName}
            onChangeEditor={onChangeEditor}
          />
        </div>
        <div className="d-flex-space px-10">
          <p data-tut="reactour__quizdegree">نمره شما : {quizResultNumber}</p>

          <p>برای ثبت آزمون باید نمره ی 100 دریافت کنید</p>

          <Button
            type="primary"
            disabled={loader || firstload}
            onClick={handleInputSend}
            datatut="reactour__quiztest"
          >
            {loader ? (
              <DotLoader
                color="#fff"
                loading={loader}
                css={override}
                size={22}
              />
            ) : (
              "تست آزمون"
            )}
          </Button>
        </div>

        <div className="mt-8" data-tut="reactour__quizfile">
          <Upload
            label=" آپلود فایل"
            {...register("file", { required: false })}
            message="فایل دوره را وارد کنید"
            control={control}
            error={errors["file"]}
            value={quizData?.file}
            accept=".pdf"
            // onChange={(e) => setFile(e.target.files)}
            id="cover_upload"
          />
        </div>

        <div className="btn-box flex mt-8">
          <div className="flex">
            <Button type="primary" onClick={prevStep}>
              مرحله ی قبل <RightArrow />
            </Button>
            <div className="mr-8">
              <Button onClick={() => history.push(`/my-course/${courseId}`)}>
                برگشت به جلسات
              </Button>
            </div>
          </div>
          <div className="btn-box flex mt-8">
            {/*  <div className="ml-8">*/}
            {/*  /!*  <Button type="primary" disabled>*!/*/}
            {/*  /!*    مرحله ی بعد*!/*/}
            {/*  /!*    <RightArrow />*!/*/}
            {/*  /!*  </Button>*!/*/}
            {/*  /!*</div>*!/*/}

            {/*<Button htmlType="submit">ثبت</Button>*/}
            <Button datatut="reactour__quizcancle">انصراف</Button>

            {quizResultNumber === 100 ? (
              <Button
                htmlType="submit"
                disabled={loader || firstload}
                datatut="reactour__quizfinal"
                classes=" mr-5"
              >
                {" "}
                {loader ? (
                  <DotLoader
                    color="#fff"
                    loading={loader}
                    css={override}
                    size={22}
                  />
                ) : (
                  "ثبت"
                )}
              </Button>
            ) : null}
          </div>
          {quizId && quizData?.get_status_display !== "منتشر شده" && (
            <div className="flex">
              <Button onClick={() => deleteContentQuiz.reFetch()}>
                حذف ازمون{" "}
              </Button>
            </div>
          )}
        </div>
      </form>
      <Modal
        visible={isDisplayModal}
        onCancel={toggleDisplayModal}
        title={"ثبت آزمون"}
        classes="min-w-max pb-0"
        maskClosable={false}
      >
        <p className=" mb-12">
          آزمون ذخیره نشده است، برای ذخیره آزمون روی دکمه ثبت کلیک کنید
        </p>
        <div className=" flex items-center justify-center">
          <Button
            disabled={loader || firstload}
            datatut="reactour__quizfinal"
            classes=" mr-5"
            onClick={handleSubmit(onSubmit)}
          >
            {" "}
            {loader ? (
              <DotLoader
                color="#fff"
                loading={loader}
                css={override}
                size={22}
              />
            ) : (
              "ثبت"
            )}
          </Button>
        </div>
      </Modal>
      <WebTour tourConfig={tourConfig} />
    </div>
  );
};

export default ContentTest;
