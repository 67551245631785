import React, { useState } from "react";
import WorkExperienceDone from "./WorkExperinceDone";
import WorkExperienceForm from "./WorkExperienceForm";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import useFetch from "@App/Context/useFetch";
import { Skeleton } from "antd";

const WorkExperiencePanel = () => {
  const [teacherWorks, setTeacherWorks] = useState({ results: [] });
  const [addItem, setAddItem] = useState(false);

  const getWorks = useFetch({
    url: `TeacherProfessionService`,
    setter: setTeacherWorks,
  });

  const showForm = () => {
    setAddItem((before) => !before);
  };

  return (
    <div className="WorkExperiencePanel">
      {getWorks.response?.data ? (
        teacherWorks.results.map((item) => {
          return [
            <WorkExperienceDone
              key={item.uuid}
              company_name={item.company_name}
              profession_description={item.profession_description}
              profession_end_date={item.profession_end_date}
              profession_start_date={item.profession_start_date}
              profession_title={item.profession_title}
              id={item.uuid}
              caller={getWorks}
              type="work"
              showForm={showForm}
            />,
          ];
        })
      ) : (
        <Skeleton.Button block active size="large" />
      )}
      {addItem && (
        <WorkExperienceForm showForm={showForm} getWorks={getWorks} />
      )}
      {!addItem && (
        <Button type="text" size="small" success onClick={showForm}>
          <PlusIcon />
          <p>اضافه کردن سوابق کاری</p>
        </Button>
      )}
    </div>
  );
};

export default WorkExperiencePanel;
