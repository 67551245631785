import React, { useState } from 'react';
import { useUserData } from '@App/Context/userContext';
import DashboardBox from '../DashboardBox';
import DashboardStudentItem from './DashboardStudentItem';
import avatar from '@Assets/Pic/avatar.png';
import _ from 'lodash';


import { ReactComponent as StudentEmptyIcon } from '@Assets/Icons/student-empty.svg';
import Link from '@Components/Shared/Buttons/Link';



const DashboardStudents = ({ students }) => {
	const studentList = students?.slice(0, 3) || [];

	const renderMessageEmpty = () => (
		<div className='flex flex-col items-center justify-center flex-auto gap-y-8'>
			<StudentEmptyIcon />
			<p>در حال حاضر پیامی ندارید</p>
		</div>
	);

	const renderStudents = () =>
	studentList.map((student) => (
			<DashboardStudentItem key={student.uuid} {...student} />
		));

	return (
		<DashboardBox
			title='بهترین دانشجویان شما:'
			classes='dashboard__message--box'
		>
			{_.isEmpty(studentList) ? (
				renderMessageEmpty()
			) : (
				<>
					<div className='flex flex-col gap-y-3 mt-4'>
						{renderStudents()}
					</div>
					{students.length > 3 && (
						<Link to='/' type='text'>
							بیشتر...
						</Link>
					)}
				</>
			)}
		</DashboardBox>
	);
};

export default DashboardStudents;
