import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Textarea from "@Components/Shared/Inputs/Textarea";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import Button from "@Components/Shared/Buttons/Button";
import TestCaseInput from "@Components/Shared/TestCaseInput/TestCaseInput";
import Upload from "@Components/Shared/Inputs/Upload";
import { useParams } from "react-router";
import useFetch from "../../../../Context/useFetch";
import Input from "../../../Shared/Inputs/Input";
import ExampleEditorBox from "../../../Shared/codeeditor/ExampleEditorBox";
import { toast } from "react-toastify";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
// assets
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ffa44a;
`;
const Example = ({
  getContentDetail,
  resetForm,
  resetEditForm,
  isEdit,
  item,
  updatePositionList,
  contentPosition,
}) => {
  // console.log('item', item);
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitted },
    getValues,
    reset,
    resetField,
  } = useForm({
    defaultValues: {
      name: isEdit ? item.name : "",
      text: isEdit ? item.example_text : "",
      code: isEdit ? item.example_code : "",
      // testCase: isEdit ? item.test_cases : [],
      // testCaseUser: isEdit ? item.user_test_cases : [],
    },
  });
  useEffect(() => {
    if (isEdit) {
      for (let index = 0; index < item.test_cases.length; index++) {
        if (
          item.test_cases[index].output !== "" &&
          item.test_cases[index].input === ""
        ) {
          const test = item.test_cases[index].output.split("#$$$#");
  
          for (let index = 0; index < test.length; index++) {
            appendTestCase({
              output: test[index],
            });
          }
        } else if (
          item.test_cases[index].output !== "" &&
          item.test_cases[index].input === "#$$$#"
        ) {
          const test = item.test_cases[index].output.split("#$$$#");
  
          for (let index = 0; index < test.length; index++) {
            appendTestCase({
              output: test[index],
            });
          }
        } else if (
          item.test_cases[index].output !== "" &&
          item.test_cases[index].input !== ""
        ) {
          const test = item.test_cases[index].output.split("#$$$#");
          const test1 = item.test_cases[index].input.split("#$$$#");
          for (let index = 0; index < test.length; index++) {
            appendTestCase({ input: [test1[index]], output: test[index] });
          }
        } else {
          appendTestCase();
        }
      }
      for (let index = 0; index < item.user_test_cases.length; index++) {
        if (
          item.user_test_cases[index].output !== "" &&
          item.user_test_cases[index].input === ""
        ) {
          const test = item.user_test_cases[index].output.split("#$$$#");

          for (let index = 0; index < test.length; index++) {
            appendTestCaseUser({
              output: test[index],
            });
          }
        } else if (
          item.user_test_cases[index].output !== "" &&
          item.user_test_cases[index].input === "#$$$#"
        ) {
          const test = item.user_test_cases[index].output.split("#$$$#");

          for (let index = 0; index < test.length; index++) {
            appendTestCaseUser({
              output: test[index],
            });
          }
        } else if (
          item.user_test_cases[index].output !== "" &&
          item.user_test_cases[index].input !== ""
        ) {
          const test = item.user_test_cases[index].output.split("#$$$#");
          const test1 = item.user_test_cases[index].input.split("#$$$#");
          for (let index = 0; index < test.length; index++) {
            appendTestCaseUser({ input: [test1[index]], output: test[index] });
          }
        }
      }
    }
  }, [isEdit]);

  const {
    fields: fieldsTestCase,
    append: appendTestCase,
    remove: removeTestCase,
  } = useFieldArray({ name: "testCase", control });

  const {
    fields: fieldsTestCaseUser,
    append: appendTestCaseUser,
    remove: removeTestCaseUser,
  } = useFieldArray({ name: "testCaseUser", control });

  const [postData, setPostData] = useState();
  const [loading, setLoading] = useState(false);
  const { contentId } = useParams();
  // const [file, setfile] = useState(isEdit ? item.file : null);
  const [value, setValue] = useState(isEdit ? item.example_code : "");
  const [data, setData] = useState("");
  const [data1, setData1] = useState([]);
  const createExample = useFetch({
    url: `ExampleService`,
    data: postData,
    method: "POST",
    trigger: false,
    caller: getContentDetail,
    argFunc: () => {
      resetForm();
      updatePositionList();
      setLoading(false);
    },
  });

  const editExample = useFetch({
    url: isEdit ? `ExampleService/${item.uuid}` : undefined,
    data: postData,
    method: "PATCH",
    trigger: false,
    caller: getContentDetail,
    argFunc: (res) => {
      console.log("item.status: ", item.status);
      if (item.status === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      }
      resetEditForm();
      setLoading(false);
    },
    // setter:setCategories,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.file.length !== 0) {
      formData.append("file", data.file[0], data.file[0].name);
    }
    formData.append("content_uuid", contentId);
    formData.append("name", data.name);
    formData.append("text", data.text);
    formData.append("code", value);
    formData.append(
      "example_index",
      isEdit ? item.index : contentPosition.length
    );
    formData.append(
      "test_case_input",
      data.testCase.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "test_case_output",
      data.testCase.map(({ output }) => output).join("#$$$#")
    );
    formData.append(
      "user_test_case_input",
      data.testCaseUser.map(({ input }) => input).join("#$$$#")
    );
    formData.append(
      "user_test_case_output",
      data.testCaseUser.map(({ output }) => output).join("#$$$#")
    );
    setPostData(formData);

    if (!value) {
      toast.error("کدی داخل ادیتور وارد نکرده اید");
    } else {
      setLoading(true);
      if (isEdit) editExample.reFetch();
      else createExample.reFetch();
    }
  };

  function onChangeEditor(newValue) {
    setValue(newValue);
  }

  return (
    <form
      className="content__SubmitFrom content__SubmitFrom--example"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="  نام "
        register={{
          required: {
            value: true,
            message: "نام را وارد کنید",
          },
        }}
        name="name"
        control={control}
      />
      <Textarea
        label="  توضیحات "
        register={{
          required: {
            value: true,
            message: "توضیحات را وارد کنید",
          },
        }}
        name="text"
        control={control}
      />
      <p className="my-8">قطعه کد</p>

      <ExampleEditorBox
        id={item?.uuid}
        onChangeEditor={onChangeEditor}
        lan={
          getContentDetail?.response?.data?.language === "c"
            ? "c_cpp"
            : getContentDetail?.response?.data?.language
        }
        // small={true}
        languageId={getContentDetail?.response?.data?.language_id}
        value={value}
      />
      <p className="mt-8">نمونه ورودی و خروجی (جهت ارزیابی توسط کامپایلر)</p>
      <div className="p-4">
        <div className="grid textCaseLable mb-4">
          <p className="pr-4">ورودی:</p>
          <p>خروجی:</p>
        </div>

        {fieldsTestCase.map((item, index) => (
          <TestCaseInput
            key={item.id}
            item={item}
            mode="edit"
            control={control}
            index={index}
            name="testCase"
            remove={removeTestCase}
            appendTestCase={appendTestCase}
            setData1={setData1}
            data1={data1}
            getValues={getValues}
            setValue={setValue}
            resetField={resetField}
            reset={reset}
          />
        ))}
      </div>

      <Button
        onClick={() => appendTestCase({ input: [], output: "" })}
        type="text"
        size="small"
        success
      >
        <PlusIcon />
        <span>اضافه کردن نمونه ورودی و خروجی های جدید </span>
      </Button>

      <p className="mt-8"> نمونه ورودی و خروجی (جهت نمایش به کاربر)</p>
      <div className="p-4 ">
        <div className="grid textCaseLable mb-4">
          <p className="pr-4">ورودی:</p>
          <p>خروجی:</p>
        </div>

        {fieldsTestCaseUser.map((item, index) => (
          <TestCaseInput
            key={item.id}
            item={item}
            mode="edit"
            control={control}
            name="testCaseUser"
            index={index}
            remove={removeTestCaseUser}
            setValue={setValue}
            getValues={getValues}
            setData1={setData1}
            data1={data1}
            resetField={resetField}
            reset={reset}
          />
        ))}
      </div>

      <Button
        onClick={() => appendTestCaseUser({ input: [], output: "" })}
        type="text"
        size="small"
        success
      >
        <PlusIcon />
        <span>اضافه کردن نمونه ورودی و خروجی های جدید </span>
      </Button>
      <div className="mt-8">
        <Upload
          label=" آپلود فایل"
          {...register("file", { required: false })}
          message="فایل دوره را وارد کنید"
          // name='file'
          control={control}
          error={errors["file"]}
          value={item?.file}
          // isSubmitted={isSubmitted}
          accept=".pdf"
          id="cover_upload"
        />
      </div>
      <div className="flex items-center justify-between mt-16">
        <Button onClick={() => resetForm()}>لغو</Button>
        <Button htmlType="sumbit" type="primary">
          {loading ? (
            <DotLoader
              color="#fff"
              loading={loading}
              css={override}
              size={22}
            />
          ) : (
            "ثبت"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Example;
