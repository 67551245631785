import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Layout from "@Components/Shared/Layout/Layout";

import Dashboard from "./Layouts/Dashboard/Dashboard";
import Profile from "./Layouts/Profile/Profile";
import Resume from "./Layouts/resume/Resume";
import MyCourses from "./Layouts/MyCourse/MyCourses";
import CreateCourse from "./Layouts/CreateCourse/CreateCourse";
import CreateSeasons from "./Layouts/CreateSeasons/CreateSeasons";
import CreateContent from "./Layouts/CreateContent/CreateContent";
import Quize from "./Layouts/Quize/SeasonQuize";
import Wallet from "./Layouts/Wallet/Wallet";
import Settings from "./Layouts/Settings";
import { UserDataProvider } from "./Context/userContext";
import { CreateCourseProvider } from "./Context/CreateCourseContext";
import { PhazeProvider } from "./Context/phazeContext";
import { useUserData } from "@App/Context/userContext";
import ComingSoon from "@Layouts/Messages/ComingSoon";

const Authenticated = () => {
  const { userData } = useUserData();
  console.log("userData: ", userData);
  return (
    <>
      {/*<UserDataProvider>*/}
      <Switch>
        {!userData.user_is_teacher && (
          // <Route path="*">{() => <Redirect to="/"/>}</Route>
          <Layout>
            <>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route path="*">{() => <Redirect to="/profile" />}</Route>
            </>
          </Layout>
        )}

        <Layout>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/resume">
            <Resume />
          </Route>

          <Route exact path="/my-course">
            <MyCourses />
          </Route>
          <Route exact path="/messages">
            <ComingSoon />
          </Route>

          <Route
            exact
            path="/create-course"
            render={(routeProps) => (
              <CreateCourseProvider>
                <PhazeProvider>
                  <CreateCourse key={Math.random(100) * 1000} {...routeProps} />
                </PhazeProvider>
              </CreateCourseProvider>
            )}
          />
          <Route exact path="/my-course/:courseId">
            <CreateSeasons />
          </Route>
          <Route exact path="/my-course/:courseId/:contentId">
            <CreateContent />
          </Route>

          <Route exact path="/wallet">
            <Wallet />
          </Route>
          <Route exact path="/setting">
            <Settings />
          </Route>
          <Route exact path="/my-course/createseasons/add-quize/:courseId/:seasonId">
            <Quize />
          </Route>
          <Route exact path="/coursequiz/:seasonId">
            <Quize />
          </Route>
        </Layout>

        <Route path="*">{() => <Redirect to="/" />}</Route>
      </Switch>
      {/*</UserDataProvider>*/}
    </>
  );
};

export default Authenticated;
