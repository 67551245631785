import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Textarea from "@Components/Shared/Inputs/Textarea";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import useFetch from "../../../../Context/useFetch";
import { useParams } from "react-router";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { toast } from "react-toastify";
import Button from "@Components/Shared/Buttons/Button";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ffa44a;
`;
const Description = ({
  getContentDetail,
  resetEditForm,
  resetForm,
  item,
  isEdit,
  updatePositionList,
  contentPosition,
}) => {
  console.log("item", item);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: isEdit ? item.text : "",
    },
  });

  const [postData, setPostData] = useState();
  const { contentId } = useParams();
  const [loading, setLoading] = useState(false);

  const createCodeText = useFetch({
    url: `CodeTextService`,
    params: { type: "text" },
    data: postData,
    method: "POST",
    trigger: false,
    caller: getContentDetail,
    func: () => {
      resetForm();
      updatePositionList("text");
    },
    // setter:setCategories,
  });

  const editCodeText = useFetch({
    url: isEdit ? `CodeTextService/${item.uuid}` : undefined,
    params: { type: "text" },
    data: postData,
    method: "PATCH",
    trigger: false,
    caller: getContentDetail,
    func: () => {
      resetForm();
      updatePositionList("text");
      if (item.status === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      }
      setLoading(false);
    },

    // setter:setCategories,
  });
  const onSubmit = (data) => {
    setLoading(true);
    setPostData({
      text: data.text,
      content_uuid: contentId,
      codetext_index: isEdit ? item.index : contentPosition.length,
    });

    if (isEdit) editCodeText.reFetch();
    else createCodeText.reFetch();
  };

  // console.log('resetForm: ', resetForm)
  // console.log('resetEditForm: ', resetEditForm)
  return (
    <form
      className="content__SubmitFrom  content__SubmitFrom--description"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Textarea
        label="  توضیحات "
        register={{
          required: { value: true, message: "توضیحات را وارد کنید" },
        }}
        name="text"
        control={control}
        errors={errors}
      />
      <div className="flex items-center justify-between mt-8">
        <Button onClick={() => resetForm()}>لغو</Button>
        <Button htmlType="sumbit" type="primary">
          {loading ? (
            <DotLoader
              color="#fff"
              loading={loading}
              css={override}
              size={22}
            />
          ) : (
            "ثبت"
          )}
        </Button>
      </div>
    </form>
  );
};

export default Description;
