import { UserDataProvider } from "./Context/userContext";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Auth from "./Layouts/Auth/Auth";
import AuthCallback from "./Layouts/Auth/AuthCallback";

const UnAuthenticated = () => {
   ('aa')
  return (
    <>
      {/*<UserDataProvider>*/}
        <Switch>
          <Route exact path="/" component={Auth} />
          <Route exact path="/callback/:token?" component={AuthCallback} />

          <Route path="*">{() => <Redirect to={{ pathname: "/" }} />}</Route>
        </Switch>
      {/*</UserDataProvider>*/}
    </>
  );
};

export default UnAuthenticated;
