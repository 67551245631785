import React, { useState } from "react";
import Upload from "@Components/Shared/Inputs/Upload";
import UploadVideo from "@Components/Shared/Inputs/UploadVideo";
// import UploadVideo from "../../CreateSeasons/UploadVideo";
import { useForm } from "react-hook-form";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import { useDropzone } from "react-dropzone";
import VideoPlayer from "@Components/Shared/Video/VideoPlayer";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import useFetch from "@App/Context/useFetch";
import WebTour from "@Components/Shared/WebTour/WebTour";
import { UploadVideoandImgStep } from "@Components/Layouts/CreateCourse/Step/PropertyStep";
import { useAuth } from "@App/Context/authContext";

const UploadVideoandImg = ({
  nextStep,
  prevStep,
  ToggleModal,
  courseUUID,
  courseData,
}) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #ffa44a;
  `;
  const [cover, setcover] = useState();
  const [video, setVideo] = useState("");
  const [videosrc, setVideosrc] = useState(
    courseUUID ? courseData.intro_video : null
  ); // Todo: courseData.intro_video raise err: ["The submitted data was not a file. Check the encoding type on the form."]
  const [image, setImage] = useState("");
  const [imagesrc, setImagesrc] = useState(
    courseUUID ? courseData.cover : null
  ); // Todo: courseData.cover raise err: ["The submitted data was not a file. Check the encoding type on the form."]
  const [loader, setLoader] = useState(false);
  const [postData, setPostData] = useState();
  const [update, setUpdate] = useState(false);

  const { updateCourseDetail, createInfoData, apiCreateCourse, courseId } =
    useCreateCourse();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();
  const { showGuid, setShowGuid } = useAuth();

  const onSubmit = (data) => {
    setLoader(true);
    // ToggleModal();
    if (video === "" && image !== "" && imagesrc !== null) {
      console.log("A");
      const formData = new FormData();
      formData.append("cover", image);
      setPostData(formData);
      updateContentDetail.reFetch();
    } else if (video !== "" && image === "" && videosrc !== null) {
      const formData = new FormData();
      formData.append("intro_video", video);
      setPostData(formData);
      updateContentDetail.reFetch();
    } else if (
      video !== "" &&
      image !== "" &&
      videosrc !== null &&
      imagesrc !== null
    ) {
      const formData = new FormData();
      formData.append("intro_video", video);
      formData.append("cover", image);
      setPostData(formData);
      updateContentDetail.reFetch();
    } else if (update) {
      console.log("E");
      console.log("update: ", update);
      console.log("image: ", image);
      console.log("video: ", video);
      console.log("imagesrc: ", imagesrc);
      console.log("videosrc: ", videosrc);
      const formData = new FormData();

      if (videosrc && imagesrc) {
        formData.append("intro_video", video);
        formData.append("cover", image);
      } else if (videosrc) {
        // formData.append("intro_video", video);
        formData.append("cover", image);
        // formData.append("cover", image);
      } else if (imagesrc) {
        // formData.append("intro_video", null);
        formData.append("intro_video", video);
        // formData.append("cover", image);
      } else {
        // formData.append("intro_video", null);
        // formData.append("cover", null);
        ToggleModal();
      }
      setPostData(formData);
      deleteContentDetail.reFetch();
    } else {
      console.log("F");
      ToggleModal();
    }
  };
  // const getContentDetail = useFetch({
  //   url: courseUUID
  //     ? `CourseService/${courseUUID}/course_get`
  //     : `CourseService/${courseId}/course_get`,
  //
  //   method: "GET",
  //   argFunc: (res) => {
  //     setVideosrc(res.intro_video);
  //     setImagesrc(res.cover);
  //   },
  // });
  const updateContentDetail = useFetch({
    url: courseUUID
      ? `CourseService/${courseUUID}/course_assets`
      : `CourseService/${courseId}/course_assets`,

    trigger: false,
    data: postData,
    method: "PATCH",
    argFunc: (res) => {
      ToggleModal();
    },
  });
  const deleteContentDetail = useFetch({
    url: courseUUID
      ? `CourseService/${courseUUID}/course_assets`
      : `CourseService/${courseId}/course_assets`,

    trigger: false,
    data: postData,
    method: "PUT",
    argFunc: (res) => {
      ToggleModal();
    },
  });
  const handleSelectVideo = (files) => {
    setVideo(files[0]);
    setVideosrc(URL.createObjectURL(files[0]));
  };
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    // accept: "all/*",
    onDropAccepted: handleSelectVideo,
  });
  const handleDelete = () => {
    setVideo("");
    setVideosrc(null);
    getInputProps();
    setUpdate(true);
  };
  const handleTourOpen = () => {
    setShowGuid(true);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%", margin: "0 auto" }}
    >
      <div className=" grid grid-cols-2 gap-8 DragAndDrop">
        <InnerDropzone
          image={image}
          setImage={setImage}
          setImagesrc={setImagesrc}
          imagesrc={imagesrc}
          setUpdate={setUpdate}
          videosrc={videosrc}
          setVideo={setVideo}
        />
        <div
          onDragOver={() => getInputProps()}
          data-tut="reactour__videoUpload"
        >
          <label className="input__label">آپلود فیلم معرفی دوره :</label>

          <div className=" relative" data-tut="reactour__videoDrag">
            {videosrc === "" || videosrc === null || videosrc === undefined ? (
              <div
                {...getRootProps({
                  className:
                    video === ""
                      ? "DragAndDrop__Box DragAndDrop__video relative"
                      : "DragAndDrop__Box DragAndDrop__video--noborder relative",
                })}
              >
                <input
                  {...getInputProps()}
                  accept="video/*"
                  className="DragAndDrop__video"
                />
                {(videosrc === "" ||
                  videosrc === null ||
                  videosrc === undefined) && (
                  <p className="DragAndDrop__txt absolute">
                    Drag and Drop your file here
                  </p>
                )}
              </div>
            ) : (
              <div>
                <i
                  className="fas fa-trash-alt DragAndDrop__DeleteIcon absolute cursor-pointer"
                  onClick={handleDelete}
                ></i>
                <VideoPlayer src={videosrc} className="DragAndDrop__img" />
                <p className=" text-center" style={{ marginTop: "2rem" }}>
                  {video.name}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="btn-box flex w-full">
        <div className="flex items-center justify-between w-full">
          <Button
            onClick={ToggleModal}
            disabled={loader}
            datatut="reactour__nextstepvideo"
          >
            عبور
          </Button>
          <Button
            type="primary"
            onClick={handleTourOpen}
            key="CourseSecondPhase"
          >
            راهنما
          </Button>
          <Button
            htmlType="submit"
            disabled={loader}
            datatut="reactour__videosave"
          >
            {loader ? (
              <DotLoader
                color="#fff"
                loading={loader}
                css={override}
                size={22}
              />
            ) : (
              "ثبت"
            )}
          </Button>
        </div>
        <WebTour tourConfig={UploadVideoandImgStep} />

        {/* <Button type="primary" onClick={prevStep}>
          <LeftArrow />
          مرحله ی قبل
        </Button> */}
      </div>
    </form>
  );
};

export default UploadVideoandImg;

function InnerDropzone({
  setImage,
  setImagesrc,
  image,
  imagesrc,
  setUpdate,
  setVideo,
  videosrc,
}) {
  // const { getRootProps } = useDropzone({ noDragEventsBubbling: true });

  const handleSelectVideo = (files) => {
    setImage(files[0]);
    setImagesrc(URL.createObjectURL(files[0]));
  };
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    // accept: "all/*",
    onDropAccepted: handleSelectVideo,
  });
  const handleDelete = () => {
    setImage("");
    setImagesrc("");
    setUpdate(true);
  };
  return (
    <div onDragOver={() => getInputProps()} className="reactour__Videoimage">
      <label className="input__label">آپلود عکس کاور دوره :</label>
      <div className=" relative" data-tut="reactour__imageDrag">
        {imagesrc === "" || imagesrc === null || imagesrc === undefined ? (
          <div
            {...getRootProps({
              className:
                image === ""
                  ? "DragAndDrop__Box DragAndDrop__video relative"
                  : "DragAndDrop__Box DragAndDrop__video--noborder relative",
            })}
          >
            <input {...getInputProps()} accept="image/*" />
            {image === "" && (
              <p className="DragAndDrop__txt absolute">
                Drag and Drop your file here
              </p>
            )}
          </div>
        ) : (
          <div className="DragAndDrop__imageBox ">
            <i
              className="fas fa-trash-alt DragAndDrop__DeleteIcon absolute cursor-pointer"
              onClick={handleDelete}
            ></i>
            <img src={imagesrc} alt="imagesrc" className="DragAndDrop__img" />
            <p className=" text-center" style={{ marginTop: "2rem" }}>
              {image.name}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
