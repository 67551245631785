import React, { useCallback, useMemo, useState } from 'react';
import CreditCardEntry from '@Components/Layouts/Wallet/CreditCardEntry';
import IncomelistContainer from '@Components/Layouts/Wallet/IncomelistContainer';
import CardChecking from '@Components/Layouts/Wallet/CardCheking';
import { Redirect } from 'react-router';
import useFetch from '../../Context/useFetch';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const Wallet = () => {
	const [page, setPage] = useState('entry');
	const [isFormFilled, setFormFilled] = useState(true);
	// const [walletData, setWalletData] = useState(null);
	// const [walletLoading, setWalletLoading] = useState(true);

	const handleNextStep = (state) => {
		setPage(state);
	};
	const handlePrevStep = (state) => {
		setPage(state);
	};

	const setData = (data) => {
		// console.log('bbb: ', data)
		setFormFilled(data.is_filled && data.is_accepted);
		if (data.is_accepted) {
			setPage('list');
		} else if (data.is_filled) {
			setPage('checking');
		} else {
			setPage('entry');
		}
		// setWalletData(data)
		// setWalletLoading(false)
	};

	const walletStatus = useFetch({
		url: `TeacherWalletService/get_wallet_status`,
		method: 'GET',
		setter: setData,
	});

	const showPage = () => {
		switch (page) {
			case 'entry':
				return (
					<CreditCardEntry
						isFormFilled={isFormFilled}
						handleNextStep={handleNextStep}
					/>
				);
			case 'list':
				return <IncomelistContainer handleNextStep={handleNextStep} />;
			case 'checking':
				return <CardChecking handlePrevStep={handlePrevStep} />;

			default:
				<Redirect to='/' />;
		}
	};

	return (
		<>
			<BreadCrumb />
			<section className='wallet flex flex-col items-center justify-center'>
				<h1 className='wallet-header'>کیف پول</h1>
				{showPage()}
			</section>
		</>
	);
};

export default Wallet;
