import React from 'react';
import { Rate as RateBase } from 'antd';
import { ReactComponent as StarIcon } from '@Assets/Icons/star.svg';
import classNames from 'classnames';

// const description = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
const Rate = ({ classes, ...rest }) => {
	return (
		<RateBase
			className={classNames('rate', [classes])}
			allowHalf
			character={<StarIcon />}
			{...rest}
		/>
	);
};
export default Rate;
