import React from "react";
import { Modal as ModalBase } from "antd";
import classNames from "classnames";

const Modal = ({ children, classes, ...rest }) => {
  return (
    <ModalBase
      className={classNames("modal", [classes])}
      footer={null}
      centered
      destroyOnClose
      maskClosable={false}
      {...rest}
    >
      {children}
    </ModalBase>
  );
};
export default Modal;
