import React from "react";
import { NavLink } from "react-router-dom";

const SidebarMenuItem = ({
  url,
  icon,
  text,
  user_is_teacher,
  expand,
  ...rest
}) => {
  console.log("user_is_teacher", user_is_teacher);
  return (
    <li className="sidebarMenu__li d-flex-align " {...rest}>
      <NavLink
        exact
        to={!user_is_teacher && url !== "/" ? "/profile" : url}
        className="d-flex-align"
        activeClassName={
          !user_is_teacher && url !== "/" ? "selectedgust" : "selected"
        }
      >
        {!expand ? (
          <>
            <div className="menuIcon">{icon}</div>
            <p>{text}</p>
          </>
        ) : (
          <div className="menuIcon">{icon}</div>
        )}
      </NavLink>
    </li>
  );
};

export default SidebarMenuItem;
