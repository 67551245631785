import React from 'react';
import CreateCourseContainer from '@Components/Layouts/CreateCourse/CreateCourseContainer';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const CreateCourse = (props) => {
	return (
		<>
			<BreadCrumb />
			<CreateCourseContainer
				courseUUID={props?.history?.location?.state?.courseUUID}
			/>
		</>
	);
};

export default CreateCourse;
