import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "@Components/Shared/Inputs/Input";
import InputMask from "@Components/Shared/Inputs/InputMask";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import Button from "@Components/Shared/Buttons/Button";
import CheckboxInput from "@Components/Shared/Inputs/CheckboxInput";
import useFetch from "@App/Context/useFetch";
import { useParams } from "react-router";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import _ from "lodash";
import { toast } from "react-toastify";

const CreateSeasonsForm = ({ handleShowForm, getCoursesSeasons, data }) => {
  const isEdit = !_.isEmpty(data);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      season_lock: isEdit ? data.lockedOn : false,
      season_name: isEdit ? data.title : "",
      season_time: isEdit ? data.duration_time : "",
    },
  });
  const { courseId } = useParams();
  const [postData, setPostData] = useState();
  const createCourseSeason = useFetch({
    url: `SeasonService`,
    method: "POST",
    trigger: false,
    data: postData,
    func: () => handleShowForm(),
    caller: getCoursesSeasons,
  });

  const editCourseSeason = useFetch({
    url: data ? `SeasonService/${data.uuid}` : undefined,
    method: "PATCH",
    trigger: false,
    data: postData,
    func: () => handleShowForm(),
    argFunc: (res) => {
      console.log("selectedSeason: ", data);
      if (data.get_status_display === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      }
      getCoursesSeasons.reFetch();
    },
  });
  const onSubmit = (data) => {
    setPostData({
      title: data.season_name,
      course_uuid: courseId,
      lockedOn: data.season_lock,
      duration_time: data.season_time,
    });
    if (isEdit) editCourseSeason.reFetch();
    else createCourseSeason.reFetch();
  };

  return (
    <form className="CreateSeasonsForm" onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="موضوع"
        register={{
          required: {
            value: true,
            message: " موضوع جلسه را وارد کنید",
          },
        }}
        name="season_name"
        control={control}
        absolute
      />
      <div className="CreateSeasonsForm__row">
        <InputMask
          label="مدت زمان"
          register={{
            required: { value: true, message: "تایم جلسه را وارد کنید" },
          }}
          name="season_time"
          control={control}
          mask="99:99:99"
          absolute
        />
        <CheckboxInput
          label="قفل مبحث"
          register={{
            required: false,
          }}
          name="season_lock"
          control={control}
          large
        />
      </div>
      <Button
        htmlType="submit"
        type="primary"
        disabled={createCourseSeason.loading || editCourseSeason.loading}
      >
        {isEdit ? "ویرایش" : "ثبت"}
      </Button>
    </form>
  );
};

export default CreateSeasonsForm;
