import { useAuth } from "@App/Context/authContext";
import React, { Component } from "react";
import { useEffect } from "react";
import { HOME_URL } from "../../constants";
import { useHistory } from "react-router-dom";

const Auth = () => {
  useEffect(() => {}, []);

  const { token } = useAuth();
  const history = useHistory();
  //  ("token", token);
  const changeRoute = () => {
    if (!token) {
      //  ("token2", token);
      // window.location.href = HOME_URL + "/dashboard/?redirectTeacher";
      window.location.href = HOME_URL + "?redirectTeacher";
    } else {
      //  ("token1", token);
      //   history.push("/");
    }
  };
  return <>{changeRoute()}</>;
};

export default Auth;
