import PropTypes from "prop-types";
import React, { forwardRef, useState, useEffect } from "react";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "@Components/Shared/Modal/Modal";
import Button from "@Components/Shared/Buttons/Button";

const UploadProfile = forwardRef(
  ({ defaultImage, label, setCroppedImage1, ...rest }, ref) => {
    const [imagePreviewUrl, setstateimagePreviewUrl] = useState("");
    const [coverfile, setcover] = useState(null);
    const [crop, setCrop] = useState(true);
    const [isDisplayModal, setDisplayModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const handleImageChange = (e) => {
      setImageToCrop(undefined);
      setCroppedImage(undefined);
      setCrop(true);
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        setcover(e.target.files[0]);

        reader.addEventListener("load", () => {
          const image = reader.result;
          setstateimagePreviewUrl(reader.result);
          setImageToCrop(image);
        });

        reader.readAsDataURL(e.target.files[0]);
      }
      setDisplayModal(true);
    };
    const [cropConfig, setCropConfig] = useState(
      // default crop config
      {
        // unit: "%",
        width: 80,
        height: 80,
        aspect: 16 / 16,
      }
    );
    const [imageRef, setImageRef] = useState();

    async function cropImage(crop) {
      if (imageRef && crop.width && crop.height) {
        const croppedImage = await getCroppedImage(
          imageRef,
          crop,
          "croppedImage.jpeg" // destination filename
        );

        // calling the props function to expose
        // croppedImage to the parent component
        setCroppedImage(croppedImage);
      }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
      // creating the cropped image from the source image
      const canvas = document.createElement("canvas");
      const scaleX = sourceImage.naturalWidth / sourceImage.width;
      const scaleY = sourceImage.naturalHeight / sourceImage.height;
      canvas.width = cropConfig.width;
      canvas.height = cropConfig.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        sourceImage,
        cropConfig.x * scaleX,
        cropConfig.y * scaleY,
        cropConfig.width * scaleX,
        cropConfig.height * scaleY,
        0,
        0,
        cropConfig.width,
        cropConfig.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          // returning an error
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }

          blob.name = fileName;
          setCroppedImage1(blob);
          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);

          resolve(croppedImageUrl);
        }, "image/jpeg");
      });
    }
    const toggleDisplayModal = () => {
      setDisplayModal((before) => !before);
    };
    const handleCancle = () => {
      setCroppedImage(undefined);
      setDisplayModal((before) => !before);
    };
    return (
      <div className=" flex items-center">
        <div className="profile__uploader  relative">
          <div className="profile__uploader--cover">
            <label htmlFor="image__cover--perview">
              {coverfile ? (
                <img
                  alt="Cropped Img"
                  src={croppedImage ? croppedImage : imagePreviewUrl}
                  className={
                    croppedImage ? " imagePreview--center" : "imagePreview "
                  }
                />
              ) : (
                <img
                  alt="imagePreview"
                  className="imagePreview"
                  src={defaultImage}
                />
              )}
            </label>
          </div>
          <div className="UploadProfile--uploadcoverbox">
            <input
              type="file"
              accept=".png , .jpg"
              className="UploadProfile__input "
              id="image__cover--perview"
              onInput={handleImageChange}
              onFocus={(e) => (e.target.placeholder = "")}
              ref={ref}
              // defaultValue={convertToFile()}
              {...rest}
            />
            <span
              className="UploadProfile__label"
              htmlFor="image__cover--perview"
            >
              <PlusIcon />
            </span>
          </div>
        </div>

        {crop && (
          <Modal
            visible={isDisplayModal}
            onCancel={toggleDisplayModal}
            title="عکس پروفایل"
            classes="min-w-max pb-0"
          >
            <div className=" flex items-center justify-center w-full">
              <ReactCrop
                src={imageToCrop}
                crop={cropConfig}
                ruleOfThirds
                onImageLoaded={(imageRef) => setImageRef(imageRef)}
                onComplete={(cropConfig) => cropImage(cropConfig)}
                onChange={(cropConfig) => setCropConfig(cropConfig)}
                crossorigin="anonymous" // to avoid CORS-related problems
              />
            </div>

            <div className=" flex items-center justify-between mt-8">
              <Button type="primary" onClick={toggleDisplayModal}>
                کراپ
              </Button>
              <Button onClick={handleCancle}>کنسل</Button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
);

UploadProfile.propTypes = {
  defaultImage: PropTypes.string,
  register: PropTypes.any,
  croppedImage: PropTypes.any,
};

export default UploadProfile;
