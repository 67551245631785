import React, { useState } from "react";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import PropertyForm from "./Form/PropertyForm";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import PrerequisiteForm from "./Form/PrerequisiteForm";

const AttributeItem = ({ item, toggleForm, field, index }) => {
  const [edit, setEdit] = useState(false);
  const { deleteCourseStepTwoItem } = useCreateCourse();
  const closeForm = () => {
    setEdit(false);
  };
  return (
    <>
      {!edit ? (
        <div className="AttributeItem flex">
          <div className="AttributeItem__Bg">{item}</div>
          <IconBtn
            onClick={() => deleteCourseStepTwoItem("stepTwo", field, index)}
          >
            <Trash />
          </IconBtn>
          <IconBtn onClick={() => setEdit(!edit)}>
            <Pencil />
          </IconBtn>
        </div>
      ) : field === "courseFeature" ? (
        <PropertyForm
          toggleForm={toggleForm}
          item={item}
          index={index}
          isEdit
          field={field}
          closeForm={closeForm}
        />
      ) : (
        <PrerequisiteForm
          toggleForm={toggleForm}
          item={item}
          index={index}
          isEdit
          field={field}
          closeForm={closeForm}
        />
      )}
    </>
  );
};

export default AttributeItem;
