import React from 'react';
import Appbar from '../Menu/Appbar';
import SidebarMenu from '@Components/Shared/Menu/SidebarMenu';
import { Layout as DashboardLayout } from 'antd';

const { Content } = DashboardLayout;

const Layout = ({ children }) => {
	return (
		<>
			<DashboardLayout className='layout__dashboard'>
				<header className='layout__header'>
					<Appbar />
				</header>
				<section className='layout__wrapper'>
					<aside className='layout__sidebar'>
						<SidebarMenu />
					</aside>
					<Content className='layout__content'>{children}</Content>
				</section>
			</DashboardLayout>
			{/*<Footer/>*/}
		</>
	);
};
export default Layout;
