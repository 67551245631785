import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import SeasonsContentItem from "./SeasonsContentItem";
import SeasonContentForm from "./SeasonContentForm";
import useFetch from "../../../Context/useFetch";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import { Skeleton } from "antd";

const CreateSeasonContent = ({
  id,
  seasonContents,
  getCoursesSeasonsContent,
  type,
}) => {
  const [Form, setForm] = useState(false);
  const [season_contents, setSeason_contents] = useState({
    contents: seasonContents.contents.length > 0 ? seasonContents.contents : [],
  });
  console.log("seasonContents: ", seasonContents);
  // console.log('season_contents: ', season_contents);
  const ToggleForm = () => {
    setForm((before) => !before);
  };

  const triggerSortingContent = useFetch({
    url: `SeasonService/${id}/sortingContent`,
    method: "POST",
    trigger: false,
    data: {
      new_items: season_contents.contents.map((item) => ({
        uuid: item.uuid,
        index: item.index,
      })),
    },
    caller: getCoursesSeasonsContent,
    message: "مرتب سازی با موفقیت انجام شد",
  });

  const handleDragEnd = (result) => {
    console.log("seasonContents.contents: ", seasonContents.contents);
    const { destination, source } = result;
    console.log({ result });
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = Array.from(season_contents.contents);
    console.log("items: ", items);
    const [reorderedItem] = items.splice(source.index, 1);
    console.log("reorderedItem: ", reorderedItem);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log("items2: ", items);
    items.forEach((item, key) => {
      item.index = key;
    });
    console.log("items3: ", items);
    setSeason_contents((before) => ({ contents: items }));
  };
  useEffect(() => {
    setSeason_contents({ contents: seasonContents.contents });
  }, [seasonContents.contents.length]);

  // console.log('seasonContents.contents',seasonContents.contents)
  return (
    <div className="CreateSeasonContent px-20">
      <div className="d-flex-space mb-4">
        <p className=" CreateSeasonContent_title">مبحث:</p>
        <div className="d-flex">
          <Button
            onClick={ToggleForm}
            type="text"
            size="small"
            success
            // disabled={type === "منتشر شده" ? true : false}
            // classes={type === "منتشر شده" ? " cursor-not-allowed" : ""}
          >
            <PlusIcon />
            <p>اضافه کردن مبحث جدید</p>
          </Button>
          <div className="d-flex-justify-start ">
            <Button
              onClick={() => triggerSortingContent.reFetch()}
              disabled={
                triggerSortingContent.loading || type === "منتشر شده"
                  ? true
                  : false
              }
              // disabled={triggerSortingContent.loading}
            >
              ثبت مرتب سازی مباحث
            </Button>
          </div>
        </div>
      </div>
      {Form && (
        <SeasonContentForm
          id={id}
          ToggleForm={ToggleForm}
          getCoursesSeasonsContent={getCoursesSeasonsContent}
        />
      )}
      {!getCoursesSeasonsContent.loading ? (
        <>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="season-content">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // className='ContentSecondPhase__content'
                >
                  {season_contents.contents.map((item, index) => {
                    return (
                      <Draggable
                        key={`item-${item.uuid}`}
                        draggableId={`item-${item.uuid}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              isDrag: snapshot.isDragging,
                            })}
                          >
                            <SeasonsContentItem
                              key={item.uuid}
                              status={item.get_status_display}
                              title={item.title}
                              video_data={item.video}
                              id={item.uuid}
                              getCoursesSeasonsContent={
                                getCoursesSeasonsContent
                              }
                              lockedOn={item.lockedOn}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <div className="w-full">
          <Skeleton.Button block active size="large" />
          <br />
          <br />
          <Skeleton.Button block active size="large" />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default CreateSeasonContent;
