import { Statistic } from 'antd';
import classNames from 'classnames';
import React from 'react';

const Price = ({
	display_inline,
	isDiscount,
	success,
	underline,
	value,
	...rest
}) => {
	return (
		<div
			className={classNames('price', {
				isSuccess: success,
				discount: isDiscount,
				underline: underline,
				inline: display_inline,
			})}
		>
			<Statistic value={value} {...rest} />
		</div>
	);
};
export default Price;
