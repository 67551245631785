import React, { useState, useEffect } from "react";
import Phase from "@Components/Shared/Phase/Phase";
import FormResultAlarmWrapper from "@Components/Shared/FormResultAlarmPage/FormResultAlarmWrapper";
import successPic from "@Assets/Pic/successCreateCourse.png";
import BasicInfoForm from "@Components/Layouts/CreateCourse/Form/BasicInfoForm";
import CourseSecondPhase from "@Components/Layouts/CreateCourse/CourseSecondPhase";
import SupplementaryForm from "@Components/Layouts/CreateCourse/Form/SupplementaryForm";
import UploadVideoandImg from "@Components/Layouts/CreateCourse/Form/UploadVideoandImg";
import useFetch from "../../../Context/useFetch";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import WebTour from "@Components/Shared/WebTour/WebTour";
import {
  PropertyStep,
  SupplementaryStep,
  UploadVideoandImgStep,
} from "@Components/Layouts/CreateCourse/Step/PropertyStep";
// import { SupplementaryStep } from "@Components/Layouts/CreateCourse/Step/SupplementaryStep";
import { useAuth } from "@App/Context/authContext";
import Button from "@Components/Shared/Buttons/Button";
import { usePhaze } from "@App/Context/phazeContext";
const PhaseList = [
  {
    PhaseNumber: 1,
    phaseName: " مشخصات اولیه ",
  },
  {
    PhaseNumber: 2,
    phaseName: "ویژگی و پیشنیاز ",
  },
  {
    PhaseNumber: 3,
    phaseName: " مشخصات تکمیلی",
  },
  {
    PhaseNumber: 4,
    phaseName: " عکس و فیلم دوره",
  },
];

const CreateCourseContainer = ({ courseUUID }) => {
  const { phaze, setPhaze } = usePhaze();
  const [PhaseNum, setPhaseNum] = useState(phaze);
  const [resultModal, setresultModal] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [courseDataLoading, setCourseDataLoading] = useState(true);
  const { courseId } = useCreateCourse();
  const setData = (data) => {
    setCourseData(data);
    setCourseDataLoading(false);
  };
  const { showGuid, setShowGuid } = useAuth();

  const getCourse = useFetch({
    url: `CourseService/${courseUUID}/course_get`,
    method: "GET",
    trigger: courseUUID ? true : false,
    setter: setData,
  });

  const nextStep = () => {
    setPhaseNum((prevState) => prevState + 1);
    setPhaze((prevState) => prevState + 1);
    // localStorage.setItem("phaz", PhaseNum + 1);
  };
  const prevStep = () => {
    setPhaseNum((prevState) => prevState - 1);
    setPhaze((prevState) => prevState - 1);
  };
  const ToggleModal = () => {
    setresultModal((prevState) => !prevState);
  };
  const handleTourOpen = () => {
    setShowGuid(true);
  };
  const handleTourOpensecFaz = () => {
    // setPhaseNum(2);
    // localStorage.setItem("phaz", 2);
    setShowGuid(true);
  };
  const changeForm = () => {
    switch (PhaseNum) {
      case 1:
        return (
          <>
            {courseUUID ? (
              <>
                {!courseDataLoading ? (
                  <>
                    <BasicInfoForm
                      nextStep={nextStep}
                      prevStep={prevStep}
                      courseData={courseData}
                    />
                    {/* <Button
                      type="primary"
                      onClick={handleTourOpen}
                      key="BasicInfoForm"
                    >
                      راهنما
                    </Button>
                    <WebTour tourConfig={tourConfig} /> */}
                  </>
                ) : null}
              </>
            ) : (
              <>
                <BasicInfoForm nextStep={nextStep} prevStep={prevStep} />
                {/* <Button
                  type="primary"
                  onClick={handleTourOpen}
                  key="BasicInfoForm"
                >
                  راهنما
                </Button>
                <WebTour tourConfig={tourConfig} /> */}
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            {courseUUID ? (
              !courseDataLoading ? (
                <>
                  <CourseSecondPhase
                    nextStep={nextStep}
                    prevStep={prevStep}
                    courseData={courseData}
                  />
                  <Button
                    type="primary"
                    onClick={handleTourOpensecFaz}
                    key="CourseSecondPhase"
                  >
                    راهنما
                  </Button>
                  <WebTour tourConfig={PropertyStep} />
                </>
              ) : null
            ) : (
              <>
                <CourseSecondPhase nextStep={nextStep} prevStep={prevStep} />
                <Button
                  type="primary"
                  onClick={handleTourOpensecFaz}
                  key="CourseSecondPhase"
                >
                  راهنما
                </Button>
                <WebTour tourConfig={PropertyStep} />
              </>
            )}
          </>
        );

      case 3:
        return (
          <>
            {courseUUID ? (
              !courseDataLoading ? (
                <>
                  <SupplementaryForm
                    nextStep={nextStep}
                    prevStep={prevStep}
                    courseData={courseData}
                    getCourse={getCourse}
                    edit={true}
                  />
                  <Button
                    type="primary"
                    onClick={handleTourOpensecFaz}
                    key="CourseThirdPhase"
                    classes=" mt-10"
                  >
                    راهنما
                  </Button>
                  <WebTour tourConfig={SupplementaryStep} />
                </>
              ) : null
            ) : (
              <>
                <SupplementaryForm
                  nextStep={nextStep}
                  prevStep={prevStep}
                  edit={false}
                />
                <Button
                  type="primary"
                  onClick={handleTourOpensecFaz}
                  key="CourseThirdPhase"
                  classes=" mt-10"
                >
                  راهنما
                </Button>
                <WebTour tourConfig={SupplementaryStep} />
              </>
            )}
          </>
        );

      case 4:
        return (
          <>
            {courseUUID ? (
              !courseDataLoading ? (
                <>
                  <UploadVideoandImg
                    ToggleModal={ToggleModal}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    courseData={courseData}
                    courseUUID={courseUUID}
                  />
                </>
              ) : null
            ) : (
              <>
                <UploadVideoandImg
                  ToggleModal={ToggleModal}
                  nextStep={nextStep}
                  prevStep={prevStep}
                />
                {/* <Button
                  type="primary"
                  onClick={handleTourOpensecFaz}
                  key="CourseSecondPhase"
                >
                  راهنما
                </Button>
                <WebTour tourConfig={UploadVideoandImgStep} /> */}
              </>
            )}
          </>
        );

      default:
        break;
    }
  };
  return (
    <>
      {!resultModal ? (
        <div className="mt-10 create__course">
          <Phase PhaseNum={PhaseNum} PhaseList={PhaseList} />
          {changeForm()}
        </div>
      ) : (
        <div className="m-10">
          <FormResultAlarmWrapper
            title="مشخصات دوره شما تکمیل شد.اکنون میتوانید آنرا در لیست دوره هایتان مشاهده کرده و جلسات و مباحثتان را به آن اضافه کنید"
            pic={successPic}
            outlineBtnName="همه ی دوره ها"
            PrimaryBtnName="افزودن جلسات"
            outlineBtnNameLink="/my-course"
            PrimaryBtnNameLink={`/my-course/${
              courseId ? courseId : courseUUID
            }`}
          />
        </div>
      )}
    </>
  );
};

export default CreateCourseContainer;
