import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";

export const PropertyStep = [
  {
    selector: '[data-tut="reactour__secondphaze"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          ویژگی و پیشنیاز
        </div>
        <div style={{ marginTop: "15px" }}>
          ویژگی و پیشنیاز دوره را وارد نمایید، تا این مرحله کامل نشده، نمیتوانید
          مرحله بعد بروید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            1 of 8
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          ویژگی های دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          ویژگی های دوره مورد نظر را وارد نمایید ، تا این مرحله تایید نشود،
          نمیتوانید مرحله بعد را تکمیل نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            2 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseaddNEWProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          اضافه کردن ویژگی جدید
        </div>
        <div style={{ marginTop: "15px" }}>
          برای اضافه کردن ویژگی جدید، روی این دکمه کلیک نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            3 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseSAVENEWProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          تایید کردن ویژگی جدید
        </div>
        <div style={{ marginTop: "15px" }}>
          برای نایید کردن ویژگی جدید، روی این دکمه کلیک نمایید
          <IconBtn
            htmlType="submit"
            success
            title="ثبت"
            icon={<Submit />}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            4 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__coursePREProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          پیش نیاز های دوره{" "}
        </div>
        <div style={{ marginTop: "15px" }}>
          پیش نیاز های دوره مورد نظر را وارد نمایید ، تا این مرحله تایید نشود،
          نمیتوانید مرحله بعد را تکمیل نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            5 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseaddNEWPREProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          اضافه کردن پیش نیاز جدید
        </div>
        <div style={{ marginTop: "15px" }}>
          برای اضافه کردن پیش نیاز جدید، روی این دکمه کلیک نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            6 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseaNextProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مرحله بعد</div>
        <div style={{ marginTop: "15px" }}>
          از این قسمت میتونی به مباحث بعدی دسترسی داشته باشی. در صورتی این گزینه
          فعاله که تمامی مراحل این مبحث رو پر کرده باشی
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            7 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courseaBeforeProperti"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مرحله قبل</div>
        <div style={{ marginTop: "15px" }}>
          از این قسمت میتونی به مبحث قبلی دسترسی داشته باشی.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            8 of 8
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
];

export const SupplementaryStep = [
  {
    selector: '[data-tut="reactour__Thirdphaze"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مشخصات تکمیلی</div>
        <div style={{ marginTop: "15px" }}>
          مشخصات تکمیلی دوره را وارد نمایید، تا این مرحله کامل نشده، نمیتوانید
          مرحله بعد بروید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            1 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: ".reactour__Supplementary",
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          دسته بندی دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          دسته بندی دوره مورد نظر را از لیست زیر انتخاب نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            2 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: ".reactour__TagSupplementary",
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>تگ های دوره</div>
        <div style={{ marginTop: "15px" }}>
          تگ های دوره مورد نظر را از لیست زیر انتخاب نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            3 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__Thirdtextarea"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>توضیحات دوره</div>
        <div style={{ marginTop: "15px" }}>
          تگ های دوره مورد نظر را از لیست زیر انتخاب نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            4 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__Thirdsave"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>ثبت</div>
        <div style={{ marginTop: "15px" }}>
          ثبت مشخصات تکمیلی ، تا این مرحله ثبت نشود، امکان رفتن به مرحله بعد
          فراهم نمیشود
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            5 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__ThirdsaveNext"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مرحله بعد</div>
        <div style={{ marginTop: "15px" }}>
          از این قسمت میتونی به مباحث بعدی دسترسی داشته باشی. در صورتی این گزینه
          فعاله که تمامی مراحل این مبحث رو پر کرده باشی
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            6 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__ThirdsaveBefore"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مرحله قبل</div>
        <div style={{ marginTop: "15px" }}>
          از این قسمت میتونی به مبحث قبلی دسترسی داشته باشی.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            7 of 7
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
];
export const UploadVideoandImgStep = [
  {
    selector: '[data-tut="reactour__Forthphaze"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          عکس و فیلم دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          عکس و فیلم دوره ، این مرحله اختیاری میباشد ، اما تا عکس کاور و فیلم
          معرفی آپلود نشود،دوه پابلیش نمیشود
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            1 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: ".reactour__Videoimage",
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          آپلود عکس کاور دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          آپلود عکس کاور دوره ، این قسمت برای آپلود عکس کاور دوره میباشد
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            2 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__imageDrag"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          آپلود عکس کاور دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          روی این باکس کلیک کنید یا عکس را بر روی آن درگ کنید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            3 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__videoUpload"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          آپلود فیلم معرفی دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          آپلود فیلم دوره ، این قسمت برای آپلود عکس کاور دوره میباشد
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            4 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__videoDrag"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>
          آپلود فیلم معرفی دوره
        </div>
        <div style={{ marginTop: "15px" }}>
          روی این باکس کلیک کنید یا فیلم را بر روی آن درگ کنید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            5 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__nextstepvideo"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>عبور</div>
        <div style={{ marginTop: "15px" }}>
          عبور، با کلیک بر روی این دکمه، مینوانید بدون آپلود عکس یا ویدیو، ایجاد
          دوره را به پایان برسانید. (یاداوری میشود ،تا عکس کاور و فیلم معرفی
          آپلود نشود،دوره تایید نمیشود)
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            6 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__videosave"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>ثبت</div>
        <div style={{ marginTop: "15px" }}>
          با کلیک بر روی این دکمه، عکس کاور یا ویدیو و یا هردو، آپلود میشود، و
          سپس ایجاد دوره به اتمام میرسد
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            7 of 7
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
];
