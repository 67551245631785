import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "@Components/Shared/Inputs/Input";
import Textarea from "@Components/Shared/Inputs/Textarea";
import DatePickerInput from "@Components/Shared/Inputs/DatePickerInput";
import Select from "@Components/Shared/Inputs/Select";
import UploadProfile from "@Components/Shared/Inputs/UploadProfile";
import Button from "@Components/Shared/Buttons/Button";
import useFetch from "../../../Context/useFetch";
import Upload from "@Components/Shared/Inputs/Upload";
import { useUserData } from "@App/Context/userContext";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";

//icons
import { ReactComponent as Github } from "@Assets/Icons/github.svg";
import { ReactComponent as Instagram } from "@Assets/Icons/instagram.svg";
import { ReactComponent as Linkdin } from "@Assets/Icons/linkdin.svg";
import { ReactComponent as Telegram } from "@Assets/Icons/TelegramLogo.svg";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #fff;
`;

const optionList = [
  { label: "دیپلم", value: "U.DP" },
  { label: "فوق دیپلم ", value: "DP" },
  { label: "کارشناسی ", value: "B.S" },
  { label: "کارشناسی ارشد ", value: "M.S" },
  { label: "دکترا ", value: "Ph.D" },
];

const ProfileForm = () => {
  const { userData, getUser } = useUserData();

  const [profileData, setProfileData] = useState(null);
  const [profilepostData, setProfilepostData] = useState(null);

  const [ProfileLoading, setProfileLoading] = useState(true);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();
  const [croppedImage, setCroppedImage] = useState(undefined);

  const onSubmit = (data) => {
    let formData = new FormData();
    if (croppedImage !== undefined) {
      formData.append("cover", croppedImage, croppedImage.name);
      // formData.append("cover", croppedImage, "aaaa");
    }
    if (croppedImage === undefined && data.cover.length !== 0) {
      formData.append("cover", data.cover[0], data.cover[0].name);
    }
    if (data.resume.length !== 0) {
      formData.append("resume", data.resume[0], data.resume[0].name);
    }

    formData.append("description", data.description);
    formData.append("first_name", data.first_name);
    formData.append(
      "birth_date",
      data.birth_date.convert().format("YYYY-MM-DD")
    );
    formData.append("province", data.province);
    formData.append("last_name", data.last_name);
    formData.append("degree", data.degree);
    formData.append("major", data.major);
    formData.append("email", data.email);
    formData.append("phone_number", data.phone_number);
    formData.append("address", data.address);
    formData.append("github", data.github);
    formData.append("telegram", data.telegram);
    formData.append("instagram", data.instagram);
    formData.append("linkedin", data.linkedin);
    setProfilepostData(formData);
    PostProfile.reFetch();
  };

  const setData = (data) => {
    setProfileData(data);
    setProfileLoading(false);
  };

  const getuserInfo = useFetch({
    url: `TeacherService/profile_get`,
    method: "GET",
    trigger: userData.user_is_teacher ? true : false,
    setter: setData,
  });

  const PostProfile = useFetch({
    url: !userData.user_is_teacher
      ? "TeacherService"
      : `TeacherService/${profileData?.uuid}`,
    method: !userData.user_is_teacher ? "POST" : "PATCH",
    trigger: false,
    data: profilepostData,
    caller: getuserInfo,
    message: !userData.user_is_teacher
      ? "اطلاعات با موفقیت ثبت شد"
      : "اطلاعات با موفقیت ویرایش شد",
    argFunc: (res) => {
      // !userData.user_is_teacher && getUser.reFetch()
      getUser.reFetch();
    },
  });

  return (
    <>
      {!ProfileLoading || !userData.user_is_teacher ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-wrapper">
            <div className="uploadProfile__box flex">
              <UploadProfile
                defaultImage={
                  !userData.user_is_teacher
                    ? userData?.cover
                    : profileData?.cover
                }
                {...register("cover", {
                  // required: isEmpty(profileData?.resume),
                  required: false,
                })}
                name="cover"
                setCroppedImage1={setCroppedImage}
              />
              {/* <input type="file" accept="image/*" onChange={onUploadFile} /> */}

              <div className="mr-10">
                <p className="text-primary uploadProfile__box-name">
                  {userData?.first_name}
                </p>
                <p className="text-primary uploadProfile__box-id">
                  {userData?.last_name}
                </p>
              </div>
            </div>

            <Input
              label="نام"
              register={{
                required: {
                  value: true,
                  message: "نام را وارد کنید",
                },
              }}
              name="first_name"
              // disabled={true}
              control={control}
              value={userData?.first_name}
            />
            <Input
              label="نام خانوادگی"
              register={{
                required: {
                  value: true,
                  message: "نام  خانوادگی را وارد کنید",
                },
              }}
              name="last_name"
              // disabled={true}
              control={control}
              value={userData?.last_name}
            />
            <Select
              label="میزان تحصیلات"
              register={{
                required: true,
              }}
              name="degree"
              control={control}
              options={optionList}
              message="میزان تحصیلات را وارد کنید"
              value={profileData?.degree}
              defaultValue="U.DP"
            />
            <Input
              label=" رشته تحصیلی"
              register={{
                required: {
                  value: true,
                  message: "رشته تحصیلات را وارد کنید",
                },
              }}
              name="major"
              control={control}
              value={profileData?.major}
            />

            <DatePickerInput
              control={control}
              rules={{ required: false }}
              label="تاریخ تولد"
              name="birth_date"
              message="تاریخ تولد را وارد کنید"
              defaultValue={profileData?.birth_date}
            />
            <Input
              label="ایمیل "
              register={{
                required: {
                  value: true,
                  message: "ایمیل خود را وارد کنید",
                },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "ایمیل وارد شده اشتباه است!",
                },
              }}
              type="email"
              name="email"
              control={control}
              // disabled={true}
              value={userData?.email}
            />
            <Input
              label="محل سکونت"
              register={{
                required: false,
              }}
              name="province"
              control={control}
              value={profileData ? profileData.province : ""}
            />
            <Input
              label="شماره تماس "
              register={{
                required: {
                  value: true,
                  message: "شماره تماس را وارد کنید",
                },
                pattern: {
                  value: /^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message:
                    " فرمت درست(۱۲۱۲ ۱۲۳ ۰۹۱۳) شماره تماس وارد شده اشتباه است!",
                },
              }}
              name="phone_number"
              type="number"
              control={control}
              value={profileData?.phone_number}
            />
            <Textarea
              label="آدرس "
              register={{
                required: {
                  value: true,
                  message: "آدرس را وارد کنید",
                },
              }}
              name="address"
              control={control}
              value={profileData?.address}
              maxLength={500}
              showCount
            />
            <Textarea
              label=" درباره من "
              register={{
                required: {
                  value: true,
                  message: "توضیحات را وارد کنید",
                },
              }}
              name="description"
              control={control}
              value={profileData?.description}
              showCount
              maxLength={400}
            />
            <div className=" profile__social col-span-full">
              <Input
                label="github"
                register={{
                  required: {
                    value: true,
                    message: "آیدی گیت هاب الزامی است  ",
                  },
                }}
                name="github"
                control={control}
                prefix={<Github />}
                value={
                  profileData
                    ? profileData.github !== "undefined"
                      ? profileData.github
                      : ""
                    : ""
                }
                placeholder={"فقط یوزرنیم را وارد نمایید"}
              />
              <Input
                label="linkedin"
                register={{
                  required: false,
                }}
                name="linkedin"
                control={control}
                prefix={<Linkdin />}
                value={
                  profileData
                    ? profileData.linkedin !== "undefined"
                      ? profileData.linkedin
                      : ""
                    : ""
                }
                placeholder={"فقط یوزرنیم را وارد نمایید"}

                // value={profileData?.linkedin}
              />
              <Input
                label="instagram"
                register={{
                  required: false,
                }}
                name="instagram"
                control={control}
                prefix={<Instagram />}
                value={
                  profileData
                    ? profileData.instagram !== "undefined"
                      ? profileData.instagram
                      : ""
                    : ""
                }
                placeholder={"فقط یوزرنیم را وارد نمایید"}

                // value={profileData?.instagram}
              />
              <Input
                label="telegram"
                register={{
                  required: false,
                }}
                name="telegram"
                control={control}
                prefix={<Telegram />}
                value={
                  profileData
                    ? profileData.telegram !== "undefined"
                      ? profileData.telegram
                      : ""
                    : ""
                }
                placeholder={"فقط یوزرنیم را وارد نمایید"}

                // value={profileData?.telegram}
              />
            </div>
            <div className="col-span-full">
              <Upload
                label="رزومه"
                {...register("resume", {
                  required: profileData?.resume ? false : true,
                })}
                message="رزومه خود را آپلود کنید"
                error={errors["resume"]}
                accept=".pdf"
                id="cover_upload"
                name="resume"
                value={profileData?.resume}
              />
            </div>
          </div>
          <div className="center w-100 mt-40 profile__action">
            <Button
              disabled={PostProfile.loading}
              type="primary"
              htmlType="submit"
            >
              {PostProfile.loading ? (
                <DotLoader
                  color="#fff"
                  loading={PostProfile.loading}
                  css={override}
                  size={22}
                />
              ) : (
                "ثبت تغییرات"
              )}
            </Button>
          </div>
        </form>
      ) : null}
    </>
  );
};

export default ProfileForm;
