import React, { useState } from "react";
import AttributeItem from "./AttributeItem";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import PrerequisiteForm from "@Components/Layouts/CreateCourse/Form/PrerequisiteForm";
import { useCreateCourse } from "@App/Context/CreateCourseContext";

const PreRequesties = ({ courseData }) => {
  const [addForm, setaddForm] = useState(false);

  // const Properties = [
  // 	{
  // 		id: 1,
  // 		property: 'آموزش خوب و سریع',
  // 	},
  // 	{
  // 		id: 2,
  // 		property: ' اساتید مجرب  ',
  // 	},
  // ];
  const { createInfoData } = useCreateCourse();
  if (courseData?.properties?.length > 0) {
    createInfoData.stepTwo.coursePreReq = courseData.prerequisites;
  }
  const toggleForm = () => {
    setaddForm(!addForm);
  };

  const closeForm = () => {
    setaddForm(false);
  };
  return (
    <div data-tut="reactour__coursePREProperti">
      <p className="atrr__title mb-4">پیش نیاز های دوره:</p>
      {createInfoData.stepTwo.coursePreReq.map(({ pre_prop }, index) => (
        <AttributeItem
          key={index}
          closeForm={closeForm}
          item={pre_prop}
          index={index}
          field="coursePreReq"
          toggleForm={toggleForm}
        />
      ))}
      <div>
        {!addForm ? (
          <Button
            onClick={toggleForm}
            type="text"
            size="small"
            success
            datatut="reactour__courseaddNEWPREProperti"
          >
            <PlusIcon />
            <p>اضافه کردن پیش نیاز جدید </p>
          </Button>
        ) : (
          <PrerequisiteForm
            toggleForm={toggleForm}
            closeForm={closeForm}
            field={"coursePreReq"}
          />
        )}
      </div>
    </div>
  );
};

export default PreRequesties;
