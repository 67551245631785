import React, { useState } from 'react';

import { ReactComponent as Line } from '@Assets/Icons/Line 27.svg';
import ResumeDoneWrapper from '@Components/Layouts/resume/ResumeWrapper/ResumeDoneWrapper';
import useFetch from '@App/Context/useFetch';
import WorkExperienceForm from './WorkExperienceForm';
import Time from '@Components/Shared/Time/Time';

const WorkExperinceDone = ({
	company_name,
	profession_description,
	profession_end_date,
	profession_start_date,
	profession_title,
	caller,
	id,
	showForm,
	type,
}) => {
	const [edit, setEdit] = useState(false);
	const showEdit = () => {
		setEdit((before) => !before);
	};
	return (
		<>
			<ResumeDoneWrapper
				className='WorkExperinceDone'
				type='work'
				id={id}
				caller={caller}
				showEdit={showEdit}
			>
				<div className=' WorkExperinceDone__header  '>
					<p className='WorkExperinceDone__title'>{profession_title}</p>
				</div>
				<div className='WorkExperinceDone__middle flex'>
					<p className='line'>{company_name}</p>

					<p className='whitespace-nowrap'>
						<Time value={profession_start_date} /> تا
						{profession_end_date ?<Time value={profession_end_date} /> : <span>تاکنون&nbsp;  </span>}
					</p>
				</div>
				<p className='WorkExperinceDone__des'>{profession_description}</p>
			</ResumeDoneWrapper>

			{edit && (
				<WorkExperienceForm
					showEdit={showEdit}
					edit
					showForm={showForm}
					getWorks={caller}
					company_name={company_name}
					profession_description={profession_description}
					profession_end_date={profession_end_date}
					profession_start_date={profession_start_date}
					profession_title={profession_title}
					id={id}
				/>
			)}
		</>
	);
};

export default WorkExperinceDone;
