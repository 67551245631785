import React from 'react';
import comingSoon from "@Assets/Pic/comingSoon.jpg";

function ComingSoon(props) {
    return (
        <div style={{width: '100%', height: '100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img alt={'comingSoon'} src={comingSoon}/>
        </div>
    );
}

export default ComingSoon;