import React, { useMemo, useState } from "react";
import { Table, Tooltip, Menu, Skeleton, Empty } from "antd";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import Button from "@Components/Shared/Buttons/Button";
import Link from "@Components/Shared/Buttons/Link";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import useFetch from "@App/Context/useFetch";
import Price from "@Components/Shared/Price/Price";
import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

const { Item } = Menu;

const MyCourseTable = ({
  courseData,
  handleStatusData,
  showModalStatus,
  getTeacherCourses,
  getTeacherCoursesStatistics,
}) => {
  const [selectedItem, setSelectedItem] = useState();
  const history = useHistory();
  const deleteItem = useFetch({
    url: `CourseService/${selectedItem}`,
    method: "DELETE",
    trigger: false,
    caller: getTeacherCourses,
    argFunc: () => {
      getTeacherCoursesStatistics.reFetch();
    },
  });
  const [quizid, setQuizid] = useState();
  const [quizid1, setQuizid1] = useState();
  const [lan, setLan] = useState();
  const [lanid, setLanid] = useState();
  const makeCoursePublish = useFetch({
    url: `AdminService/makeCoursePublish`,
    method: "POST",
    trigger: false,
    data: { course_uuid: selectedItem },
    caller: getTeacherCourses,
    argFunc: () => {
      toast.success("دوره با موفقیت منتشر شد");
      getTeacherCoursesStatistics.reFetch();
    },
    argErrFunc: (err) => handleError(err),
  });
  const makeExtraFieldsPublish = useFetch({
    url: `AdminService/makeExtraFieldsPublish`,
    method: "POST",
    trigger: false,
    data: { course_uuid: selectedItem },
    argFunc: () => {
      toast.success("با موفقیت انجام شد");
    },
    argErrFunc: (err) => handleError(err),
  });

  const handleError = (err) => {
    if (err?.data === "course already published") {
      toast.error("این دوره قبلا منتشر شده است");
    }
    if (err?.data === "you didnt pass all steps") {
      toast.error("همه مراحل را تکمیل نکرده اید");
    }
    if (err?.data === "course is not published") {
      toast.error("این دوره هنوز منتشر نشده است");
    }
  };
  const getCoursesSeasons = useFetch({
    url: `CourseService/${quizid}/seasons_teacher`,
    method: "GET",
    // setter: setCourseSeasons,
    // pagination: pagination,
    argFunc: (res) => {
      setLan(res[0].language);
      setLanid(res[0].language_id);
      console.log("res?[0]", res[0]);
      history.push({
        pathname: `/coursequiz/${quizid1}`,
        state: {
          type: "course",
          languageId: res[0].language_id,
          languageName: res[0].language,
          quizId: quizid1,
        },
      });
    },
  });
  const handleCurseQuiz = (id, test) => {
    setQuizid(id);
    console.log("test", test);
    test?.status_set.map(
      (idd) =>
        idd.get_status_name_display === "ساخت آزمون دوره" &&
        idd.get_status_state_display === "انجام شده" &&
        setQuizid1(idd.id)
    );
    getCoursesSeasons.reFetch();
  };
  const columns = [
    {
      title: "",
      key: "index",
      render: (_, __, index) => <span className="table__num">{index + 1}</span>,
      width: "5%",
    },
    {
      title: "نام دوره",
      key: "title",
      ellipsis: {
        showTitle: false,
      },
      dataIndex: "title",
      align: "right",
      width: "18%",
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: "تعداد جلسات",
      dataIndex: "nums_of_seasons",
      key: "nums_of_seasons",
      width: "8%",
    },
    {
      title: "تعداد دانشجوها",
      dataIndex: "num_of_participants",
      key: "num_of_participants",
      width: "8%",
    },
    {
      title: "قیمت دوره",
      dataIndex: "price",
      key: "price",
      width: "8%",

      render: (value) => {
        return value === 0 ? (
          <span>رایگان</span>
        ) : (
          <Price value={value} suffix="تومان" />
        );
      },
    },
    {
      title: "",
      key: "published",
      dataIndex: "get_is_published_display",
      width: "8%",

      render: (text) => <span className="text-2xl">{text}</span>,
    },
    // {
    // 	title: '',
    // 	key: 'post',
    // 	// dataIndex: "uuid",
    // 	// width: (props) => (props.get_is_published_display !== "منتشر شده" ? "20%" : "0"),
    // 	width: '10%',

    // 	render: (props) => (
    // 		<Menu
    // 			className='course_table__actions'
    // 			mode='horizontal'
    // 			triggerSubMenuAction='click'
    // 		>
    // 			<Item key='info'></Item>
    // 		</Menu>
    // 	),
    // },
    // {
    // 	title: '',
    // 	key: 'actions',
    // 	dataIndex: 'statusbar',
    // 	width: '12%',

    // 	render: (statusbar) => (
    // 		<Menu
    // 			className='course_table__actions'
    // 			mode='horizontal'
    // 			triggerSubMenuAction='click'
    // 		>
    // 			<Item key='status'></Item>
    // 		</Menu>
    // 	),
    // },

    {
      title: "",
      key: "actions",
      // dataIndex: ['uuid', 'get_is_published_display'],
      width: "30%",
      className: "course_table--actions",
      render: (props) => (
        <div className="flex items-center justify-between w-full">
          <Button
            onClick={() => {
              setSelectedItem(props.uuid);
              props.get_is_published_display !== "منتشر شده"
                ? makeCoursePublish.reFetch()
                : makeExtraFieldsPublish.reFetch();
            }}
            type="text"
          >
            انتشار{" "}
          </Button>
          <Button
            type="text"
            onClick={() => {
              showModalStatus();
              handleStatusData(props.statusbar);
              
            }}
          >
            وضعیت
          </Button>
          <Button
            type="text"
            style={
              props.nums_of_seasons !== 0
                ? { color: "#000" }
                : { color: "gray" }
            }
            disabled={props.nums_of_seasons === 0}
            onClick={() => handleCurseQuiz(props.uuid, props.statusbar)}
          >
            آزمون دوره
          </Button>
          {/* <Link
            to={{
              pathname: `/coursequiz`,
              state: {
                type: "course",
                // languageId: languageId,
                // languageName: languageName,
                
              },
            }}
            type="text"
            style={{ color: "#000" }}
          >
            آزمون دوره
          </Link> */}

          {/* <Link to={`/my-course/${props.uuid}`} type="text"> */}
          <Link
            to={{
              pathname: `/my-course/${props.uuid}`,
              state: { type: props.get_is_published_display },
            }}
            type="text"
          >
            جلسات
          </Link>

          {props.get_is_published_display !== "منتشر شده" ? (
            <IconBtn
              onClick={() => {
                setSelectedItem(props.uuid);
                deleteItem.reFetch();
              }}
              icon={<Trash />}
              danger
              title="حذف"
            />
          ) : null}

          <IconBtn
            icon={<Pencil />}
            title="ویرایش"
            onClick={async () =>
              history.push({
                pathname: "/create-course",
                state: { courseUUID: props.uuid },
              })
            }
          />
        </div>
      ),
    },
  ];

  // const renderLoading = () =>
  // 	_.range(4).map((__, key) => <Skeleton className='table-box-loading' key={key} active paragraph />);

  return (
    <div className="course_table">
      <Table
        className="table-box"
        rowKey="uuid"
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          pageSize: 6,
        }}
        columns={columns}
        dataSource={courseData}
        loading={getTeacherCourses.loading}
        locale={{
          emptyText: <Empty description="هنوز دوره ای نساختی!🙂" />,
        }}
      />
    </div>
  );
};

export default MyCourseTable;
