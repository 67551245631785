import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './Context/authContext';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<ToastContainer
					position='top-center'
					autoClose={4000}
					newestOnTop
					closeOnClick
					rtl
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<App />
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
