import React, { useState } from 'react';
import MyCourseTable from './MyCourseTable';
import MycourseFilter from './MyCourseFilter';
import CourseStatusModal from './CourseStatusModal';
import Button from '@Components/Shared/Buttons/Button';
import { useHistory } from 'react-router-dom';
import useFetch from '@App/Context/useFetch';
import Modal from '@Components/Shared/Modal/Modal';

const MyCourseContainer = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const toggleShowModal = () => {
		setIsModalVisible((before) => !before);
	};

	const history = useHistory();
	const [userCourses, setUserCourses] = useState({ results: [], courses: [] });
	const [filterSearch, setFilterSearch] = useState('all');
	const [statistics, setStatistics] = useState({
		all: 0,
		draft: 0,
		published: 0,
	});

	const [statusData, setStatusData] = useState(null);

	const handleStatusData = (data) => {
		setStatusData(data);
	};

	const getTeacherCourses = useFetch({
		url: `TeacherService/courses`,
		method: 'GET',
		setter: setUserCourses,
		params: { state: filterSearch },
	});

	const getTeacherCoursesStatistics = useFetch({
		url: `TeacherService/courses_statistics`,
		method: 'GET',
		setter: setStatistics,
	});

	const updateFilterSearch = (data) => {
		setFilterSearch(data);
		getTeacherCourses.reFetch();
	};

	return (
		<>
			<div className='flex MyCourseContainer__header '>
				<MycourseFilter
					updateFilterSearch={updateFilterSearch}
					statistics={statistics}
				/>
				<Button
					type='default'
					onClick={() => history.push('/create-course')}
				>
					ایجاد دوره
				</Button>
			</div>
			<MyCourseTable
				showModalStatus={toggleShowModal}
				handleStatusData={handleStatusData}
				courseData={userCourses.courses}
				getTeacherCoursesStatistics={getTeacherCoursesStatistics}
				getTeacherCourses={getTeacherCourses}
			/>
			<Modal visible={isModalVisible} onCancel={toggleShowModal}>
				<CourseStatusModal statusData={statusData} />
			</Modal>
		</>
	);
};

export default MyCourseContainer;
