import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Textarea from "@Components/Shared/Inputs/Textarea";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import Select from "@Components/Shared/Inputs/Select";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import useFetch from "../../../../Context/useFetch";
import Modal from "@Components/Shared/Modal/Modal";
import { Skeleton } from "antd";

const TagList = [
  { label: "موبایل", value: "موبایل" },
  { label: "وب ", value: "وب " },
  { label: "ویندوز ", value: "ویندوز " },
];

const SupplementaryForm = ({
  nextStep,
  prevStep,
  courseData,
  getCourse,
  edit,
}) => {
  const { handleSubmit, control } = useForm();
  console.log("courseData ~ SupplementaryForm: ", courseData);
  const [isDisplayModal, setDisplayModal] = useState(false);

  const [categories, setCategories] = useState([]);

  const getCategories = useFetch({
    url: `HelpsService/categories`,
    // setter:setCategories,
    argFunc: (res) => {
      let category = [];

      res.results.map((item) => {
        // category.push({label: item.name, value: item.id.toString()});
        category.push({ label: item.name, value: item.name });
      });

      setCategories(category);
    },
  });
  const onSubmit = (data) => {
    updateCourseDetail("stepThree", data);
    apiCreateCourse(courseData?.uuid, "stepThree", data, getCourse);

    makeReturnButtonDisable();
    if (isDisplayModal) {
      setDisplayModal((before) => !before);
      nextStep();
    }
  };
  const handleEdit = () => {
    // updateCourseDetail("stepThree", data);
    // apiCreateCourse(courseData?.uuid, "stepThree", data, getCourse);
    // makeReturnButtonDisable();
  };
  const toggleDisplayModal = () => {
    setDisplayModal((before) => !before);
  };
  const {
    updateCourseDetail,
    createInfoData,
    apiCreateCourse,
    courseId,
    isCourseComplete,
  } = useCreateCourse();

  const [isReturnButtonDisable, setReturnButtonDisable] = useState(false);
  const makeReturnButtonDisable = () => {
    setReturnButtonDisable(true);
  };
  useEffect(() => {
    if (isCourseComplete) {
      nextStep();
    }
  }, [isCourseComplete]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="create__course--form">
        <div className="create__course--wrapper">
          <div className="grid grid-cols-2 gap-12 my-8 grid-temp-col-sm ">
            {getCategories.response?.data ? (
              <Select
                label="دسته بندی دوره"
                register={{
                  required: true,
                }}
                message=" دسته بندی  دوره  را وارد کنید"
                name="courseCategory"
                control={control}
                options={categories}
                mode="multiple"
                value={
                  courseData?.categories
                    ? courseData.categories
                    : createInfoData.stepThree.courseCategory
                }
                classes="reactour__Supplementary"
              />
            ) : (
              <Skeleton.Button block active size="large" />

            )}

            <Select
              label=" تگ های دوره "
              register={{
                required: false,
              }}
              message=" تگ دوره  را وارد کنید"
              name="courseTags"
              control={control}
              options={TagList}
              mode="tags"
              value={
                courseData?.tags
                  ? courseData.tags
                  : createInfoData.stepThree.courseTags
              }
              classes="reactour__TagSupplementary"
            />
          </div>
          <Textarea
            label=" توضیحات دوره "
            register={{
              required: {
                value: true,
                message: "توضیحات دوره را وارد کنید",
              },
            }}
            name="courseTeacherInfo"
            control={control}
            value={
              courseData?.intro
                ? courseData.intro
                : createInfoData.stepThree.courseTeacherInfo
            }
            datatut="reactour__Thirdtextarea"
          />
        </div>
        <div className="create__course--btn">
          <Button
            type="primary"
            onClick={!isReturnButtonDisable ? prevStep : undefined}
            // disabled={isReturnButtonDisable}
            datatut="reactour__ThirdsaveBefore"
          >
            مرحله ی قبل
            <RightArrow />
          </Button>
          {!isReturnButtonDisable ? (
            <Button htmlType="submit" datatut="reactour__Thirdsave">
              ثبت
            </Button>
          ) : null}
          <Modal
            visible={isDisplayModal}
            onCancel={toggleDisplayModal}
            title={"ذخیره تغییرات"}
            classes="min-w-max pb-0"
            maskClosable={false}
          >
            <p className=" mb-12">
              تغییرات ذخیره نشده است، برای ذخیره تغییرات روی دکمه ثبت کلیک
              کنید،در غیر اینصورت بر روی مرحله بعد کلیک نمایید
            </p>
            <div className=" flex items-center justify-between">
              <Button
                htmlType="submit"
                onClick={handleSubmit(onSubmit)}
                datatut="reactour__Thirdsave"
              >
                ثبت
              </Button>
              <Button
                type="primary"
                disabled={courseId === undefined && !courseData}
                onClick={
                  courseId !== undefined || courseData ? nextStep : undefined
                }
                datatut="reactour__ThirdsaveNext"
              >
                <LeftArrow />
                مرحله ی بعد
              </Button>
            </div>
          </Modal>
          <Button
            type="primary"
            disabled={
              // createInfoData.stepThree.courseCategory.length <= 0 ||
              // createInfoData.stepThree.courseTags.length <= 0 ||
              // createInfoData.stepThree.courseTeacherInfo == "" ||
              courseId === undefined && !courseData
            }
            onClick={
              edit
                ? toggleDisplayModal
                : courseId !== undefined || courseData
                ? nextStep
                : undefined
            }
            datatut="reactour__ThirdsaveNext"
          >
            <LeftArrow />
            مرحله ی بعد
          </Button>
        </div>
      </form>
    </>
  );
};

export default SupplementaryForm;
