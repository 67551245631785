import React, { useEffect, useState } from "react";
import Input from "@Components/Shared/Inputs/Input";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import Select from "@Components/Shared/Inputs/Select";
import Textarea from "@Components/Shared/Inputs/Textarea";
import { ReactComponent as Arrow } from "@Assets/Icons/to-arrow.svg";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { ReactComponent as Check } from "@Assets/Icons/CheckCircle.svg";
import { Select as SelectBase } from "antd";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "@Components/Shared/Buttons/Button";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ffa44a;
`;

const TestCaseInput = ({
  item,
  mode,
  control,
  index,
  name,
  remove,
  errors,
  getValues,
  setValue,
  resetField,
  reset,
}) => {
  const [defvalue, setDefvalue] = useState(false);
  const [defvalue1, setDefvalue1] = useState(false);
  const [val, setVal] = useState("");
  const [val1, setVal1] = useState("");
  const [loader, setLoader] = useState(false);
  const [test, setTest] = useState(index);

  const {
    fields: fieldsTestCase,
    append: appendTestCase,
    remove: removeTestCase,
    update: update_arg,
  } = useFieldArray({ name: "testCase", control });
  // const update_arg =(index , obj)=>{
  console.log("item.input", item.input);
  useEffect(() => {
    if (item.input !== "") {
      setDefvalue(true);
    } else {
      setDefvalue(false);
    }
  }, [item]);
  useEffect(() => {
    if (mode === "inputempty") {
      setDefvalue1(false);
    } else {
      setDefvalue1(true);
    }
  }, [mode]);
  const handleClick = () => {
    setLoader(true);
    setVal1(val);
    setValue(`${name}.${index}.input`, [getValues(name)[index].input]);
    setLoader(false);
    // resetField(`${name}.${index}.input`);
    // reset({ firstname: "" });
  };
  return (
    <div
      className="textCaseInput__row mb-8"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.2)",
        padding: "1rem",
        borderRadius: "1.5rem",
      }}
    >
      <div className=" flex items-center justify-between">
        <IconBtn
          title="حذف"
          icon={<Trash />}
          danger
          onClick={() => remove(index)}
        />
      </div>

      {item.input !== undefined && item.input.length !== 0 && (
        <div className=" flex items-center justify-start">
          {Array.isArray(item.input) && item.input[0]?.length !== 0 && (
            <textarea
              className="ExampleDetail__sampledata text-center "
              name="text"
              value={item.input}
              readOnly
            />
          )}
        </div>
      )}

      <Textarea
        label="ورودی"
        register={{
          required: {
            value: false,
            message: "ورودی را وارد کنید",
          },
          pattern: "^[\u0600-\u06FF]+$",
        }}
        name={`${name}.${index}.input`}
        control={control}
        defaultValue={mode === "edit" && item.input}
        errors={errors}
      />
      <Button
        type="primary"
        disabled={loader}
        onClick={handleClick}
        datatut="reactour__quiztest"
        classes="mt-3"
      >
        {loader ? (
          <DotLoader color="#fff" loading={loader} css={override} size={22} />
        ) : (
          "send"
        )}
      </Button>
      <div className="arrow mb-9">{/* <Arrow /> */}</div>
      <div className=" w-full">
        <Textarea
          label="خروجی"
          register={{
            required: {
              value: true,
              message: "خروجی را وارد کنید",
            },
            pattern: "^[\u0600-\u06FF]+$",
          }}
          name={`${name}.${index}.output`}
          control={control}
          defaultValue={mode === "edit" && item.output}
          errors={errors}
        />
      </div>
      {/* <p>{errors.pattern && "ورودی فارسی است"}</p> */}
      <div className=" flex items-center justify-between"></div>
    </div>
  );
};

export default TestCaseInput;
