import React from 'react';
import ProfileForm from '@Components/Layouts/profile/ProfileForm';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const Profile = () => {
	return (
		<>
			<BreadCrumb />

			<ProfileForm />
		</>
	);
};

export default Profile;
