import { Button, ConfigProvider } from "antd";
import React from "react";
import Router from "./Router/Router";
import "./App.less";
import "./Styles/main.scss";
import faLocal from "antd/lib/locale/fa_IR";
import { UserDataProvider } from "@App/Context/userContext";

const App = () => {
  return (
    <>
      <ConfigProvider direction="rtl" locale={faLocal}>
        <UserDataProvider>
          <Router />
        </UserDataProvider>
      </ConfigProvider>
    </>
  );
};
export default App;
