import { Avatar, Badge, Dropdown, Menu } from 'antd';
import React from 'react';
// import { FaChevronDown } from "react-icons/fa";
import ProfileMenu from './ProfileMenu';

// image
// import profileImage from "@Images/pic/p.png";
import { useUserData } from '@App/Context/userContext';
import { ReactComponent as ArrowDownIcon } from '@Assets/Icons/arrow-down.svg';

const Propfile = () => {
	const { userData } = useUserData();
	const Icon = (
		<span>
			<ArrowDownIcon />
		</span>
	);
	return (
		<Dropdown overlay={<ProfileMenu />}>
			<div className='appbar__profile'>
				<Badge count={Icon}>
					<Avatar src={userData.cover} alt='name' shape='square'>
						name
					</Avatar>
				</Badge>
			</div>
		</Dropdown>
	);
};
export default Propfile;
