import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

import Password from '@Components/Shared/Inputs/Password';
import Button from '@Components/Shared/Buttons/Button';
import useFetch from '../../../Context/useFetch';
import {toast} from 'react-toastify';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const SettingsContainer = () => {
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required('کلمه عبور فعلی را وارد کنید'),
        new_password1: Yup.string()
            .required('کلمه عبور جدید را وارد کنید')
            .min(8, 'پسورد کوتاه است.حداقل هشت رقم وارد کنید'),
        // .matches(
        // 	/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        // 	'پسوورد باید ترکیبی از حروف و اعداد باشد'
        // ),
        new_password2: Yup.string()
            .required('کلمه عبور جدید را دوباره وارد کنید')
            .oneOf([Yup.ref('new_password1'), null], 'عدم تطابق پسورهای جدید'),
    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    const {handleSubmit, control, reset} = useForm(formOptions);
    const [postPassData, setPostPassData] = useState(null);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append('old_password', data.old_password);
        formData.append('new_password1', data.new_password1);
        formData.append('new_password2', data.new_password2);
        setPostPassData(formData);
        postPassword.reFetch();
    };

    const postPassword = useFetch({
        url: 'auth/password/change',
        method: 'POST',
        trigger: false,
        noHeader: false,
        data: postPassData,
        argFunc: (res) => {
            toast.success('پسورد با موفقیت تغییر یافت');
            reset();
        },
        argErrFunc: (err) => {
            if (err.detail === 'you\'re new password must differ from you\'re old password') {
                toast.error('پسورد قبلی و جدیدت یکیه');
            }
            if (
                err.old_password?.includes(
                    'Your old password was entered incorrectly. Please enter it again.'
                )
            ) {
                toast.error('پسورد فعلی نادرسته');
            } else if (
                err.new_password2?.includes(
                    'This password is too short. It must contain at least 8 characters.'
                )
            ) {
                toast.error('پسورد کوتاه است.حداقل هشت رقم وارد کنید');
            } else if (
                err.new_password2?.includes('This password is too common.')
            ) {
                toast.error('پسوورد ساده است');
            } else if (
                err.new_password2?.includes('This password is entirely numeric.')
            ) {
                toast.error('پسوورد باید ترکیبی از حروف و اعداد باشد ');
            } else if (err.new_password1) {
                console.log('new_password1', err.new_password1);
            } else if (
                err.new_password2?.includes('The two password fields didn’t match.')
            ) {
                toast.error('عدم تطابق پسورهای جدید');
            } else if (err.new_password2) {
                console.log('new_password2', err.new_password2);
            } else {
                console.log('other_password', err);
            }
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='setting__password'>
            <Password
                label='کلمه عبور فعلی'
                register={{
                    required: {
                        value: true,
                        message: 'کلمه عبور فعلی را وارد کنید',
                    },
                }}
                name='old_password'
                control={control}
            />
            <Password
                label='کلمه عبور جدید'
                register={{
                    required: {
                        value: true,
                        message: 'کلمه عبور جدید را وارد کنید',
                    },
                }}
                name='new_password1'
                control={control}
            />
            <Password
                label='تکرار کلمه عبور جدید'
                register={{
                    required: {
                        value: true,
                        message: 'کلمه عبور جدید را دوباره وارد کنید',
                    },
                }}
                name='new_password2'
                control={control}
            />

            <Button type='primary' htmlType='submit'>
                ثبت تغییرات
            </Button>
        </form>
    );
};

export default SettingsContainer;
