import React, { useState } from 'react';
import { ReactComponent as Trash } from '@Assets/Icons/Trash.svg';
import { ReactComponent as Pencil } from '@Assets/Icons/Pencil.svg';
import IconBtn from '@Components/Shared/Buttons/IconBtn';
import useFetch from '@App/Context/useFetch';

const ResumeDoneWrapper = ({
	children,
	className,
	type,
	id,
	caller,
	showEdit,
}) => {
	const deleteItem = useFetch({
		url:
			type == 'skill'
				? `TeacherSkillService/${id}`
				: type == 'work'
				? `TeacherProfessionService/${id}`
				: `TeacherGradeService/${id}`,
		method: 'DELETE',
		trigger: false,
		caller: caller,
	});

	return (
		<div className={`resume__bg ResumeDoneWrapper relative ${className}`}>
			<div className='flex IconBox-ab'>
				<div className='px-7' onClick={showEdit}>
					<IconBtn title='ویرایش' icon={<Pencil />} classes='text-black' />
				</div>
				<div onClick={deleteItem.reFetch}>
					<IconBtn title='حذف' icon={<Trash />} danger />
				</div>
			</div>
			<>{children}</>
		</div>
	);
};

export default ResumeDoneWrapper;
