import React, { useState } from "react";
import Link from "@Components/Shared/Buttons/Link";
const FormResultAlarmWrapper = ({
  title,
  des,
  pic,
  outlineBtnName,
  PrimaryBtnName,
  outlineBtnNameLink,
  PrimaryBtnNameLink,
  finishedCourseClass,
}) => {
  return (
    <div
      className={`FormResultAlarmWrapper__background ${finishedCourseClass}`}
    >
      <div className="FormResultAlarmWrapper center">
        <h3 className="FormResultAlarmWrapper_h3">{title}</h3>
        <p className="FormResultAlarmWrapper_des">{des}</p>
        <img className="mt-12 mb-14" src={pic} alt="vector" />
        <div className="center">
          <Link to={outlineBtnNameLink}>{outlineBtnName}</Link>
          <div className="mr-8">
            <Link to={PrimaryBtnNameLink} type="primary">
              {PrimaryBtnName}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormResultAlarmWrapper;
