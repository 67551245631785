import React from "react";
import { useForm } from "react-hook-form";
import Input from "@Components/Shared/Inputs/Input";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
const PropertyForm = ({
  toggleForm,
  closeForm,
  item,
  index,
  field,
  isEdit = false,
}) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      prop: isEdit ? item : "",
    },
  });

  const { addCourseStepTwoItem, editCourseStepTwoItem, createInfoData } =
    useCreateCourse();
  const onSubmit = (data) => {
    if (isEdit) {
      editCourseStepTwoItem("stepTwo", field, index, data.prop);
      closeForm();
    } else {
      addCourseStepTwoItem("stepTwo", field, { prop: data.prop });
      closeForm();
    }
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex PropertyForm mt-8 ">
        <Input
          label="نام ویژگی"
          register={{
            required: {
              value: true,
              message: "نام ویژگی را وارد کنید",
            },
          }}
          name="prop"
          control={control}
          autoFocus
        />
        {!isEdit && (
          <IconBtn onClick={toggleForm} danger title="بستن">
            <Trash />
          </IconBtn>
        )}

        <IconBtn
          htmlType="submit"
          success
          title="ثبت"
          icon={<Submit />}
          datatut="reactour__courseSAVENEWProperti"
        />
      </div>
    </form>
  );
};

export default PropertyForm;
