import React, { useState } from "react";

import logo from "@Assets/Logo/logo.svg";
import { ReactComponent as ShoppingCartIcon } from "@Assets/Icons/shopping-cart.svg";
import { ReactComponent as LogoTextIcon } from "@Assets/Logo/codeamooz-text.svg";
import profile from "@Assets/Pic/profile.png";
import IconBtn from "../Buttons/IconBtn";
import classNames from "classnames";
import { NavLink, Link, useHistory } from "react-router-dom";
import UseScrollAppbar from "./UseScrollAppbar";
import { ReactComponent as HomeIcon } from "@Assets/Icons/home.svg";
import { ReactComponent as UserIcon } from "@Assets/Icons/user.svg";
import { ReactComponent as ResumeIcon } from "@Assets/Icons/resume.svg";
import { ReactComponent as CourseIcon } from "@Assets/Icons/course.svg";
import { ReactComponent as WalletIcon } from "@Assets/Icons/wallet.svg";
import { ReactComponent as MessageIcon } from "@Assets/Icons/message.svg";
import { ReactComponent as SettingIcon } from "@Assets/Icons/setting.svg";
import SidebarMenuItem from "./SidebarMenuItem";
import { useUserData } from "../../../Context/userContext";

import Propfile from "./ProfileMenu/Propfile";
import useMediaQuery from "@App/Hooks/useMediaQuery";

// import { useNavigate } from "react-router-dom";

const menuItem = [
  {
    url: "",
    text: "صفحه ی اصلی",
    id: 1,
  },

  {
    url: "",
    text: "ارتباط با ما",
    id: 4,
  },
  {
    url: "",
    text: " سوالات متدوال",
    id: 5,
  },
  {
    url: "",
    text: "همکاری و اساتید",
    id: 6,
  },
];
const sidebarmenuItem = [
  {
    text: "پیشخوان",
    icon: <HomeIcon />,
    url: "/",
  },
  {
    text: "پروفایل",
    icon: <UserIcon />,
    url: "/profile",
  },
  {
    text: "رزومه",
    icon: <ResumeIcon />,
    url: "/resume",
  },
  {
    text: "دوره های من",
    icon: <CourseIcon />,
    url: "/my-course",
  },
  {
    text: "کیف پول",
    icon: <WalletIcon />,
    url: "/wallet",
  },
  {
    text: "پیام ها",
    icon: <MessageIcon />,
    url: "/messages",
  },
  {
    text: "تنظیمات",
    icon: <SettingIcon />,
    url: "/setting",
  },
];

const Appbar = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const { sticky } = UseScrollAppbar();

  const isTablet = useMediaQuery("(max-width: 992px)");
  const { userData } = useUserData();

  const handleToggleMenu = () => {
    if (isTablet) {
      setOpenMenu((prev) => !prev);
      isOpenMenu
        ? document.body.classList.remove("scrolling-effect")
        : document.body.classList.add("scrolling-effect");
    }
  };

  const renderSideBarItem = () => {
    return sidebarmenuItem.map((item) => (
      <SidebarMenuItem
        key={item.url}
        {...item}
        user_is_teacher={userData.user_is_teacher}
      />
    ));
  };

  return (
    <div
      className={classNames("appbar__dahsboard", {
        sticky: sticky,
      })}
    >
      <div className="d-flex-space Menu-wrapper">
        <div className="menu_logo d-flex-align ">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          {/* <img src={Codeamoozlogo} alt='codeamooz' /> */}
          <div className="logo-text">
            <LogoTextIcon />
          </div>
        </div>
        <div className="Menu__nav--wrapper">
          <IconBtn
            classes={classNames("Menu__nav--btn", {
              open: isOpenMenu,
            })}
            icon={
              <>
                <span></span>
                <span></span>
                <span></span>
              </>
            }
            onClick={handleToggleMenu}
          />
          {isTablet && (
            <div
              onClick={handleToggleMenu}
              className={classNames("Menu__nav--backdrop", {
                active: isOpenMenu,
              })}
            ></div>
          )}
          <nav
            className={classNames("Menu__nav d-flex-space", {
              active: isOpenMenu,
            })}
          >
            {isTablet && (
              <ul className="Menu__ul Menu__ul--sidebar d-flex-space list mb-8">
                {renderSideBarItem()}
              </ul>
            )}
            <ul className="Menu__ul d-flex-space list mr-16">
              {menuItem.map((item) => (
                <li key={item.id} className="Menu__li">
                  <NavLink to={item.url}>{item.text}</NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="Menu_actions">
          <div className="d-flex-align Menu_actions--profile">
            <div className={classNames("profile__image")}>
              <Propfile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
