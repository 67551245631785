import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb as BreadcrumbBase } from 'antd';
import { ReactComponent as SeparatorIcon } from '@Assets/Icons/SeparatorIcon.svg';
const { Item } = BreadcrumbBase;

const BreaCrumb = () => {
	const routes = [
		{ path: '/', breadcrumb: 'پیشخوان' },
		{ path: '/profile', breadcrumb: 'پروفایل' },
		{ path: '/resume', breadcrumb: 'رزومه' },
		{ path: '/my-course', breadcrumb: 'دوره های من' },
		{ path: '/wallet', breadcrumb: 'کیف پول' },
		{ path: '/setting', breadcrumb: 'تنظیمات' },
		{ path: '/create-course', breadcrumb: 'ایجاد دوره' },
		{ path: '/my-course/:courseId', breadcrumb: 'جلسات دوره ' },
		{
			path: '/my-course/createseasons/createcontent',
			breadcrumb: ' محتوای مبحث ',
		},
		{
			path: '/my-course/:courseId/:contentId',
			breadcrumb: 'ساخت مبحث',
		},
		{
			path: '/my-course/createseasons/add-quize',
			breadcrumb: ' افزودن آزمون ',
		},
	];
	const breadcrumbs = useBreadcrumbs(routes);

	return (
		<div className='breadcrumbs mt-4'>
			<BreadcrumbBase className='breadcrumb' separator={<SeparatorIcon />}>
				{breadcrumbs.map(({ match, breadcrumb }) => (
					<Item key={match.url}>
						<NavLink to={match.url}>{breadcrumb}</NavLink>
					</Item>
				))}
			</BreadcrumbBase>
		</div>
	);
};

export default BreaCrumb;
