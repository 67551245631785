import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '@Components/Shared/Inputs/Input';
import ResumeFormWrapper from '@Components/Layouts/resume/ResumeWrapper/ResumeFormWrapper';
import Rate from '@Components/Shared/Rate/Rate';
import useFetch from '@App/Context/useFetch';

const SkillsForm = ({ getSkill, showForm, edit, id, skill_rate,showEdit, skill_name }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		setValue,
	} = useForm({
		defaultValues: {
			skill_name: edit ? skill_name : '',
		},
	});

	const [postData, setPostData] = useState();
	const createItem = useFetch({
		url: `TeacherSkillService`,
		data: postData,
		method: 'POST',
		trigger: false,
		caller: getSkill,
		argFunc: () => showForm(),
	});

	const patchItem = useFetch({
		url: `TeacherSkillService/${id}`,
		data: postData,
		method: 'PATCH',
		trigger: false,
		caller: getSkill,
		argFunc: () => showEdit(),
	});
	const [rate, setRate] = useState(edit ? skill_rate : 2.5					
);
	const onSubmit = (data) => {
		setPostData({
			skill_name: data.skill_name,
			skill_rate: rate,
		});
		if (edit) patchItem.reFetch();
		else createItem.reFetch();
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ResumeFormWrapper
				className='WorkExperienceForm'
				showForm={showForm}
				showEdit={showEdit}
				edit={edit}
			>
				<div className='SkillsForm__row  d-flex-align-end'>
					<Input
						label='مهارت شما'
						register={{
							required: {
								value: true,
								message: 'مهارت را وارد کنید',
							},
						}}
						name='skill_name'
						control={control}
					/>
					<Rate
						value={rate}
						onChange={(e) => setRate(e)}
					/>
				</div>
			</ResumeFormWrapper>
		</form>
	);
};

export default SkillsForm;
