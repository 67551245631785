import React, {useMemo, useState} from "react";
import {Table, Typography} from "antd";

const IncomeListTable = ({courseData}) => {
  

    let newCourseData = courseData.map((course, index) => ({...course, index: ++index}))
    const columns = [
        {
            title: "نام دوره",
            key: "title",
            render: (text, index) => (
                <div className="d-flex pr-8">
                    <Typography.Text ellipsis={true}>{text.title}</Typography.Text>
                </div>
            ),
        },
        {
            title: (
                <Typography.Text
                    ellipsis={true}
                    // style={{width: "100%", textAlign: "center"}}
                >
                    تعداد شرکت کننده ها
                </Typography.Text>
            ),
            dataIndex: "num_of_participants",
            key: "num_of_participants",
            responsive: ["md"],
        },
        {
            title: (
                <Typography.Text
                    ellipsis={true}
                    // style={{width: "100%", textAlign: "center"}}
                >
                    قیمت دوره
                </Typography.Text>
            ),
            dataIndex: "price",
            key: "price",
        },
        {
            title: (
                <Typography.Text
                    ellipsis={true}
                    // style={{width: "100%", textAlign: "center"}}
                >
                    وضعیت دوره
                </Typography.Text>
            ),
            key: "status",
            render: (text) =>
                text.get_is_published_display === 'منتشر شده' ? <p className="success"> منتشر شده </p> :
                    <p> پیش نویس </p>,
            responsive: ["md"],
        },
        {
            title: (
                <Typography.Text
                    ellipsis={true}
                    // style={{width: "100%", textAlign: "center"}}
                >
                    درآمد شما
                </Typography.Text>
            ),
            dataIndex: "gain_for_this_course",
            key: "gain_for_this_course",
        },
    ];

    return (
        <div className="course_table Income_table">
            <Table
                rowKey='uuid'
                pagination={{
					position: ['bottomCenter'],
					hideOnSinglePage: true,
					showSizeChanger: false,
				}}
                columns={columns}
                dataSource={newCourseData}/>
        </div>
    );
};

export default IncomeListTable;
