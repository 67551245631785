import React, {useState} from "react";

import {ReactComponent as Line} from "@Assets/Icons/Line 27.svg";
import ResumeDoneWrapper from "@Components/Layouts/resume/ResumeWrapper/ResumeDoneWrapper";
import useFetch from "@App/Context/useFetch";
import EducationExperienceForm from "./EducationExperienceForm";
import moment from "jalali-moment";

const EducationExperinceDone = ({
                                    institute_name,
                                    grade,
                                    catch_start_date,
                                    catch_end_date,

                                    major,
                                    id,
                                    caller,
                                    type,
                                    showForm,
                                }) => {
    const [edit, setEdit] = useState(false);
    const showEdit = () => {
        setEdit((before) => !before);
    };

    const optionList = [
        {value: 'DP', label: 'فوق دیپلم'},
        {value: 'U.DP', label: 'دیپلم'},
        {value: 'B.S', label: 'کارشناسی'},
        {value: 'M.S', label: 'کارشناسی ارشد'},
        {value: 'Ph.D', label: 'دکترا'},
    ];
    return (
        <>
            <ResumeDoneWrapper
                className="WorkExperinceDone"
                id={id}
                caller={caller}
                showEdit={showEdit}
                type="education"
            >
                <div className=" WorkExperinceDone__header  ">
                    <p className="WorkExperinceDone__title">{institute_name}</p>
                </div>
                <div className="WorkExperinceDone__middle flex">
                    <p className='line'>{optionList.find(element => element.value === grade).label}</p>
                    <p className='line'>{major}</p>
                    <p>
                        <span>
                            {" "}
                            {moment(catch_start_date, "YYYY-MM-DD")
                                .locale("fa")
                                .format("dddd D MMMM YYYY")}
                        </span> تا
                        <span>
                            {" "}
                            {moment(catch_end_date, "YYYY-MM-DD")
                                .locale("fa")
                                .format("dddd D MMMM YYYY")}
                        </span>
                    </p>
                </div>
            </ResumeDoneWrapper>
            {edit ? (
                <EducationExperienceForm
                    showEdit={showEdit}
                    edit={true}
                    showForm={showForm}
                    getEducations={caller}
                    institute_name={institute_name}
                    grade={grade}
                    catch_end_date={catch_end_date}
                    catch_start_date={catch_start_date}
                    major={major}
                    id={id}
                />
            ) : undefined}
        </>
    );
};

export default EducationExperinceDone;
