import React, { createContext, useState } from "react";

const PhazeContext = createContext();

const PhazeProvider = (props) => {
  //   var decoded = jwt_decode(token);
  const [phaze, setPhaze] = useState(1);

  return (
    <PhazeContext.Provider value={{ phaze, setPhaze }} {...props}>
      {props.children}
    </PhazeContext.Provider>
  );
};

const usePhaze = () => React.useContext(PhazeContext);

export { PhazeProvider, usePhaze };
