import { Statistic } from 'antd';
import React from 'react';
import { ReactComponent as CoinIcon } from '@Assets/Icons/coin.svg';
import { ReactComponent as CoinsIcon } from '@Assets/Icons/coins.svg';
import { ReactComponent as StudentIcon } from '@Assets/Icons/Student.svg';
import { ReactComponent as LaptopIcon } from '@Assets/Icons/Laptop.svg';
// import { DollarCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DollarIcon } from '@Assets/Icons/dollar.svg';
import Price from '@Components/Shared/Price/Price';
import Link from '@Components/Shared/Buttons/Link';
import { useHistory } from 'react-router';

const Incomes = ({
	month_earnings,
	year_earnings,
	total_earnings,
	student_numbers,
	course_numbers,
	user_is_teacher,
}) => {
	const history = useHistory();
	return (
		<section className='dashboard__income--box dashboard__box'>
			<div className='dashboard__income--box-header'>
				<DollarIcon />
				<p className='my-4'>درآمد کل شما</p>
				<Price value={total_earnings} suffix='تومان' />
			</div>
			<div className='dashboard__income--box-body'>
				<div className='dashboard__income--item'>
					<CoinIcon />
					<p>درآمد این ماه شما</p>
					<Price value={month_earnings} suffix='تومان' underline />
				</div>
				<div className='dashboard__income--item'>
					<CoinsIcon />
					<p>درآمد امسال شما</p>
					<Price value={year_earnings} suffix='تومان' underline={true} />
				</div>
				<div className='dashboard__income--item'>
					<StudentIcon />
					<p>تعداد دانشجویان شما</p>
					<Price value={student_numbers} suffix='نفر' underline={true} />
				</div>
				<div className='dashboard__income--item'>
					<LaptopIcon />
					<p>تعداد دوره‌های شما</p>
					<Price value={course_numbers} suffix='دوره' underline={true} />
				</div>
				{user_is_teacher && (
					<Link type='text' to='/wallet'>
						تکمیل اطلاعات حساب
					</Link>
				)}
			</div>
		</section>
	);
};

export default Incomes;
