import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Button from '@Components/Shared/Buttons/Button';
import InputMask from '@Components/Shared/Inputs/InputMask';
import Input from '@Components/Shared/Inputs/Input';
import useFetch from "../../../Context/useFetch";
import {toast} from "react-toastify";

const Creditcardentry = ({handleNextStep, isFormFilled}) => {
    const {control, handleSubmit} = useForm();
    const [walletData, setWalletData] = useState(null);
    const [walletPostData, setWalletPostData] = useState(null);
    const [walletLoading, setWalletLoading] = useState(true);
    // const [isPostData, setIsPostData] = useState(true);


    const setData = (data) => {
        setWalletData(data)
        setWalletLoading(false)
    }

    // const togglePost = () => {
    //     setIsPostData(false)
    // }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append('card_number', data.card_number);
        formData.append('account_number', data.account_number);
        formData.append('shaba_number', data.shaba_number);
        setWalletPostData(formData);
        walletPost.reFetch();

    }

    const walletInfo = useFetch({
        url: `TeacherWalletService/get_wallet`,
        method: 'GET',
        setter: setData,
    });


    const walletPost = useFetch({
        url: !walletData?.wallet_uuid ? `TeacherWalletService` : `TeacherWalletService/${walletData?.wallet_uuid}`,
        method: !walletData?.wallet_uuid ? 'POST' : 'PATCH',
        trigger: false,
        data: walletPostData,
        caller: walletInfo,
        // setter: togglePost,
        argFunc: () => {
            toast.success(!walletData?.wallet_uuid ? 'اطلاعات با موفقیت ثبت شد' : 'اطلاعات با موفقیت ویرایش شد');
            handleNextStep('checking');
        },
        argErrFunc: (err) => handleError(err),
    });

    const handleError = (err) => {
        if (err.shaba_number) {
            toast.error("شماره شبا ۲۴ رقمی باید باشه");
        } else if (err.account_number) {
            toast.error("شماره حساب حداقل ۸ و حداکثر ۱۴ رقمی باید باشه");
        } else if (err.card_number) {
            toast.error("شماره کارت ۱۶ رقمی باید باشه");
        } else {
            toast.error(err)
        }

    };

    // console.log('isPostData: ', isPostData)
    // console.log('walletInfo: ', walletInfo)
    return (
        !walletLoading ?
            <>
                <p className='mb-11 wallet__message'>
                    لطفا اطلاعات یک حساب بانکی فعال رو وارد کنید تا درآمد دوره‌های شما
                    به اون واریز شه.
                </p>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='flex flex-col items-center gap-y-8 wallet__form'
                >
                    <InputMask
                        name='card_number'
                        label='شماره کارت'
                        control={control}
                        maxLength={16}
                        minLength={16}
                        type={'number'}
                        placeholder="1234 1234 1234 1234"
                        register={{
                            required: {
                                value: true,
                                message: 'شماره کارت اشتیاه است',
                            },
                            pattern: {
                                value: /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                                message: "شماره کارت ۱۶ رقمی باید باشه",
                            },
                        }}
                        // mask='9999 9999 9999 9999'
                        value={!walletLoading && walletData?.card_number}
                    />
                    <Input
                        name='account_number'
                        label='شماره حساب'
                        control={control}
                        type={'number'}
                        maxLength={14}
                        minLength={8}
                        placeholder="(123456) 1234 1234 "
                        register={{
                            required: {
                                value: true,
                                message: 'شماره حساب اشتیاه است',
                            },
                            pattern: {
                                value: /^([0-9]{8,14})$/,
                                message: "شماره حساب حداقل ۸ و حداکثر ۱۴ رقمی باید باشه",
                            },
                        }}
                        value={!walletLoading && walletData?.account_number}
                    />
                    <Input
                        name='shaba_number'
                        label='شماره شبا'
                        control={control}
                        type={'number'}
                        maxLength={24}
                        minLength={24}
                        placeholder="123456 123456 123456 123456"
                        register={{
                            required: {
                                value: true,
                                message: 'شماره شبا اشتیاه است',
                            },
                            pattern: {
                                value: /^\(?([0-9]{6})\)?[-. ]?([0-9]{6})[-. ]?([0-9]{6})[-. ]?([0-9]{6})$/,
                                message: "شماره شبا ۲۴ رقمی باید باشه",
                            },
                        }}
                        addonAfter='IR -'
                        classes='wallet__form--input'
                        value={!walletLoading && walletData?.shaba_number}
                    />

                    <Button type='primary' classes='wallet__btn' htmlType='submit'>
                        ثبت
                    </Button>
                </form>
                {isFormFilled ? (
                    <Button type='primary' classes='wallet__btn' htmlType='submit'
                            onClick={() => handleNextStep('checking')}>
                        مرحله بعد
                    </Button>
                ) : null}

            </> : null


    );
};

export default Creditcardentry;
