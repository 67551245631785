import React, { useState } from 'react';
import Courses from '@Components/Layouts/Dashboard/Course/Courses';
import DashboardStudents from '@Components/Layouts/Dashboard/Students/DashboardStudents';
import DashboardMessages from '@Components/Layouts/Dashboard/Messages/DashboardMessages';
import Incomes from '@Components/Layouts/Dashboard/Incomes';
import Pdf from '../../Assets/Images/Icons/tutorialpdf.svg';
import Vid from '../../Assets/Images/Icons/tutorialvid.svg';
import useFetch from '../../Context/useFetch';
import { useUserData } from '../../Context/userContext';
import { Link } from 'react-router-dom';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const Dashboard = () => {
	const { userData } = useUserData();
	const [dashboardInfo, setDashboardInfo] = useState(null);
	const [dashboardLoading, setDashboardLoading] = useState(true);

	const setData = (data) => {
		setDashboardInfo(data);
		setDashboardLoading(false);
	};

	const getDashboardInfo = useFetch({
		url: `TeacherService/dashboard`,
		trigger: userData.user_is_teacher ? true : false,
		method: 'GET',
		setter: setData,
	});

	return (
		<>
			<BreadCrumb />
			<section className='dashboard-container '>
				<div className='dashboard'>
					<div className='dashboard__course flex flex-col'>
						<h2>{`سلام ${
							dashboardInfo?.first_name || ''
						}، خوش اومدی!`}</h2>
						<Courses
							dashboardLoading={dashboardLoading}
							course={dashboardInfo?.latest_course}
						/>
					</div>
					<section className='dashboard__income flex flex-col'>
						<div className='dashboard__income--actions flex justify-between items-center'>
							<Link
								to='/'
								className='dashboard__income--actions-link flex items-center '
							>
								<img src={Pdf} alt='pdf' />
								<span>آموزش ایجاد دوره</span>
							</Link>
							<Link
								to='/'
								className='dashboard__income--actions-link flex items-center '
							>
								<img src={Vid} alt='video' />
								<span>آموزش ایجاد دوره</span>
							</Link>
						</div>
						<Incomes
							user_is_teacher={userData.user_is_teacher}
							dashboardLoading={dashboardLoading}
							month_earnings={dashboardInfo?.month_earnings}
							year_earnings={dashboardInfo?.year_earnings}
							total_earnings={dashboardInfo?.total_earnings}
							student_numbers={dashboardInfo?.student_numbers}
							course_numbers={dashboardInfo?.course_numbers}
						/>
					</section>
					<section className='dashboard__message'>
						<DashboardMessages
							dashboardLoading={dashboardLoading}
							messages={dashboardInfo?.latest_messages}
						/>
					</section>
					<section className='dashboard__student'>
						<DashboardStudents
							dashboardLoading={dashboardLoading}
							students={dashboardInfo?.best_users}
						/>
					</section>
				</div>
			</section>
		</>
	);
};

export default Dashboard;
