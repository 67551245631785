export const tourConfig = [
  {
    selector: '[data-tut="reactour__start"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مشخصات اولیه</div>
        <div style={{ marginTop: "15px" }}>
          مشخصات اولیه دوره را وارد نمایید، تا این مرحله کامل نشده، نمیتوانید
          مرحله بعد بروید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            1 of 6
          </span>
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__lock"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>نام دوره</div>
        <div style={{ marginTop: "15px" }}>
          نام دوره مورد نظر را وارد نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            2 of 6
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__courselevel"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>سطح دوره</div>
        <div style={{ marginTop: "15px" }}>
          سطح دوره ای که ایجاد مینمایید را انتخاب کنید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            3 of 6
          </span>
          {/* <button onClick={() => goTo(3)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    // selector: '[data-tut="reactour__video"]',
    selector: '[data-tut="reactour__courselan"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>زبان</div>
        <div style={{ marginTop: "15px" }}>
          زبان دوره آموزشی را از لیست انتخاب نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            4 of 6
          </span>
          {/* <button onClick={() => goTo(4)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__coursePrice"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>هزینه دوره </div>
        <div style={{ marginTop: "15px" }}>
          هزینه دوره به تومان، وارد نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            5 of 6
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__nextbtn"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div style={{ fontWeight: "600", fontSize: "18px" }}>مرحله بعد</div>
        <div style={{ marginTop: "15px" }}>
          از این قسمت میتونی به مباحث بعدی دسترسی داشته باشی. در صورتی این گزینه
          فعاله که تمامی مراحل این مبحث رو پر کرده باشی{" "}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            6 of 6
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
];
