import { useAuth } from "@App/Context/authContext";
import React from "react";
// ----------------- Pages -----------------

import Authenticated from "../Authenticated";
import UnAuthenticated from "../UnAuthenticated";
import { UserDataProvider } from "../Context/userContext";
import ClickPrevent from "@Components/hooks/ClickPrevent/ClickPrevent";

const Router = () => {
  const { token } = useAuth();

  return (
    <>
      <ClickPrevent>
        {token ? <Authenticated /> : <UnAuthenticated />}
      </ClickPrevent>
    </>
  );
};
export default Router;
