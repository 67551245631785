import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import { useHistory, useParams } from "react-router-dom";
import SeasonContentForm from "./SeasonContentForm";
import useFetch from "@App/Context/useFetch";
import Modal from "@Components/Shared/Modal/Modal";
import UploadVideo from "./UploadVideo";
import SeasonDeleteModal from "./Modal/SeasonDeleteModal";
const SeasonsContentItem = ({
  title,
  getCoursesSeasonsContent,
  id,
  lockedOn,
  video_data,
  status,
}) => {
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const [isShowUploadVideo, setShowUploadVideo] = useState(false);
  const [video, setVideo] = useState(video_data);
  const { courseId } = useParams();
  const [del, setDel] = useState(false);

  const toggleForm = () => {
    setEditForm((before) => !before);
  };
  const deleteCourseSeasonContent = useFetch({
    // url: `ContentService/${id}`,
    url: `ContentService/${id}/deleteBriefContent`,
    method: "DELETE",
    trigger: false,
    caller: getCoursesSeasonsContent,
    fun: () => handleCloseModal(),
  });

  const [modal, setModal] = useState(false);
  const handleModalVisible = () => {
    setModal(false);
  };
  const handleModalShow = (uuid, lock) => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setShowUploadVideo((before) => !before);
    if (del) {
      getContentDetail.reFetch();
    }
  };
  const getContentDetail = useFetch({
    url: `ContentService/${id}/getContentVideo`,
    trigger: false,
    method: "GET",
    argFunc: (res) => {
      setVideo(res.video);
    },
  });

  return (
    <>
      {editForm && (
        <SeasonContentForm
          ToggleForm={toggleForm}
          getCoursesSeasonsContent={getCoursesSeasonsContent}
          id={id}
          edit
          title={title}
          lockedOn={lockedOn}
        />
      )}
      <div className="SeasonsContentItem d-flex-space">
        <p className="SeasonsContentItem__title">{title}</p>
        <div className="d-flex-align">
          <Button type="text" size="small" onClick={handleCloseModal}>
            {video === "" || video == null ? (
              <div className=" flex items-center">
                {" "}
                <PlusIcon />
                <p>&nbsp;&nbsp;اضافه کردن ویدئو </p>
              </div>
            ) : (
              <p>مشاهده ویدئو </p>
            )}
          </Button>
          <Button
            type="text"
            size="small"
            onClick={() =>
              history.push({
                pathname: `/my-course/${courseId}/${id}`,
                state: {
                  lockedOn: lockedOn,
                },
              })
            }
          >
            <PlusIcon />
            <p>اضافه کردن محتوا</p>
          </Button>

          <div className="" onClick={toggleForm}>
            <IconBtn title="ویرایش" icon={<Pencil />} />
          </div>
          {status !== "منتشر شده" ? (
            <div onClick={handleModalShow}>
              <IconBtn title="حذف" icon={<Trash />} danger />
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        maskClosable={false}
        visible={isShowUploadVideo}
        onCancel={handleCloseModal}
      >
        <UploadVideo
          id={id}
          status={status}
          video={video}
          getContentDetail={getContentDetail}
          setVideo={setVideo}
          setDel={setDel}
        />
      </Modal>
      <SeasonDeleteModal
        modal={modal}
        handleModalVisible={handleModalVisible}
        deleteItem={deleteCourseSeasonContent}
      />
    </>
  );
};

export default SeasonsContentItem;
