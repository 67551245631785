import React from "react";
import Modal from "@Components/Shared/Modal/Modal";
import Button from "@Components/Shared/Buttons/Button";

const SeasonDeleteModal = ({ modal, handleModalVisible, deleteItem }) => {
  return (
    <Modal
      maskClosable={false}
      className="ExitModal"
      visible={modal}
      onCancel={handleModalVisible}
    >
      <div className="ExitModal__back">
        <p className="mb-12">آیا از حذف مبحث مطمئن هستید؟</p>
        <div className="d-flex-space">
          <Button
            onClick={() => deleteItem.reFetch()}
            disabled={deleteItem.loading}
          >
            بله
          </Button>
          <Button onClick={handleModalVisible} type="primary">
            خیر
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SeasonDeleteModal;
