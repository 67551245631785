import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "@Components/Shared/Inputs/Input";
import { ReactComponent as Trash } from "@Assets/Icons/Cancel.svg";
import { ReactComponent as Submit } from "@Assets/Icons/CheckCircle.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import useFetch from "@App/Context/useFetch";
import CheckboxInput from "@Components/Shared/Inputs/CheckboxInput";
const SeasonContentForm = ({
  ToggleForm,
  id,
  edit,
  getCoursesSeasonsContent,
  title,
  lockedOn,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content_name: edit ? title : "",
      content_lock: edit ? lockedOn : false,
    },
  });

  const [postData, setPostData] = useState();
  const createCourseSeasonContent = useFetch({
    url: `ContentService/createBriefContent`,
    method: "POST",
    trigger: false,
    data: postData,
    func: () => ToggleForm(),
    caller: getCoursesSeasonsContent,
  });

  const editCourseSeasonContent = useFetch({
    url: `ContentService/${id}/updateBriefContent`,
    method: "PATCH",
    trigger: false,
    data: postData,
    func: () => ToggleForm(),
    caller: getCoursesSeasonsContent,
  });
  const onSubmit = (data) => {
    setPostData({
      title: data.content_name,
      season_uuid: id,
      lockedOn: data.content_lock,
    });

    if (edit) editCourseSeasonContent.reFetch();
    else createCourseSeasonContent.reFetch();
  };
  return (
    <form
      className="CreateSeasonsForm__topic mb-8 d-flex-align-end"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="عنوان مبحث"
        register={{
          required: {
            value: true,
            message: "عنوان مبحث را وارد کنید",
          },
        }}
        name="content_name"
        control={control}
        absolute
        autoFocus
      />

      <div className="resume__checkbox">
        <CheckboxInput
          label="قفل مبحث"
          register={{
            required: false,
          }}
          name="content_lock"
          control={control}
          large
        />
      </div>
      <div className="d-flex-align ">
        <IconBtn
          htmlType="submit"
          title="تایید"
          icon={<Submit />}
          success
          classes="px-7"
          disabled={
            createCourseSeasonContent.loading || editCourseSeasonContent.loading
          }
        />

        <IconBtn
          classes="cancel-icon"
          title="لغو"
          icon={<Trash />}
          danger
          onClick={ToggleForm}
          disabled={
            createCourseSeasonContent.loading || editCourseSeasonContent.loading
          }
        />
      </div>
    </form>
  );
};

export default SeasonContentForm;
