import React, { useState } from "react";
import Description from "./SecondPhaseForms/Description";
import Example from "./SecondPhaseForms/Example";
import DescriptionItem from "./SecondPhaseDoneItem/DescriptionItem";
import CodeEditor from "./SecondPhaseDoneItem/CodeEditor";
import ExampleItem from "./SecondPhaseDoneItem/ExampleItem";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import { Accordion, Panel } from "@Components/Shared/Accordion/Accordion";

import { useParams, useHistory } from "react-router";
import useFetch from "../../../Context/useFetch";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import { ReactComponent as DragIcon } from "@Assets/Icons/drag.svg";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import CodeEditorItem from "./SecondPhaseDoneItem/CodeEditorItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import { before } from "lodash";
import Modal from "@Components/Shared/Modal/Modal";
import { Skeleton } from "antd";
import SkeletonInput from "antd/lib/skeleton/Input";

const titleList = {
  DES: {
    title: "توضیحات",
  },
  EXAMPLE: {
    title: "مثال",
  },
  CODETEXT: {
    title: "قطعه کد",
  },
};

const ContentSecondPhase = ({
  nextStep,
  prevStep,
  setLanguageId,
  setLanguageName,
  setQuizId,
}) => {
  const [Form, setForm] = useState(""); // DES / EXAMPLE / CODETEXT
  const [titleModal, setTitleModal] = useState(""); // DES / EXAMPLE / CODETEXT
  const [isDisplayModal, setDisplayModal] = useState(false);
  const [isEditForm, setEditForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [lanid, setLanid] = useState("");
  const { contentId, courseId } = useParams();
  const history = useHistory();
  const ChangeForm = (name) => {
    setForm(name);
    setDisplayModal(true);
    const { title } = titleList[name];
    setTitleModal(title);
  };
  const toggleDisplayModal = () => {
    setDisplayModal((before) => !before);
    setSelectedItem({});
  };
  const resetForm = () => {
    setDisplayModal(false);
  };

  const [sessionDetail, setSessionDetail] = useState({ context: [] });

  const [contentPosition, setContentPosition] = useState([]);
  const [next, setNext] = useState(true);

  const updatePositionList = (type) => {
    setContentPosition([...contentPosition, type]);
  };
  const getContentDetail = useFetch({
    url: `ContentService/${contentId}/contentSecondPhaseGet`,
    setter: setSessionDetail,
    // setter:setCategories,
    argFunc: (res) => {
      setContentPosition(new Array(res.context.length));
      setLanguageId(parseInt(res.language_id));
      setLanid(parseInt(res.language_id));
      setLanguageName(res.language);
      setQuizId(res.quiz_id);
      setNext(false);
    },
  });

  const [selectedID, setSelectedID] = useState();
  const [selectedEditID, setSelectedEditID] = useState();

  const [editType, setEditType] = useState();
  const deleteCodeText = useFetch({
    url: `CodeTextService/${selectedID}`,
    method: "DELETE",
    trigger: false,
    caller: getContentDetail,
    argFunc: () =>
      setContentPosition((before) =>
        before.splice(1, contentPosition.length - 1)
      ),
  });

  const deleteExample = useFetch({
    url: `ExampleService/${selectedID}`,
    method: "DELETE",
    trigger: false,
    caller: getContentDetail,
    argFunc: () =>
      setContentPosition((before) =>
        before.splice(1, contentPosition.length - 1)
      ),
  });

  const triggerSortingItems = useFetch({
    url: `ContentService/${contentId}/sortingItems`,
    method: "POST",
    trigger: false,
    data: {
      new_items: sessionDetail.context.map((item) => ({
        uuid: item.uuid,
        index: item.index,
        type: item.code ? "code" : item.text ? "text" : "example",
      })),
    },
    caller: getContentDetail,
    message: "مرتب سازی با موفقیت انجام شد",
  });

  const handleSelectItem = (id, type) => {
    const item = sessionDetail.context.find(({ uuid }) => uuid == id);
    setSelectedItem(item);
    setForm(type);
    setDisplayModal(true);
    setEditForm(true);
  };

  const extraAction = (id, status, type) => (
    <div
      className="accordion__action flex"
      onClick={(event) => event.stopPropagation()}
    >
      <IconBtn
        title="ویرایش"
        icon={<Pencil />}
        onClick={() => {
          handleSelectItem(id, type);
        }}
      />
      {status !== "منتشر شده" ? (
        <IconBtn
          onClick={() => {
            if (type !== "example") {
              setSelectedID(id);
              //? for close edit form
              setEditType();
              deleteCodeText.reFetch();
            } else {
              setSelectedID(id);
              //? for close edit form
              setEditType();
              deleteExample.reFetch();
            }
          }}
          title="حذف"
          icon={<Trash />}
          danger
        />
      ) : null}
    </div>
  );
  console.log("first", lanid);
  const handleDragEnd = (result) => {
    // console.log('result: ', result);
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = Array.from(sessionDetail.context);
    // console.log('items: ', items);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((item, key) => {
      item.index = key;
    });
    // console.log('items2: ', items);
    setSessionDetail((before) => ({ ...before, context: items }));
    // console.log('sessionDetail: ', sessionDetail);
  };

  const renderForms = () => {
    switch (Form) {
      case "DES":
        return (
          <Description
            getContentDetail={getContentDetail}
            resetForm={toggleDisplayModal}
            updatePositionList={updatePositionList}
            contentPosition={contentPosition}
            isEdit={isEditForm}
            item={selectedItem}
          />
        );

      case "EXAMPLE":
        return (
          <Example
            getContentDetail={getContentDetail}
            resetForm={toggleDisplayModal}
            updatePositionList={updatePositionList}
            contentPosition={contentPosition}
            isEdit={isEditForm}
            item={selectedItem}
            lanid={lanid}
          />
        );
      case "CODETEXT":
        return (
          <CodeEditor
            getContentDetail={getContentDetail}
            resetForm={toggleDisplayModal}
            updatePositionList={updatePositionList}
            contentPosition={contentPosition}
            isEdit={isEditForm}
            item={selectedItem}
            lanid={lanid}
          />
        );
      default:
        return "not found";
    }
  };

  // console.log('sessionDetail: ', sessionDetail.context.length);
  return (
    <>
      <div className="ContentSecondPhase">
        <div className="ContentSecondPhase__btnrow">
          <Button
            onClick={() => ChangeForm("DES")}
            type="text"
            size="small"
            success
          >
            <PlusIcon />
            <p>افزودن توضیحات </p>
          </Button>
          <Button
            onClick={() => {
              ChangeForm("EXAMPLE");
              setEditForm(false);
            }}
            type="text"
            size="small"
            success
          >
            <PlusIcon />
            <p>افزودن مثال</p>
          </Button>
          <Button
            onClick={() => ChangeForm("CODETEXT")}
            type="text"
            size="small"
            success
          >
            <PlusIcon />
            <p>افزودن قطعه کد </p>
          </Button>
        </div>
        {!getContentDetail.loading ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="content">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="ContentSecondPhase__content"
                >
                  {sessionDetail.context.map((item, index) => (
                    <Draggable
                      key={`item-${item.uuid}`}
                      draggableId={`item-${item.uuid}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            title="میتونی ترتیب منو عوض کنی"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classNames("relative", {
                              "ContentSecondPhase__content--drag":
                                snapshot.isDragging,
                            })}
                          >
                            <Accordion classes="ContentSecondPhase__content--accordion">
                              <Panel
                                header={
                                  <>
                                    <div
                                      className="ContentSecondPhase__content--icon"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIcon />
                                    </div>
                                    {item.text ? (
                                      <span>توضیحات</span>
                                    ) : item.code ? (
                                      <span>قطعه کد</span>
                                    ) : (
                                      <span> مثال {item.name}</span>
                                    )}
                                  </>
                                }
                                extra={extraAction(
                                  item.uuid,
                                  item.status,
                                  item.text
                                    ? "DES"
                                    : item.code
                                    ? "CODETEXT"
                                    : "EXAMPLE"
                                )}
                              >
                                {item.text ? (
                                  <DescriptionItem text={item.text} />
                                ) : item.code ? (
                                  //   <ExampleItem code={item.code} />
                                  <CodeEditorItem
                                    item={item}
                                    lan={sessionDetail.language}
                                    lanid={lanid}
                                  />
                                ) : (
                                  <ExampleItem
                                    item={item}
                                    getContentDetail={getContentDetail}
                                    resetForm={resetForm}
                                    updatePositionList={updatePositionList}
                                    contentPosition={contentPosition}
                                    lanid={lanid}
                                  />
                                )}
                              </Panel>
                            </Accordion>
                          </div>
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div>
            <Skeleton />
          </div>
        )}

        {/* <Accordion bordered={false} header="مثال" remove={true}>
            <ExampleItem />
          </Accordion> */}
      </div>
      <div className="btn-box flex mt-8">
        <div className="flex">
          <div className="ml-8">
            <Button type="primary" onClick={prevStep}>
              مرحله ی قبل
              <RightArrow />
            </Button>
          </div>
          {sessionDetail.context.length > 1 ? (
            <Button
              // htmlType="submit"
              onClick={() => triggerSortingItems.reFetch()}
              disabled={triggerSortingItems.loading}
            >
              ثبت مرتب سازی
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className="flex">
          <div className="ml-8">
            <Button onClick={() => history.push(`/my-course/${courseId}`)}>
              برگشت به جلسات
            </Button>
          </div>
          <Button type="primary" onClick={nextStep} disabled={next}>
            <LeftArrow />
            مرحله ی بعد
          </Button>
        </div>
      </div>
      <Modal
        visible={isDisplayModal}
        onCancel={toggleDisplayModal}
        title={titleModal}
        classes="min-w-max pb-0"
        maskClosable={false}
      >
        {!!getContentDetail.response && renderForms()}
      </Modal>
    </>
  );
};

export default ContentSecondPhase;
