import PropTypes from "prop-types";
import React from "react";

const MyCourseFilterItem = ({ title, number, updateFilterSearch, state }) => {
  return (
    <div
      className="flex cursor-pointer mx-2"
      onClick={() => updateFilterSearch(state)}
    >
      <p>{title}</p>
      <p className="text-primary mx-1">({number})</p>
    </div>
  );
};

MyCourseFilterItem.propTypes = {
  number: PropTypes.any,
  title: PropTypes.any,
};

export default MyCourseFilterItem;
