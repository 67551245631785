import React, { useState } from "react";
import CreateSeasonsForm from "./CreateSeasonsForm";
import CreateSeasonContent from "./CreateSeasonContent";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import { useHistory } from "react-router-dom";
import SeasonsContentItem from "./SeasonsContentItem";
import SeasonsQuizItem from "./SeasonsQuizItem";
import useFetch from "../../../Context/useFetch";
import { Link, useParams } from "react-router-dom";

const SeasonsListAccordion = ({
  id,
  languageId,
  languageName,
  status,
  type,
}) => {
  const history = useHistory();
  const [seasonContents, setSeasonContents] = useState({ contents: [] });
  const getCoursesSeasonsContent = useFetch({
    url: `SeasonService/${id}`,
    method: "GET",
    setter: setSeasonContents,
  });
  const { courseId } = useParams();

  return (
    <div className="">
      {/* <CreateSeasonsForm /> */}
      <CreateSeasonContent
        id={id}
        seasonContents={seasonContents}
        getCoursesSeasonsContent={getCoursesSeasonsContent}
        type={type}
      />
      <div className="CreateSeasonContent px-20">
        <div className="d-flex-space mb-4">
          <p className=" CreateSeasonContent_title">آزمون:</p>
          {!seasonContents.quizzes?.uuid && (
            <Button
              type="text"
              size="small"
              success
              onClick={() =>
                history.push({
                  pathname: `/my-course/createseasons/add-quize/${courseId}/${id}`,
                  state: {
                    languageId: languageId,
                    languageName: languageName,
                    quizId: seasonContents.quizzes?.uuid,
                  },
                })
              }
              // disabled={type === "منتشر شده" ? true : false}
              // classes={type === "منتشر شده" ? " cursor-not-allowed" : ""}
            >
              <PlusIcon />
              <p>اضافه کردن آزمون جدید</p>
            </Button>
          )}
        </div>
        {seasonContents.quizzes?.uuid && (
          <SeasonsQuizItem
            seasonId={id}
            languageId={languageId}
            languageName={languageName}
            quizId={seasonContents.quizzes?.uuid}
            quizName={seasonContents.quizzes?.name}
            quizStatus={seasonContents.quizzes?.status}
            getCoursesSeasonsContent={getCoursesSeasonsContent}
          />
        )}
      </div>
    </div>
  );
};

export default SeasonsListAccordion;
