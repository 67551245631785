import React, { useState } from "react";
import { ReactComponent as HomeIcon } from "@Assets/Icons/home.svg";
import { ReactComponent as UserIcon } from "@Assets/Icons/user.svg";
import { ReactComponent as ResumeIcon } from "@Assets/Icons/resume.svg";
import { ReactComponent as CourseIcon } from "@Assets/Icons/course.svg";
import { ReactComponent as WalletIcon } from "@Assets/Icons/wallet.svg";
import { ReactComponent as MessageIcon } from "@Assets/Icons/message.svg";
import { ReactComponent as SettingIcon } from "@Assets/Icons/setting.svg";
import { ReactComponent as CloseIcon } from "@Assets/Icons/close.svg";
import { ReactComponent as ExiteIcon } from "@Assets/Icons/quite.svg";
import SidebarMenuItem from "./SidebarMenuItem";
import { useUserData } from "../../../Context/userContext";
import { useAuth } from "../../../Context/authContext";
import { useHistory } from "react-router";
import Button from "../Buttons/Button";
import Modal from "@Components/Shared/Modal/Modal";

const SidebarMenu = () => {
  const { userData } = useUserData();
  const sidebarmenuItem = [
    {
      text: "پیشخوان",
      icon: <HomeIcon />,
      url: "/",
    },
    {
      text: "پروفایل",
      icon: <UserIcon />,
      url: "/profile",
    },
    {
      text: "رزومه",
      icon: <ResumeIcon />,
      // url: userData.user_is_teacher ? '/resume' : '/profile',
      url: "/resume",
    },
    {
      text: "دوره های من",
      icon: <CourseIcon />,
      // url: userData.user_is_teacher ? '/my-course' : '/profile',
      url: "/my-course",
    },
    {
      text: "کیف پول",
      icon: <WalletIcon />,
      // url: userData.user_is_teacher ? '/wallet' : '/profile',
      url: "/wallet",
    },
    {
      text: "پیام ها",
      icon: <MessageIcon />,
      // url: userData.user_is_teacher ? '/messages' : '/profile',
      url: "/messages",
    },
    {
      text: "تنظیمات",
      icon: <SettingIcon />,
      // url: userData.user_is_teacher ? '/setting' : '/profile',
      url: "/setting",
    },
  ];
  const [modal, setModal] = useState(false);
  const { authDispatch } = useAuth();
  const [expand, setExpand] = useState(false);

  const closeNav = () => {
    setExpand((prev) => !prev);
  };
  const handleModalVisible = () => {
    setModal(false);
  };
  const handleModalShow = (uuid, lock) => {
    setModal(true);
  };
  const logout = () => {
    authDispatch({ type: "LOGOUT" });
    // navigate("/", {replace: true});
    navigate.push("/");
  };
  const navigate = useHistory();

  return (
    <div
      className="sidebarMenu"
      style={!expand ? { width: "176px" } : { width: "60px" }}
    >
      <ul className="sidebarMenu__ul list">
        <ul>
          {sidebarmenuItem.map((item) => (
            <SidebarMenuItem
              {...item}
              user_is_teacher={userData.user_is_teacher}
              expand={expand}
            />
          ))}
        </ul>
        <ul className="sidebarMenu__nestedul">
          <li className="sidebarMenu__li d-flex-align close" onClick={closeNav}>
            {!expand ? (
              <>
                {" "}
                <div className="menuIcon">
                  <CloseIcon />
                </div>
                <p>بستن</p>
              </>
            ) : (
              <CloseIcon />
            )}
          </li>
          <li
            className="sidebarMenu__li d-flex-align exit"
            onClick={handleModalShow}
          >
            {!expand ? (
              <>
                {" "}
                <div className="menuIcon">
                  <ExiteIcon />
                </div>
                <p>خروج</p>
              </>
            ) : (
              <ExiteIcon />
            )}
          </li>
        </ul>
      </ul>
      <Modal
        className="ExitModal"
        visible={modal}
        onCancel={handleModalVisible}
      >
        <div className="ExitModal__back">
          <p className="mb-12">آیا از خروج مطمئن هستید؟</p>
          <div className="d-flex-space">
            <Button onClick={logout}>بله</Button>
            <Button onClick={handleModalVisible} type="primary">
              خیر
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SidebarMenu;
