import React, { useEffect, useState } from "react";
import SeasonsListAccordion from "@Components/Layouts/CreateSeasons/SeasonsListAccordion";
import { Accordion, Panel } from "@Components/Shared/Accordion/Accordion";
import Button from "@Components/Shared/Buttons/Button";
import { useParams, useLocation } from "react-router";
import useFetch from "@App/Context/useFetch";
import CreateSeasonsForm from "@Components/Layouts/CreateSeasons/CreateSeasonsForm";
import IconBtn from "@Components/Shared/Buttons/IconBtn";

import { ReactComponent as Pencil } from "@Assets/Icons/Pencil.svg";
import { ReactComponent as Trash } from "@Assets/Icons/Trash.svg";
import { ReactComponent as LockIcon } from "@Assets/Icons/lock.svg";
import empthySeason from "@Assets/Pic/nocard.svg";
import Modal from "@Components/Shared/Modal/Modal";
import classNames from "classnames";
import { toast } from "react-toastify";
import Pagination from "../../Components/Shared/Pagination";
import BreadCrumb from "@Components/Shared/BreadCrump/BreaCrumb";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from "@Assets/Icons/drag.svg";
import { Skeleton } from "antd";

const CreateSeasons = () => {
  const { courseId } = useParams();
  const [courseSeasons, setCourseSeasons] = useState([]);
  const [isShowForm, setShowForm] = useState(false);
  const [seasonId, setSeasonId] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState({});
  const [isLock, setLock] = useState(false);
  const [type, setType] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.type) setType(location.state.type);
  }, [location]);
  // const [pagination, setPagination] = useState({
  //     current: 1,
  //     // pageSize: courseSeasons?.page_size,
  // });
  // useEffect(() => {
  //     getCoursesSeasons.reFetch();
  // }, [pagination]);

  const getCoursesSeasons = useFetch({
    url: `CourseService/${courseId}/seasons_teacher`,
    method: "GET",
    setter: setCourseSeasons,
    // pagination: pagination,
  });

  const deleteCoursesSeasons = useFetch({
    url: `SeasonService/${seasonId}`,
    method: "DELETE",
    caller: getCoursesSeasons,
    trigger: false,
    func: () => handleModalVisible(),
  });

  const updateLock = useFetch({
    url: `SeasonService/${seasonId}`,
    method: "PATCH",
    data: {
      lockedOn: isLock,
    },
    trigger: false,
    argFunc: (res) => {
      console.log("selectedSeason: ", selectedSeason);
      if (selectedSeason.get_status_display === "منتشر شده") {
        toast.success("تغییرات ارسال شد.پس از بررسی اعمال خواهدشد");
      }
      getCoursesSeasons.reFetch();
    },
  });

  const triggerSortingSeason = useFetch({
    url: `CourseService/${courseId}/sortingSeasons`,
    method: "POST",
    trigger: false,
    data: {
      new_items: courseSeasons.map((item) => ({
        uuid: item.uuid,
        index: item.index,
      })),
    },
    caller: getCoursesSeasons,
    message: "مرتب سازی با موفقیت انجام شد",
  });

  const handleShowForm = () => {
    setShowForm((before) => !before);
    setSelectedSeason({});
  };

  const handleDeleteSeason = (id) => {
    setSeasonId(id);
    deleteCoursesSeasons.reFetch();
  };

  const handleLockSeason = (id, lock) => {
    setLock(lock);
    setSeasonId(id);
    const item = courseSeasons.find((item) => item.uuid === id);
    setSelectedSeason(item);
    updateLock.reFetch();
  };

  const handleEditSeason = (id) => {
    handleShowForm();
    const item = courseSeasons.find((item) => item.uuid === id);
    setSelectedSeason(item);
  };
  const [modal, setModal] = useState(false);
  const handleModalVisible = () => {
    setModal(false);
  };
  const handleModalShow = (uuid, lock) => {
    setModal(true);
  };
  const handleDragEnd = (result) => {
    // console.log('result: ', result)
    console.log("result: ", result);
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = Array.from(courseSeasons);
    // console.log('items: ', items);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((item, key) => {
      item.index = key;
    });
    // console.log('items: ', items);
    // setCourseSeasons((before) => ({...before, items}));
    setCourseSeasons(items);
    console.log("courseSeasons: ", courseSeasons);
  };

  const extraAction = (id, lock, status) => (
    <div
      className="accordion__action flex"
      onClick={(event) => event.stopPropagation()}
    >
      <IconBtn
        title="ویرایش"
        icon={<Pencil />}
        onClick={() => handleEditSeason(id)}
      />
      {status !== "منتشر شده" ? (
        <IconBtn
          onClick={() => handleModalShow()}
          title="حذف"
          icon={<Trash />}
          danger
        />
      ) : null}
      <IconBtn
        {...(status !== "منتشر شده" && {
          onClick: () => handleLockSeason(id, lock),
        })}
        classes={classNames("CreateSeason__btn", {
          lock: lock,
        })}
        title={lock ? "بازکردن" : "بستن"}
        icon={
          <>
            <LockIcon />
            <i />
          </>
        }
      />

      {modal && (
        <Modal
          className="ExitModal"
          visible={modal}
          onCancel={handleModalVisible}
        >
          <div className="ExitModal__back">
            <p className="mb-12">آیا از حذف جلسه مطمئن هستید؟</p>
            <div className="d-flex-space">
              <Button
                onClick={() => handleDeleteSeason(id)}
                disabled={deleteCoursesSeasons.loading}
              >
                بله
              </Button>
              <Button onClick={handleModalVisible} type="primary">
                خیر
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <>
      <BreadCrumb />
      <div className="flex-auto">
        <div className="center">
          <h3 className="title mt-20 mb-10"> جلسات دوره</h3>
        </div>
        <div className="d-flex justify-between">
          <div className="d-flex-justify-start">
            <Button
              onClick={handleShowForm}
              // disabled={type === "منتشر شده" ? true : false}
            >
              افزودن جلسه
            </Button>
          </div>
          <div className="d-flex-justify-end">
            <Button
              onClick={() => triggerSortingSeason.reFetch()}
              disabled={
                triggerSortingSeason.loading ||
                type === "منتشر شده" ||
                courseSeasons.length === 0
                  ? true
                  : false
              }
              // disabled={triggerSortingSeason.loading}
            >
              {" "}
              ثبت مرتب سازی جلسات
            </Button>
          </div>
        </div>
        {!getCoursesSeasons.loading ? (
          <>
            {courseSeasons.length > 0 ? (
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="content">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        // className='ContentSecondPhase__content'
                      >
                        {courseSeasons.map((item, index) => (
                          <Draggable
                            key={`item-${item.uuid}`}
                            draggableId={`item-${item.uuid}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <>
                                <div
                                  ref={provided.innerRef}
                                  className="relative"
                                  {...provided.draggableProps}
                                  // {...provided.dragHandleProps}
                                >
                                  <Accordion
                                    accordion
                                    classes={classNames(
                                      "CreateSeason__accordion",
                                      {
                                        "CreateSeason__accordion--drag":
                                          snapshot.isDragging,
                                      }
                                    )}
                                  >
                                    <Panel
                                      key={item.uuid}
                                      header={
                                        <>
                                          <div
                                            className="ContentSecondPhase__content--icon"
                                            {...provided.dragHandleProps}
                                          >
                                            <DragIcon onClick={handleDragEnd} />
                                          </div>
                                          <span>
                                            <span className="ml-2">
                                              جلسه {item.title}
                                            </span>
                                            -
                                            <span className="mr-2">
                                              {item.duration_time}
                                            </span>
                                          </span>
                                        </>
                                      }
                                      extra={extraAction(
                                        item.uuid,
                                        !item.lockedOn,
                                        item.get_status_display
                                      )}
                                    >
                                      <SeasonsListAccordion
                                        status={item.get_status_display}
                                        languageId={item.language_id}
                                        languageName={item.language}
                                        id={item.uuid}
                                        type={type}
                                        courseId={courseId}
                                      />
                                    </Panel>
                                  </Accordion>
                                </div>
                              </>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {/*<div className='center'>*/}
                {/*    <Pagination*/}
                {/*        onChange={(page) =>*/}
                {/*            setPagination({*/}
                {/*                ...pagination,*/}
                {/*                current: page,*/}
                {/*            })*/}
                {/*        }*/}
                {/*        current={pagination.current}*/}
                {/*        total={courseSeasons?.page_size}*/}
                {/*    />*/}
                {/*</div>*/}
              </>
            ) : (
              <div className="center-col empthy__season">
                <img className="image" src={empthySeason} alt="" />
                <p>در حال حاضر جلسه ای ایجاد نشده</p>
              </div>
            )}
          </>
        ) : (
          <div className="w-full mt-10">
            <Skeleton.Button block active size="large" />
            <br />
            <br />
            <Skeleton.Button block active size="large" />
            <br />
            <br />
            <Skeleton.Button block active size="large" />
            <br />
            <br />
            <Skeleton.Button block active size="large" />
            <br />
            <br />
          </div>
        )}
      </div>
      <Modal visible={isShowForm} onCancel={handleShowForm} title="افزودن جلسه">
        <CreateSeasonsForm
          getCoursesSeasons={getCoursesSeasons}
          handleShowForm={handleShowForm}
          data={selectedSeason}
        />
      </Modal>
    </>
  );
};

export default CreateSeasons;
