import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as VideoIcon } from "@Assets/Icons/upload-video.svg";
import classNames from "classnames";
import { Progress } from "antd";
import { isEmpty } from "lodash";
import { useParams } from "react-router";
import VideoPlayer from "@Components/Shared/Video/VideoPlayer";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import useFetch from "@App/Context/useFetch";
import { ToastContainer } from "react-toastify";

const override = css`
  display: block;
  margin: 4rem auto 0 auto;
  border-color: #ffa44a;
`;

const UploadVideo = (props) => {
  const [loader, setLoader] = useState(false);
  const [del, setDel] = useState(false);

  const { courseId } = useParams();
  const [videoSrc, setVideoSrc] = useState("");
  const [postData, setPostData] = useState();
  const [precent, setPrecent] = useState(0);
  const handleSelectVideo = (files) => {
    const formData = new FormData();
    formData.append("video", files[0]);
    const src = URL.createObjectURL(files[0]);
    setVideoSrc(src);
    setPostData(formData);
    setLoader(true);
    updateContentDetail.reFetch();
  };
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: "video/*",
    onDropAccepted: handleSelectVideo,
  });
  const updateContentDetail = useFetch({
    url: `ContentService/${props.id}/updateContentVideo`,
    trigger: false,
    data: postData,
    method: "PATCH",
    message: "ویدیو با موفقیت آپلود شد",
    argFunc: (res) => {
      setPrecent(100);
      setLoader(true);
      setDel(false);
      props.setDel(false);
    },
    argErrFunc: (res) => {
      setLoader(false);
      setDel(false);
      props.setDel(false);
    },
    caller: props.getContentDetail,
  });
  const handleDelete = () => {
    getInputProps();
    setVideoSrc("");
    props.setVideo(null);
    setDel(true);
    props.setDel(true);
  };

  return (
    <>
      {del ? (
        <div
          className={classNames("upload__video", {
            accept: isDragAccept,
            reject: isDragReject,
          })}
        >
          {loader ? (
            <div className="VideoPlayer__loader">
              <DotLoader
                color="#f68521"
                loading={loader}
                css={override}
                size={50}
              />
            </div>
          ) : (
            <div {...getRootProps({ className: "dropzone " })}>
              <input {...getInputProps()} />
              <VideoIcon />
              <p>ویدئو ر1 اینجا رها کنید، یا کلیک کنید</p>
            </div>
          )}
        </div>
      ) : props.video === null ? (
        <div
          className={classNames("upload__video", {
            accept: isDragAccept,
            reject: isDragReject,
            uploading: !isEmpty(acceptedFiles),
          })}
        >
          {videoSrc === "" ? (
            <div {...getRootProps({ className: "dropzone " })}>
              <input {...getInputProps()} />
              <VideoIcon />
              <p>ویدئو ر1 اینجا رها کنید، یا کلیک کنید</p>
            </div>
          ) : (
            <div className="upload__video--progress">
              {loader ? (
                <div className="VideoPlayer__loader">
                  <DotLoader
                    color="#f68521"
                    loading={loader}
                    css={override}
                    size={50}
                  />
                </div>
              ) : (
                <>
                  <i
                    className="fas fa-trash-alt DragAndDrop__DeleteIcon absolute cursor-pointer"
                    onClick={handleDelete}
                  ></i>
                  <VideoPlayer src={videoSrc} />
                </>
              )}
              <Progress
                strokeColor={{
                  "0%": "#ef8019",
                  "100%": "#f9a825",
                }}
                percent={precent}
                size="small"
                status="active"
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {props.status !== "منتشر شده" ? (
            <i
              className="fas fa-trash-alt DragAndDrop__DeleteIcon absolute cursor-pointer"
              onClick={handleDelete}
            ></i>
          ) : (
            ""
          )}
          <VideoPlayer src={props.video} />
        </>
      )}
    </>
  );
};
export default UploadVideo;
