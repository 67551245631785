import React from "react";
import _ from "lodash";
import Link from "@Components/Shared/Buttons/Link";
// import {Link} from 'react-router-dom';
import { Link as LinkBase } from "react-router-dom";
import Price from "@Components/Shared/Price/Price";
import { useUserData } from "@App/Context/userContext";
import DashboardBox from "../DashboardBox";

// Assets
import { ReactComponent as CourseEmptyIcon } from "@Assets/Icons/course-empty.svg";
import { ReactComponent as StudentIcon } from "@Assets/Icons/Student.svg";
import { ReactComponent as DollarIcon } from "@Assets/Icons/dollar.svg";

const Courses = ({ course }) => {
  const { userData } = useUserData();
  console.log("course: ", course);
  const renderCourseEmpty = () => (
    <div className="dashboard__course--empty">
      <CourseEmptyIcon />
      <p>
        هنوز هیچ دوره ای ایجاد نکردی؟!
        <br />
        وقتشه دست به کار شی
      </p>
      {!userData.user_is_teacher ? (
        <Link to="/profile">ساخت پروفایل</Link>
      ) : (
        <Link to="/my-course">ایجاد دوره</Link>
      )}
    </div>
  );
  return (
    <DashboardBox title="آخرین دوره شما:" classes="dashboard__course--box">
      {_.isEmpty(course) ? (
        renderCourseEmpty()
      ) : (
        <article className="dashboard__course--item flex ">
          <div className="dashboard__course--item-cover">
            <img
              src={course.cover}
              alt={course.title}
              className="image object-cover"
            />
          </div>
          <div className="dashboard__course--item-content">
            <h2>{course.title}</h2>
            <div className="dashboard__course--item-info">
              <div className="flex items-center gap-2">
                <DollarIcon />
                <span>درآمد این دوره:</span>
                <Price value={course.gain_for_this_course} suffix="تومان" />
              </div>
              <div className="flex items-center gap-2 ">
                <span>وضعیت:</span>
                <span>
                  {course.is_published ? "منتشر شده" : "دخیره در پیشنویس"}
                </span>
              </div>
              <div className="flex items-center gap-2" id="student">
                <StudentIcon />
                <span>تعداد دانشجویان:</span>
                <span>{course.num_of_participants} نفر</span>
              </div>
              <LinkBase
                to={`/my-course/${
                  course.courseId ? course.courseId : course.uuid
                }`}
              >
                مشاهده دوره
              </LinkBase>
            </div>
          </div>
        </article>
      )}
    </DashboardBox>
  );
};

export default Courses;

//
//
//         <li> </li>
//         <li>
//           <a href='#'>مشاهده دوره</a>
//         </li>
//       </ul>
//     </div>
//     {/*    );*/}
//     {/*})}*/}
//   </Card>
// )}
