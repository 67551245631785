import React from 'react';
import MyCourseContainer from '@Components/Layouts/MyCourse/MyCourseContainer';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const MyCourses = () => {
	return (
		<>
			<BreadCrumb />
			<section className='flex flex-col h-full'>
				<h3 className='title mt-14'> دوره های من </h3>
				<MyCourseContainer />
			</section>
		</>
	);
};

export default MyCourses;
