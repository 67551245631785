import { useAuth } from "@App/Context/authContext";
import React, { Component } from "react";
import { useEffect } from "react";
import { HOME_URL } from "../../constants";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const AuthCallback = () => {
  useEffect(() => {}, []);

  const { authDispatch } = useAuth();
  const { token } = useParams();
  const history = useHistory();
  //  ("token", token);
  const changeRoute = () => {
    if (token) {
      authDispatch({
        type: "LOGIN",
        token: token,
      });
    }
    history.push("/");

  };
  useEffect(() => {
    changeRoute();
  }, []);
  return <>{}</>;
};

export default AuthCallback;
