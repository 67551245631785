import React from 'react';
import CardCheck from '../../../Assets/Images/Pic/cardCheck.png';
import Button from "../../Shared/Buttons/Button";

const Cardcheking = ({handlePrevStep}) => {
    return (
        <div className='flex flex-col items-center'>
            <p className='mb-11 wallet__message'>
                اطلاعات حساب بانکی شما وارد شده و در انتظار بررسی است. به محض تایید
                شما می توانید کیف پول خود را مشاهده کنید
            </p>
            <img src={CardCheck} alt='checking'/>
            <Button type='primary' classes='wallet__btn' htmlType='submit' onClick={() => handlePrevStep('entry')}>
                تغییر اطلاعات کیف پول
            </Button>
        </div>
    );
};

export default Cardcheking;
