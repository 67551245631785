import React from "react";
import Property from "@Components/Layouts/CreateCourse/Property";
import PreReqesties from "@Components/Layouts/CreateCourse/PreRequesties";
import Button from "@Components/Shared/Buttons/Button";
import { ReactComponent as RightArrow } from "@Assets/Icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "@Assets/Icons/left-arrow.svg";
import { useCreateCourse } from "@App/Context/CreateCourseContext";
import WebTour from "@Components/Shared/WebTour/WebTour";
import { tourConfig } from "./Step/PropertyStep";
import { useAuth } from "@App/Context/authContext";

const CourseSecondPhase = ({ nextStep, prevStep, courseData }) => {
  const { createInfoData } = useCreateCourse();
  console.log("courseData ~ CourseSecondPhase: ", courseData);

  const handleNextStep = () => {
    if (
      (createInfoData.stepTwo.courseFeature.length > 0 &&
        createInfoData.stepTwo.coursePreReq.length > 0) ||
      (courseData?.properties?.length > 0 &&
        courseData?.prerequisites?.length > 0)
    ) {
      nextStep();
    }
  };
  const { showGuid, setShowGuid } = useAuth();
  const handleTourOpen = () => {
    setShowGuid(true);
  };
  return (
    <section className="create__course--form">
      <div className="create__course--wrapper">
        <Property courseData={courseData} />
        <div className="mt-4">
          <PreReqesties courseData={courseData} />
        </div>
      </div>
      <div className="create__course--btn">
        <Button
          type="primary"
          onClick={prevStep}
          datatut="reactour__courseaBeforeProperti"
        >
          مرحله ی قبل
          <RightArrow />
        </Button>
        <Button
          type="primary"
          classes="ml-8"
          disabled={
            !courseData &&
            (createInfoData.stepTwo.courseFeature.length <= 0 ||
              createInfoData.stepTwo.coursePreReq.length <= 0)
          }
          onClick={handleNextStep}
          datatut="reactour__courseaNextProperti"
        >
          <LeftArrow />
          مرحله ی بعد
        </Button>
      </div>
    </section>
  );
};

export default CourseSecondPhase;
