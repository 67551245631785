import Cookies from "js-cookie";
import { MAIN_UR } from "../constants";

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // localStorage.setItem("token", action.token);
      Cookies.set("token", action.token, { domain: MAIN_UR ,secure: true });

      return action.token;
    case "LOGOUT":
      // localStorage.removeItem("token");
      Cookies.remove("token", { path: "/", domain: MAIN_UR });

      return null;
    default:
      return state;
  }
};
