import React, {useState} from "react";
import {ReactComponent as PlusIcon} from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import {ReactComponent as Trash} from "@Assets/Icons/Trash.svg";
import {ReactComponent as Pencil} from "@Assets/Icons/Pencil.svg";
import IconBtn from "@Components/Shared/Buttons/IconBtn";
import {useHistory} from "react-router-dom";
import SeasonContentForm from "./SeasonContentForm";
import useFetch from "@App/Context/useFetch";
import Modal from "@Components/Shared/Modal/Modal";
import UploadVideo from "./UploadVideo";
import { Link, useParams } from "react-router-dom";

const SeasonsQuizItem = ({
                             quizId,
                             quizName,
                             quizStatus,
                             getCoursesSeasonsContent,
                             languageId,
                             languageName,
                             seasonId
                         }) => {

    const deleteSeasonQuiz = useFetch({
        url: `QuizService/${quizId}`,
        method: "DELETE",
        trigger: false,
        noHeader: false,
        caller: getCoursesSeasonsContent,
    });
    const history = useHistory();
    const { courseId } = useParams();
    return (
        <>
            <div className="SeasonsContentItem d-flex-space">
                <p className="SeasonsContentItem__title">{quizName}</p>
                <div className="d-flex-align">
                    <div>
                        <IconBtn
                            title="ویرایش"
                            icon={<Pencil/>}
                            onClick={() => history.push({
                                    pathname: `/my-course/createseasons/add-quize/${courseId}/${seasonId}`,
                                    state: {
                                        languageId: languageId,
                                        languageName: languageName,
                                        quizId: quizId
                                    }
                                }
                            )
                            }/>
                    </div>
                    {quizStatus !== "منتشر شده" ? (<div>
                        <IconBtn
                            title="حذف"
                            icon={<Trash/>}
                            danger
                            onClick={() => deleteSeasonQuiz.reFetch()}
                        />
                    </div>) : null}
                </div>
            </div>
        </>
    );
};

export default SeasonsQuizItem;