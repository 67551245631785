import Button from "@Components/Shared/Buttons/Button";

export const tourConfig = [
  {
    selector: '[data-tut="reactour__quizname"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">نام آزمون</div>
        <div style={{ marginTop: "15px" }}>نام آزمون را وارد نمایید</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            1 of 13
          </span>
        </div>
      </div>
    ),

    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="reactour__quizdes"]',
    content: ({ goTo }) => (
      <div className="WebTour__txtBox">
        <div className="WebTour__titile">توضیحات آزمون</div>
        <div style={{ marginTop: "15px" }}>
          توضیحات آزمون مورد نظر را وارد نمایید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            2 of 13
          </span>
          {/* <button onClick={() => goTo(2)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "bottom",
  },
  {
    selector: '[data-tut="WebTour__quiztestcase"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">تست کیس ورودی و خروجی</div>
        <div style={{ marginTop: "15px" }}>
          تست کیس ها برای بررسی کد شما هستند.تا زمانی که نتوانید با قطعه کد
          پیشنهادی و تست کیس ها نمره کامل کسب کنید این آزمون تایید نخواهد شد.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            3 of 13
          </span>
          {/* <button onClick={() => goTo(3)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    // selector: '[data-tut="reactour__video"]',
    selector: '[data-tut="reactour__newtestcase"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">تست کیس</div>
        <div style={{ marginTop: "15px" }}>افزودن تست کیس جدید</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            4 of 13
          </span>
          {/* <button onClick={() => goTo(4)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__sampletestcase"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile"> نمونه ورودی و خروجی </div>
        <div style={{ marginTop: "15px" }}>
          نمونه ورودی و خروجی که به کاربر نمایش داده میشود
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            5 of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__newsampletestcase"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">افزودن نمونه ورودی و خروجی</div>
        <div style={{ marginTop: "15px" }}>افزودن نمونه ورودی و خروجی</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            6 of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__codeeditor"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">قطعه کد</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          ایجاد قطعه کد برای ازمودن تست کیس ها و تا زمانی که نمره کامل کسب نکنید
          این آزمون تایید نخواهد شد{" "}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            7 of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quizruncode"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">اجرای کد</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          مشاهده خروجی کد، بدون در نظر گرفتن تست کیس ها
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            8of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quiztest"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">تست آزمون</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          مشاهده نتیجه کد، با در نظر گرفتن تست کیس ها و برگرداندن نمره
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            9of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quizdegree"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">نمره شما</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          مشاهده نمره شما ، با در نظر گرفتن تست کیس ها
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            10of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quizfile"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">آپلود فایل</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          کاربر قابلیت دانلود آن را در صفحه آزمون دارد
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            11of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quizfinal"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">ثبت</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          در صورت گرفتن نمره کامل نمایش داده میشود و میتوانید آزمون را ثبت
          نمایید
          <div className="btn-box">
            <Button>ثبت</Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            12of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
  {
    selector: '[data-tut="reactour__quizcancle"]',
    content: ({ goTo }) => (
      <div className=" WebTour__txtBox">
        <div className="WebTour__titile">انصراف</div>
        <div style={{ marginTop: "15px" }}>
          {" "}
          در صورت تمایل به انصراف از ساخت آزمون، روی این دکمه کلیک کنید
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "45px",
          }}
        >
          <span className="WebTour__number" style={{ fontWeight: "600" }}>
            13of 13
          </span>
          {/* <button onClick={() => goTo(5)}>{"Next"}</button> */}
        </div>
      </div>
    ),
    style: {
      padding: "20px 18px 12px 18px",
    },
    position: "top",
  },
];
