import React, { useState } from "react";
import SkillsDone from "./SkillsDone";
import SkillsForm from "./SkillsForm";
import { ReactComponent as PlusIcon } from "@Assets/Icons/plus.svg";
import Button from "@Components/Shared/Buttons/Button";
import useFetch from "@App/Context/useFetch";
import Item from "antd/lib/list/Item";
import { Skeleton } from "antd";

const SkillsPanel = () => {
  const [AddForm, setAddForm] = useState(false);
  const [teacherSkill, setTeacherSkill] = useState({ results: [] });
  const [addItem, setAddItem] = useState(false);

  const getSkill = useFetch({
    url: `TeacherSkillService`,
    setter: setTeacherSkill,
  });
  const showForm = () => {
    setAddItem((before) => !before);
  };
  return (
    <div className="WorkExperiencePanel ">
      {getSkill.response?.data ? (
        teacherSkill.results.map((item) => {
          return [
            <SkillsDone
              key={item.uuid}
              caller={getSkill}
              skill_name={item.skill_name}
              id={item.uuid}
              showForm={showForm}
              skill_rate={item.skill_rate}
            />,
          ];
        })
      ) : (
        <Skeleton.Button block active size="large" />
      )}
      {addItem && <SkillsForm getSkill={getSkill} showForm={showForm} />}
      {!addItem && (
        <Button onClick={showForm} type="text" size="small" success>
          <PlusIcon />
          <p>اضافه کردن مهارت </p>
        </Button>
      )}
    </div>
  );
};

export default SkillsPanel;
