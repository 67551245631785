import React from 'react';
import SettingsContainer from '@Components/Layouts/Settings/SettingsContainer';
import BreadCrumb from '@Components/Shared/BreadCrump/BreaCrumb';

const index = () => {
	return (
		<>
			<BreadCrumb />
			<section className='setting flex flex-col items-center '>
				<h1 className='setting__header'>تعویض کلمه عبور</h1>
				<SettingsContainer />
			</section>
		</>
	);
};

export default index;
